import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { P } from "common/P/P";
import { Htag } from "common/Htag/Htag";
import { Button } from "common/Button/Button";
import { ReactComponent as DoneIcon } from "common/assets/icons/check-circle.svg";
import { routeConstants } from "navigation";

interface OrderDetails {
	_id: string;
	userId: string | null;
	guestId: string | null;
	cart: {
		items: Array<{
			product: any;
			selectedShape: {
				id: string | null;
				name: string | null;
				imageUrl: string | null;
			};
			selectedSize: {
				id: string | null;
				name: string | null;
			};
			quantity: number;
			unitPrice: number;
			originalPrice: number;
			discountPrice: number;
			discountPercentage: number;
		}>;
		totalAmount: number;
		totalDiscount: number;
	};
	shippingInformation: {
		fullName: string;
		email: string;
		phoneNumber: string;
		address: string;
		city: string;
		region: string;
		country: string;
		zipCode: string;
		shipmentType: string;
		shipmentCost: number;
	};
	paymentDetails: {
		cardBrand: string;
		last4: string;
	};
	paymentStatus: string;
	createdAt: string;
}

const SuccessOrderPage: React.FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const orderDetails = location.state as OrderDetails | undefined;

	console.log("ORDer details:", orderDetails);

	useEffect(() => {
		if (!orderDetails) {
			navigate(routeConstants.root.route);
		}
	}, [orderDetails, navigate]);

	if (!orderDetails) {
		return null;
	}

	return (
		<div className="px-[80px] TwoXl:px-[90px] ThreeXl:px-[110px] py-8">
			<div className="grid grid-flow-row grid-cols-1 gap-6 p-4 border rounded-xl border-strokeColor auto-rows-max w-[580px] m-auto bg-white">
				<div className="grid grid-cols-1 gap-4 grid-rows-maxTwo place-items-center">
					<DoneIcon />
					<Htag tag="h4" type="semibold">
						Thanks for your order!
					</Htag>
				</div>
				<div>
					<div className="grid grid-flow-row grid-cols-1 gap-3 py-4 border-dashed border-y border-strokeColor auto-rows-max">
						<P size="p1" type="semibold">
							Transaction date
						</P>
						<P size="p2" type="regular">
							{new Date(orderDetails.createdAt).toLocaleString("en-US", {
								weekday: "long",
								year: "numeric",
								month: "long",
								day: "numeric",
								timeZoneName: "short",
							})}
						</P>
					</div>
					<div className="grid grid-flow-row grid-cols-1 gap-3 py-4 border-b border-dashed border-strokeColor auto-rows-max">
						<P size="p1" type="semibold">
							Payment method
						</P>
						<P size="p2" type="regular">
							{`${orderDetails.paymentDetails.cardBrand} card ending with ****${orderDetails.paymentDetails.last4}`}
						</P>
					</div>
					<div className="grid grid-flow-row grid-cols-1 gap-3 py-4 border-b border-dashed border-strokeColor auto-rows-max">
						<P size="p1" type="semibold">
							Shipping method
						</P>
						<P size="p2" type="regular">
							{orderDetails.shippingInformation.shipmentType}
						</P>
						{/* <P size="p2" type="semibold" className="underline cursor-pointer">
							Track order
						</P> */}
					</div>
					{orderDetails.cart.items.map((item, index) => (
						<div
							key={index}
							className="grid grid-flow-row grid-cols-1 gap-3 py-4 border-b border-dashed border-strokeColor auto-rows-max"
						>
							<div className="grid items-center gap-3 grid-cols-maxFr">
								{item.selectedShape.imageUrl && item.selectedShape.name && (
									<div className="inline-flex flex-col items-start justify-start w-16 h-16 bg-black border border-gray-200 rounded-lg bg-opacity-10">
										<img
											src={item.selectedShape.imageUrl}
											alt={item.selectedShape.name}
											className="object-cover w-full h-full"
										/>
									</div>
								)}
								<div className="grid grid-flow-row grid-cols-1 gap-1 auto-rows-max grow shrink basis-0">
									<div className="grid grid-flow-row grid-cols-1 auto-rows-max gap-0.5">
										<P size="p3" type="medium" className="truncate-1-lines">
											{item.product.name}
										</P>
										<div className="grid gap-1 grid-cols-maxThree place-items-center">
											<P size="p2" type="regular" className="!text-[#475467]">
												{item.selectedShape.name}
											</P>
											<div className="w-4 h-px rotate-90 border border-slate-600"></div>
											<P size="p2" type="regular" className="!text-[#475467]">
												{item.selectedSize.name}
											</P>
										</div>
									</div>
									<P size="p2" type="regular" className="!text-[#475467]">
										x{item.quantity}
									</P>
								</div>
							</div>
						</div>
					))}
					<div className="grid gap-4 py-4 border-b border-dashed grid-cols-maxTwo place-content-between place-items-center border-strokeColor">
						<P size="p1" type="medium" className="!text-[#475467]">
							Subtotal
						</P>
						<P size="p1" type="semibold" className="!text-[#475467]">
							${orderDetails.cart.totalAmount.toFixed(2)}
						</P>
					</div>
					<div className="grid grid-flow-row grid-cols-1 gap-6 py-4 border-b border-dashed auto-rows-max border-strokeColor">
						<div className="grid gap-4 grid-cols-maxTwo place-content-between place-items-center">
							<P size="p1" type="medium" className="!text-[#475467]">
								Discount
							</P>
							<P size="p1" type="regular" className="!text-[#475467]">
								-${orderDetails.cart.totalDiscount.toFixed(2)}
							</P>
						</div>
						<div className="grid gap-4 grid-cols-maxTwo place-content-between place-items-center">
							<P size="p1" type="medium" className="!text-[#475467]">
								Shipment cost
							</P>
							<P size="p1" type="regular" className="!text-[#475467]">
								${orderDetails.shippingInformation.shipmentCost.toFixed(2)}
							</P>
						</div>
					</div>
					<div className="grid gap-4 py-4 grid-cols-maxTwo place-content-between place-items-center">
						<P size="p1" type="medium" className="!text-[#475467]">
							Grand total
						</P>
						<Htag tag="h3" type="semibold">
							$
							{(
								orderDetails.cart.totalAmount -
								orderDetails.cart.totalDiscount +
								orderDetails.shippingInformation.shipmentCost
							).toFixed(2)}
						</Htag>
					</div>
				</div>
				<Button
					appearance="main"
					width="full"
					paddingY="py-3"
					onClick={() => navigate(routeConstants.root.route)}
				>
					Continue shopping
				</Button>
			</div>
		</div>
	);
};

export default SuccessOrderPage;
