import React from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { RootState } from "components/App/store";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { routeConstants } from "navigation";
import { serviceConfig } from "configs";
import { clearCart } from "redux/cart/cartSlice";

const PaymentForm: React.FC = () => {
	const stripe = useStripe();
	const elements = useElements();
	const cart = useSelector((state: RootState) => state.cart);
	const shipping = useSelector((state: RootState) => state.shippingInformation);
	const auth = useSelector((state: RootState) => state.auth);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		if (!stripe || !elements) return;

		const cardElement = elements.getElement(CardElement);
		if (!cardElement) return;

		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: "card",
			card: cardElement,
		});

		if (error) {
			toast.error(
				"Payment failed. Please check your card details and try again."
			);
			console.error("Stripe payment error:", error);
			return;
		}

		try {
			const cardBrand = paymentMethod?.card?.brand;
			const last4 = paymentMethod?.card?.last4;

			const response = await axios.post(
				`${serviceConfig.apiUrl}/process-payment`,
				{
					paymentMethodId: paymentMethod?.id,
					cart,
					shippingInformation: shipping,
					userId: auth.isLoggedIn ? auth.user?._id : null,
					cardBrand,
					last4,
				}
			);

			if (response.data.success) {
				toast.success("Payment successful!");
				dispatch(clearCart());
				navigate(routeConstants.successOrder.route, {
					state: response.data.data,
				});
			} else {
				toast.error("Payment failed. Please try again.");
			}
		} catch (error) {
			console.error("Error processing payment:", error);
			toast.error("An error occurred. Please try again.");
		}
	};

	return (
		<form onSubmit={handleSubmit} className="space-y-4">
			<CardElement
				options={{
					style: {
						base: {
							fontSize: "16px",
							color: "#32325d",
							"::placeholder": {
								color: "#aab7c4",
							},
						},
						invalid: {
							color: "#fa755a",
						},
					},
				}}
				className="p-4 border border-gray-300 rounded-md"
			/>
			<button
				type="submit"
				disabled={!stripe}
				className="w-full px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700"
			>
				Pay
			</button>
		</form>
	);
};

export default PaymentForm;
