import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { checkTokenValidity, deleteCookie } from '../../utils/cookie';

interface AppState {
    isPlayground: boolean;
    isLoggedIn: boolean;
}

const initialState: AppState = {
    isPlayground: checkTokenValidity(),
    isLoggedIn: checkTokenValidity(),
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setPlayground: (state) => {
            state.isPlayground = true;
        },
        setLandingPage: (state) => {
            state.isPlayground = false;
        },
        setLoggedIn: (state, action: PayloadAction<boolean>) => {
            state.isLoggedIn = action.payload;
        },
        logout: (state) => {
            deleteCookie('token');
            deleteCookie('user');
            state.isPlayground = false;
            state.isLoggedIn = false;
        },
    },
});

export const { setPlayground, setLandingPage, setLoggedIn, logout } = appSlice.actions;
export default appSlice.reducer;