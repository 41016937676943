const dateFormatter = (inputDate?: Date | string): string => {
	let date: Date;

	if (typeof inputDate === "string") {
		date = new Date(inputDate);
		if (isNaN(date.getTime())) {
			return "";
		}
	} else {
		date = inputDate || new Date();
	}

	const formattedDate = date.toLocaleDateString("en-GB", {
		day: "2-digit",
		month: "2-digit",
		year: "numeric",
	});

	return formattedDate.replace(/\//g, ".");
};

export default dateFormatter;
