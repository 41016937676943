import React, { useState, useRef, useEffect } from "react";
import { Htag } from "common/Htag/Htag";
import GenerateHistoryItem from "common/GenerateHistoryItem/GenerateHistoryItem";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "components/App/store";
import { serviceConfig } from "configs";
import { toast } from "react-toastify";
import { formatDistanceToNowStrict } from "date-fns";

const GeneratedHistoryCollection: React.FC = (): JSX.Element => {
	const auth = useSelector((state: RootState) => state.auth);
	const [history, setHistory] = useState<any[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [offset, setOffset] = useState<number>(0);
	const [totalCount, setTotalCount] = useState<number>(0);
	const limit = 10;
	const chatContainerRef = useRef<HTMLDivElement>(null);
	const [input, setInput] = useState<string>("");

	useEffect(() => {
		const fetchHistory = async () => {
			try {
				const response = await axios.get(
					`${serviceConfig.apiUrl}/user_generated_history`,
					{
						params: {
							userId: auth.user?._id,
							limit,
							offset,
						},
					}
				);

				setHistory((prevHistory) => [
					...prevHistory,
					...response.data.data.history,
				]);
				setTotalCount(response.data.data.totalCount);
				setLoading(false);
			} catch (error) {
				console.error("Error fetching history:", error);
				setLoading(false);
			}
		};

		fetchHistory();
	}, [auth.user?._id, offset]);

	const loadMore = () => {
		if (history.length < totalCount) {
			setOffset(offset + limit);
		}
	};

	const handleDownload = (id: string) => {
		const downloadUrl = `${serviceConfig.apiUrl}/download_collection/${id}`;
		window.location.href = downloadUrl; // Triggers the download
	};

	const handleShare = (id: string) => {
		const shareableUrl = `${window.location.origin}/shared/${id}`;
		navigator.clipboard.writeText(shareableUrl);
		toast.success("Link copied successfully!");
	};

	const handleEdit = (prompt: string) => {
		setInput(prompt); // Autofill the input field with the selected prompt
		chatContainerRef.current?.scrollIntoView({ behavior: "smooth" }); // Scroll to the input field
	};

	const handleDelete = async (id: string) => {
		try {
			await axios.delete(`${serviceConfig.apiUrl}/delete_image/${id}`);
			setHistory((prevHistory) =>
				prevHistory.filter((item) => item._id !== id)
			); // Remove the deleted item from the history
			toast.success("Collection deleted successfully!");
		} catch (error) {
			console.error("Error deleting collection:", error);
			toast.error("Failed to delete the collection.");
		}
	};

	return (
		<div className="grid grid-flow-row grid-cols-1 gap-6 py-10 auto-rows-max">
			<Htag tag="h2" type="semibold">
				Generated history
			</Htag>
			{history.map((item, index) => (
				<GenerateHistoryItem
					key={index}
					prompt={item.prompt}
					images={[
						item.imageUrl1,
						item.imageUrl2,
						item.imageUrl3,
						item.imageUrl4,
					].filter(Boolean)}
					time={formatDistanceToNowStrict(new Date(item.createdAt), {
						addSuffix: true,
						roundingMethod: "floor", // This ensures it rounds down to the nearest unit like "16 hours ago"
					}).replace(/^about\s*/, "")} // Removes "about" if present
					onDownload={() => handleDownload(item._id)}
					onShare={() => handleShare(item._id)}
					onEdit={() => handleEdit(item.prompt)}
					onDelete={() => handleDelete(item._id)}
				/>
			))}
			{loading && <p>Loading...</p>}
			{history.length < totalCount && (
				<button onClick={loadMore} className="mt-4">
					Load More
				</button>
			)}
		</div>
	);
};

export default GeneratedHistoryCollection;
