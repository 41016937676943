import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	checkTokenValidity,
	deleteCookie,
	getCookie,
} from "../../utils/cookie";
import { updateProfile } from "./profileAsyncThunks";
import { auth } from "./authAsyncThunks";

interface User {
	_id: string;
	UserGuid?: string;
	UserName?: string;
	UserPass?: string;
	Name?: string;
	IsMain?: number;
	UserVisibleIndex?: number;
	UserViewCnt?: number;
	Address?: string;
	MobilePhoneNumber?: string;
	HomePhoneNumber?: string;
	WorkPhoneNumber?: string;
	WorkFaxNumber?: string;
	WebAddress?: string;
	WebKey?: string;
	ZipCode?: string;
	Email: string;
	FirstName?: string;
	LastName?: string;
	Patronomic?: string;
	BirthDate?: Date | null;
	Residency?: string;
	PassportNo?: string;
	PassportIssuePlace?: string;
	LangSkills?: string;
	SaleBalanceLimit?: number;
	PurchBalanceLimit?: number;
	LastActivityDate?: Date;
	LastActivityDevice?: string;
	Latitude?: number;
	Longitude?: number;
	ProfileImage?: string | any;
	Country?: string;
	Region?: string;
	City?: string;
	orders?: any[];
	addresses?: any[];
	wishlists?: any[];
}

interface AuthState {
	isLoggedIn: boolean;
	user: User | null;
	error: string | null | undefined;
}

const initialState: AuthState = {
	isLoggedIn: checkTokenValidity(),
	user: checkTokenValidity()
		? getCookie("user")
			? JSON.parse(getCookie("user") || "{}")
			: null
		: null,
	error: null,
};

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setUser: (state, action: PayloadAction<User>) => {
			state.isLoggedIn = true;
			state.user = action.payload;
			state.error = null;
		},
		loginFailed: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
		},
		logout: (state) => {
			deleteCookie("token");
			deleteCookie("user");
			state.isLoggedIn = false;
			state.user = null;
		},
		updateAddresses: (state, action: PayloadAction<any[]>) => {
			if (state.user) {
				state.user.addresses = action.payload;
			}
		},
		addWishlistItem: (state, action: PayloadAction<any>) => {
			if (state.user) {
				if (!state.user.wishlists) {
					state.user.wishlists = [];
				}
				state.user.wishlists.push(action.payload);
			}
		},
		removeWishlistItem: (state, action: PayloadAction<string>) => {
			if (state.user && state.user.wishlists) {
				state.user.wishlists = state.user.wishlists.filter(
					(item) => item._id !== action.payload
				);
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(auth.fulfilled, (state, action: PayloadAction<any>) => {
				state.isLoggedIn = true;
				state.user = action.payload.user;
				state.error = null;
			})
			.addCase(auth.rejected, (state, action) => {
				state.error = action.payload as string;
			})
			.addCase(
				updateProfile.fulfilled,
				(state, action: PayloadAction<User>) => {
					state.user = { ...state.user, ...action.payload };
					state.error = null;
				}
			)
			.addCase(updateProfile.rejected, (state, action) => {
				state.error = action.payload as string;
			});
	},
});

export const {
	setUser,
	logout,
	updateAddresses,
	addWishlistItem,
	removeWishlistItem,
} = authSlice.actions;
export default authSlice.reducer;
