import axios from "axios";
import { serviceConfig } from "configs";
import { getCookie } from "utils/cookie";

const API_BASE_URL = serviceConfig.apiUrl;

interface User {
	_id: string;
	UserGuid: string;
	UserName: string;
	UserPass: string;
	Name: string;
	IsMain: number;
	UserVisibleIndex: number;
	UserViewCnt: number;
	Address: string;
	MobilePhoneNumber: string;
	HomePhoneNumber: string;
	WorkPhoneNumber: string;
	WorkFaxNumber: string;
	WebAddress: string;
	WebKey: string;
	ZipCode: string;
	Email: string;
	FirstName: string;
	LastName: string;
	Patronomic: string;
	BirthDate: Date | null;
	Residency: string;
	PassportNo: string;
	PassportIssuePlace: string;
	LangSkills: string;
	SaleBalanceLimit: number;
	PurchBalanceLimit: number;
	LastActivityDate: Date;
	LastActivityDevice: string;
	Latitude: number;
	Longitude: number;
	Country: string;
	Region: string;
	City: string;
	orders: any[];
	addresses: any[];
	wishlists: any[];
}
interface RegisterInitResponse {
	message: string;
}

interface RegisterFinalResponse {
	success: boolean;
	data: {
		user: User;
		token: string;
		currentTime: string;
		tokenExpirationTime: string;
	};
	message: string;
}

interface LoginResponse {
	success: boolean;
	data: {
		user: User;
		token: string;
		currentTime: string;
		tokenExpirationTime: string;
	};
	message: string;
}
interface ResetPasswordResponse {
	success: boolean;
	data: {
		user: User;
		token: string;
		currentTime: string;
		tokenExpirationTime: string;
	};
	message: string;
}

interface ForgetPasswordInitResponse {
	message: string;
}

interface ForgetPasswordFinalResponse {
	success: boolean;
	data: {
		user: User;
		token: string;
		currentTime: string;
		tokenExpirationTime: string;
	};
	message: string;
}
interface UpdateProfileResponse {
	success: boolean;
	data: {
		user: User;
		token: string;
		currentTime: string;
		tokenExpirationTime: string;
	};
	message: string;
}

export const authService = {
	registerInit: async (
		email: string,
		password: string,
		firstName: string,
		lastName: string,
	): Promise<RegisterInitResponse> => {
		const response = await axios.post<RegisterInitResponse>(
			`${API_BASE_URL}/register/init`,
			{
				email,
				password,
				firstName,
				lastName
			}
		);
		return response.data;
	},
	registerFinal: async (
		email: string,
		otp: string,
		firstName: string,
		lastName: string
	): Promise<RegisterFinalResponse> => {
		const response = await axios.post<RegisterFinalResponse>(
			`${API_BASE_URL}/register/final`,
			{
				email,
				otp,
				firstName,
				lastName
			}
		);
		return response.data;
	},
	login: async (email: string, password: string): Promise<LoginResponse> => {
		const response = await axios.post<LoginResponse>(`${API_BASE_URL}/login`, {
			email,
			password,
		});
		return response.data;
	},
	resetPassword: async (
		email: string,
		oldPassword: string,
		newPassword: string
	): Promise<ResetPasswordResponse> => {
		const response = await axios.post<ResetPasswordResponse>(
			`${API_BASE_URL}/reset-password`,
			{
				email,
				oldPassword,
				newPassword,
			}
		);
		return response.data;
	},
	forgetPasswordInit: async (
		email: string
	): Promise<ForgetPasswordInitResponse> => {
		const response = await axios.post<ForgetPasswordInitResponse>(
			`${API_BASE_URL}/forget-password/init`,
			{ email }
		);
		return response.data;
	},
	verifyForgetPasswordOTP: async (
		email: string,
		otp: string
	): Promise<{ message: string }> => {
		const response = await axios.post<{ message: string }>(
			`${API_BASE_URL}/forget-password/verify`,
			{ email, otp }
		);
		return response.data;
	},
	forgetPasswordFinal: async (
		email: string,
		otp: string,
		newPassword: string
	): Promise<ForgetPasswordFinalResponse> => {
		const response = await axios.post<ForgetPasswordFinalResponse>(
			`${API_BASE_URL}/forget-password/final`,
			{
				email,
				otp,
				newPassword,
			}
		);
		return response.data;
	},
	updateProfile: async (formData: FormData): Promise<UpdateProfileResponse> => {
		const token = getCookie("token");
		const response = await axios.put<UpdateProfileResponse>(
			`${API_BASE_URL}/profile/update`,
			formData,
			{
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	},
};
