import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Htag } from "common/Htag/Htag";
import { Input } from "common/Input/Input";
import { P } from "common/P/P";
import UploadPhoto from "common/UploadPhoto/UploadPhoto";
import { Button } from "common/Button/Button";
import { Select } from "common/Select/Select";
import { SelectItem } from "common/Select/SelectItem";
import { Autocomplete } from "@react-google-maps/api";
import { RootState, AppDispatch } from "components/App/store";
import { updateProfile } from "redux/auth/profileAsyncThunks";
import axios from "axios";
import { serviceConfig } from "configs";
import { toast } from "react-toastify";

interface Country {
	_id: string;
	name: string;
	states: Region[];
}

interface Region {
	_id: string;
	name: string;
	code: string;
}

const ProfileTab: React.FC = () => {
	const dispatch = useDispatch<AppDispatch>();
	const user = useSelector((state: RootState) => state.auth.user);
	const [photo, setPhoto] = useState<Blob | string | null>(null);
	const [selectedCountry, setSelectedCountry] =
		useState<string>("United States");
	const [selectedRegion, setSelectedRegion] = useState<string>(
		user?.Region || ""
	);
	const [countries, setCountries] = useState<Country[]>([]);
	const [autocomplete, setAutocomplete] =
		useState<google.maps.places.Autocomplete | null>(null);

	const [inputs, setInputs] = useState({
		name: "",
		surname: "",
		email: "",
		address: "",
		city: "",
		region: "",
		zipCode: "",
	});

	useEffect(() => {
		if (user) {
			setInputs({
				name: user.FirstName || "",
				surname: user.LastName || "",
				email: user.Email || "",
				address: user.Address || "",
				city: user.City || "",
				region: user.Region || "",
				zipCode: user.ZipCode || "",
			});
			setPhoto(user.ProfileImage);
			setSelectedCountry(user.Country || "United States");
			setSelectedRegion(user.Region || "");
		}
	}, [user]);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setInputs((prevInputs) => ({
			...prevInputs,
			[name]: value,
		}));
	};

	const handleCountrySelect = async (value: string) => {
		setSelectedCountry(value);
		setInputs((prevInputs) => ({
			...prevInputs,
			region: "",
		}));

		// Send country change to backend
		const formData = new FormData();
		formData.append("Country", value);

		try {
			await dispatch(updateProfile(formData)).unwrap();
			toast.success("Country updated successfully!");
		} catch (error) {
			toast.error("Failed to update country. Please try again.");
		}
	};

	const handleRegionSelect = (value: string) => {
		setSelectedRegion(value);
		setInputs((prevInputs) => ({
			...prevInputs,
			region: value,
		}));
	};

	const handlePhotoChange = (files: Blob[]) => {
		if (files.length > 0) {
			setPhoto(files[0]);
		} else {
			setPhoto(null);
		}
	};

	const fetchCountries = async () => {
		try {
			const response = await axios.get(`${serviceConfig.apiUrl}/countries`);
			if (response.data.success) {
				setCountries(response.data.data);
			}
		} catch (error) {
			console.error("Error fetching countries:", error);
		}
	};

	useEffect(() => {
		fetchCountries();
	}, []);

	const handlePersonalInfoSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		const formData = new FormData();
		formData.append("FirstName", inputs.name);
		formData.append("LastName", inputs.surname);
		if (photo && typeof photo !== "string") {
			formData.append("ProfileImage", photo);
		}

		try {
			await dispatch(updateProfile(formData)).unwrap();
			toast.success("Profile updated successfully!");
		} catch (error) {
			toast.error("Failed to update profile. Please try again.");
		}
	};

	const handleAddressSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		const formData = new FormData();
		formData.append("Address", inputs.address);
		formData.append("City", inputs.city);
		formData.append("Region", inputs.region);
		formData.append("ZipCode", inputs.zipCode);
		formData.append("Country", selectedCountry);

		try {
			await dispatch(updateProfile(formData)).unwrap();
			toast.success("Address updated successfully!");
		} catch (error) {
			toast.error("Failed to update address. Please try again.");
		}
	};

	const onLoad = useCallback((autoC: google.maps.places.Autocomplete) => {
		setAutocomplete(autoC);
	}, []);

	const onPlaceChanged = () => {
		if (autocomplete !== null) {
			const place = autocomplete.getPlace();
			let address = "";
			let city = "";
			let stateName = "";
			let zip = "";

			place.address_components?.forEach((component) => {
				const types = component.types;
				if (types.includes("street_number")) {
					address = component.long_name + " " + address;
				}
				if (types.includes("route")) {
					address += component.long_name;
				}
				if (types.includes("locality")) {
					city = component.long_name;
				}
				if (types.includes("administrative_area_level_1")) {
					stateName = component.long_name;
				}
				if (types.includes("postal_code")) {
					zip = component.long_name;
				}
			});

			setInputs({
				...inputs,
				address: address,
				city: city,
				region: stateName,
				zipCode: zip,
			});
			setSelectedCountry("United States");
		} else {
			console.log("Autocomplete is not loaded yet!");
		}
	};

	return (
		<div className="grid grid-cols-2 gap-6">
			<div className="grid grid-flow-row grid-cols-1 gap-6 p-6 bg-white border auto-rows-max border-strokeColor rounded-xl">
				<div className="grid grid-flow-row grid-cols-1 pb-5 border-b auto-rows-max border-strokeColor">
					<Htag tag="h4" type="semibold">
						Personal info
					</Htag>
					<P size="p3" type="regular" className="text-textSecondaryColor">
						Update your photo and personal details here.
					</P>
				</div>
				<div className="grid grid-flow-row grid-cols-1 gap-4 auto-rows-max">
					<Input
						appearance="default"
						type="text"
						name="name"
						placeholder="Enter your name"
						label="Name"
						withlabel
						value={inputs.name}
						onChange={handleInputChange}
					/>
					<Input
						appearance="default"
						type="text"
						name="surname"
						placeholder="Enter your surname"
						label="Surname"
						withlabel
						value={inputs.surname}
						onChange={handleInputChange}
					/>
					<Input
						appearance="default"
						type="text"
						name="email"
						placeholder="Enter your email"
						label="Email address"
						withlabel
						value={inputs.email}
						disabled
					/>
				</div>
				<UploadPhoto onChange={handlePhotoChange} uploadedPhoto={photo} />
				<div className="grid gap-3 pt-4 border-t grid-cols-maxTwo place-content-end border-strokeColor">
					<Button appearance="border" paddingX="px-4">
						Cancel
					</Button>
					<Button
						appearance="main"
						paddingX="px-8"
						onClick={handlePersonalInfoSubmit}
					>
						Save changes
					</Button>
				</div>
			</div>
			<div className="grid grid-flow-row grid-cols-1 gap-6 p-6 bg-white border auto-rows-max border-strokeColor rounded-xl h-max">
				<div className="grid grid-flow-row grid-cols-1 pb-5 border-b auto-rows-max border-strokeColor">
					<Htag tag="h4" type="semibold">
						My address
					</Htag>
				</div>
				<div className="grid grid-flow-row grid-cols-1 pb-5 border-b auto-rows-max border-strokeColor">
					<Select
						defaultSelectLabel="Select country"
						onSelect={handleCountrySelect}
						value={selectedCountry}
					>
						{countries.map((country) => (
							<SelectItem key={country._id} value={country.name}>
								{country.name}
							</SelectItem>
						))}
					</Select>
				</div>
				<div className="grid grid-flow-row grid-cols-1 gap-5 auto-rows-max">
					<Autocomplete
						onLoad={onLoad}
						onPlaceChanged={onPlaceChanged}
						restrictions={{ country: "us" }}
					>
						<Input
							appearance="default"
							type="text"
							name="address"
							placeholder="Enter your street name and house number"
							label="Street name and house number"
							withlabel
							value={inputs.address}
							onChange={handleInputChange}
						/>
					</Autocomplete>
					<div className="grid grid-cols-2 gap-3 h-max">
						<Input
							appearance="default"
							type="text"
							name="city"
							placeholder="City"
							label="City"
							withlabel
							value={inputs.city}
							onChange={handleInputChange}
							minWidth={false}
						/>
						<Select
							label="Region"
							defaultSelectLabel="Select region"
							value={selectedRegion}
							onSelect={handleRegionSelect}
						>
							{countries
								.find((country) => country.name === selectedCountry)
								?.states.map((state) => (
									<SelectItem key={state._id} value={state.name}>
										{state.name}
									</SelectItem>
								))}
						</Select>
					</div>
					<Input
						appearance="default"
						type="text"
						name="zipCode"
						placeholder="Enter your postal code"
						label="Postal code"
						withlabel
						value={inputs.zipCode}
						onChange={handleInputChange}
					/>
				</div>
				<div className="grid gap-3 pt-4 border-t grid-cols-maxTwo place-content-end border-strokeColor">
					<Button appearance="border" paddingX="px-4">
						Cancel
					</Button>
					<Button
						appearance="main"
						paddingX="px-8"
						onClick={handleAddressSubmit}
					>
						Save changes
					</Button>
				</div>
			</div>
		</div>
	);
};

export default ProfileTab;
