import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CartItem {
	product: any;
	selectedShape: {
		id: string | null;
		name: string | null;
		imageUrl: string | null;
	};
	selectedSize: {
		id: string | null;
		name: string | null;
	};
	quantity: number;
	unitPrice: number;
	originalPrice: number;
	discountPrice: number;
	discountPercentage: number;
}

interface CartState {
	items: CartItem[];
	totalAmount: number;
	totalDiscount: number;
	status: "idle" | "loading" | "succeeded" | "failed";
	error: string | null;
}

interface ModifyItemQuantityPayload {
	productId: string;
	shapeId: string | null;
	sizeId: string | null;
}

const initialState: CartState = {
	items: [],
	totalAmount: 0,
	totalDiscount: 0,
	status: "idle",
	error: null,
};

const cartSlice = createSlice({
	name: "cart",
	initialState,
	reducers: {
		addItem: (state, action: PayloadAction<CartItem>) => {
			const existingIndex = state.items.findIndex(
				(item) =>
					item.product._id === action.payload.product._id &&
					item.selectedShape.id === action.payload.selectedShape.id &&
					item.selectedSize.id === action.payload.selectedSize.id
			);

			if (existingIndex !== -1) {
				state.items[existingIndex].quantity += action.payload.quantity;
				state.totalAmount += action.payload.unitPrice * action.payload.quantity;
				state.totalDiscount +=
					(action.payload.originalPrice - action.payload.discountPrice) *
					action.payload.quantity;
			} else {
				state.items.push(action.payload);
				state.totalAmount += action.payload.unitPrice * action.payload.quantity;
				state.totalDiscount +=
					(action.payload.originalPrice - action.payload.discountPrice) *
					action.payload.quantity;
			}
		},
		increaseQuantity: (
			state,
			action: PayloadAction<ModifyItemQuantityPayload>
		) => {
			const { productId, shapeId, sizeId } = action.payload;
			const index = state.items.findIndex(
				(item) =>
					item.product._id === productId &&
					item.selectedShape.id === shapeId &&
					(item.selectedSize.id === sizeId ||
						item.selectedSize.id === null ||
						sizeId === null)
			);
			if (index !== -1) {
				state.items[index].quantity++;
				state.totalAmount += state.items[index].unitPrice;
				state.totalDiscount +=
					state.items[index].originalPrice - state.items[index].discountPrice;
			} else {
				console.log(
					"No item matched for increase:",
					productId,
					shapeId,
					sizeId
				);
			}
		},
		decreaseQuantity: (
			state,
			action: PayloadAction<ModifyItemQuantityPayload>
		) => {
			const { productId, shapeId, sizeId } = action.payload;
			const index = state.items.findIndex(
				(item) =>
					item.product._id === productId &&
					item.selectedShape.id === shapeId &&
					(item.selectedSize.id === sizeId ||
						item.selectedSize.id === null ||
						sizeId === null)
			);
			if (index !== -1 && state.items[index].quantity > 1) {
				state.items[index].quantity--;
				state.totalAmount -= state.items[index].unitPrice;
				state.totalDiscount -=
					state.items[index].originalPrice - state.items[index].discountPrice;
			} else {
				console.log(
					"No item matched for decrease or quantity is at minimum:",
					productId,
					shapeId,
					sizeId
				);
			}
		},
		removeItem: (state, action: PayloadAction<ModifyItemQuantityPayload>) => {
			const { productId, shapeId, sizeId } = action.payload;
			const index = state.items.findIndex(
				(item) =>
					item.product._id === productId &&
					item.selectedShape.id === shapeId &&
					(sizeId ? item.selectedSize.id === sizeId : true)
			);
			if (index !== -1) {
				state.totalAmount -=
					state.items[index].unitPrice * state.items[index].quantity;
				state.totalDiscount -=
					(state.items[index].originalPrice -
						state.items[index].discountPrice) *
					state.items[index].quantity;
				state.items.splice(index, 1);
			}
		},
		clearCart: (state) => {
			state.items = [];
			state.totalAmount = 0;
			state.totalDiscount = 0;
		},
		applyDiscount: (state, action: PayloadAction<number>) => {
			state.totalDiscount = action.payload;
		},
	},
});

export const {
	addItem,
	increaseQuantity,
	decreaseQuantity,
	removeItem,
	clearCart,
	applyDiscount,
} = cartSlice.actions;
export default cartSlice.reducer;
