import React from "react";
import { ReactComponent as Logo } from "common/assets/icons/logo.svg";
import { ReactComponent as TiktokIcon } from "common/assets/icons/tiktok.svg";
import { ReactComponent as FacebookIcon } from "common/assets/icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "common/assets/icons/instagram.svg";
import { Link } from "react-router-dom";
import { P } from "common/P/P";

interface IPropsFooter {
	isPlayground: boolean;
}

const Footer: React.FC<IPropsFooter> = ({ isPlayground }) => {
	return isPlayground ? (
		<div className="grid grid-cols-1 py-6 border-t place-content-center place-items-center border-strokeColor">
			<P size="p2" type="medium" className="!text-[#667085]">
				Copyright © 2024 Nail AI. All rights reserved
			</P>
		</div>
	) : (
		<div className="">
			<nav className="relative z-20 px-12 py-3 mx-[170px] TwoXl:mx-[240px] ThreeXl:mx-[300px] my-16 bg-white rounded-xl navbar-bg">
				<div className="grid py-2 border-b grid-cols-maxTwo place-items-center place-content-between border-strokeColor">
					<Logo className="w-32 cursor-pointer" />
					<div className="relative grid gap-4 grid-cols-maxFour place-items-center">
						<div
							className={`flex items-center justify-start gap-2 px-4 py-2 cursor-pointer rounded-xl`}
						>
							<P
								size="p2"
								type="semibold"
								className="cursor-pointer !text-[#475467]"
							>
								Blogs
							</P>
						</div>

						<div className="flex items-center justify-start gap-2 px-4 py-2">
							<P
								size="p2"
								type="semibold"
								className="cursor-pointer !text-[#475467]"
							>
								Contact Us
							</P>
						</div>
						<div className="flex items-center justify-start gap-2 px-4 py-2">
							<P
								size="p2"
								type="semibold"
								className="cursor-pointer !text-[#475467]"
							>
								Privacy and Policy
							</P>
						</div>
						<div className="flex items-center justify-start gap-2 px-4 py-2">
							<P
								size="p2"
								type="semibold"
								className="cursor-pointer !text-[#475467]"
							>
								Terms of Use
							</P>
						</div>
					</div>
				</div>
				<div className="grid py-6 grid-cols-maxTwo place-items-center place-content-between">
					<P size="p2" type="medium" className="!text-[#667085]">
						© 2024 Nail Ai. All rights reserved.
					</P>
					<div className="grid gap-6 grid-cols-maxThree">
						<Link to="https://www.tiktok.com/@naildesign.ai">
							<TiktokIcon className="cursor-pointer" />
						</Link>

						<Link to="https://www.facebook.com/naildesignai">
							<FacebookIcon className="cursor-pointer" />
						</Link>
						<Link to="https://instagram.com/naildesign.ai?igshid=OGQ5ZDc2ODk2ZA==">
							<InstagramIcon className="cursor-pointer" />
						</Link>
					</div>
				</div>
			</nav>
		</div>
	);
};

export default Footer;
