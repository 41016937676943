import React, { useState } from "react";
import { Htag } from "common/Htag/Htag";
import { useSelector } from "react-redux";

import { RootState } from "components/App/store";
import PaymentMethodSelector from "components/PaymentMethods/PaymentMethodSelector";
import OrderSummary from "components/PaymentMethods/OrderSummary";

const PaymentPage: React.FC = () => {
	const [selectedMethod, setSelectedMethod] = useState<string>("card");
	const app = useSelector((state: RootState) => state.app);
	const cart = useSelector((state: RootState) => state.cart);
	const shipping = useSelector((state: RootState) => state.shippingInformation);

	const subtotal = cart.totalAmount;
	const discount = cart.totalDiscount;
	const shipmentCost = subtotal > 50 ? 0 : shipping.shipmentCost;
	const grandTotal = subtotal - discount + shipmentCost;

	const dropdownVariants = {
		initial: { opacity: 0, scaleY: 0, originY: 0 },
		animate: {
			opacity: 1,
			scaleY: 1,
			transition: { duration: 0.3, ease: "easeOut" },
		},
	};

	return (
		<div
			className={`${
				app.isPlayground
					? "px-[80px] TwoXl:px-[90px] ThreeXl:px-[110px]"
					: "px-[170px] TwoXl:px-[240px] ThreeXl:px-[300px]"
			} py-8 grid grid-cols-1 gap-4 grid-flow-row auto-rows-max`}
		>
			<Htag tag="h2" type="semibold">
				Payment
			</Htag>
			<div className="grid grid-cols-[70%_30%] gap-4 pb-14">
				<div className="grid grid-cols-1 gap-6 p-6 bg-white border border-gray-200 rounded-xl h-max">
					<Htag tag="h4" type="semibold">
						Select payment methods
					</Htag>
					<PaymentMethodSelector
						selectedMethod={selectedMethod}
						setSelectedMethod={setSelectedMethod}
						dropdownVariants={dropdownVariants}
					/>
				</div>
				<div className="grid grid-flow-row grid-cols-1 gap-6 p-6 bg-white border border-gray-200 auto-rows-max rounded-xl h-max">
					<OrderSummary
						subtotal={subtotal}
						discount={discount}
						shipmentCost={shipmentCost}
						grandTotal={grandTotal}
					/>
				</div>
			</div>
		</div>
	);
};

export default PaymentPage;
