import React, { useEffect } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";
import "i18n";
import { ErrorBoundary } from "modules/errors";
import Footer from "components/Footer/Footer";
import { useSelector } from "react-redux";
import { RootState } from "./store";
import Navbar from "components/Navbar/Navbar";
import { LoadScript } from "@react-google-maps/api";
import { PlaygroundRoutes } from "navigation/PlaygroundRoutes/PlaygroundRoutes";
import { LandingRoutes } from "navigation/LandingRoutes/LandingRoutes";
import { routeConstants } from "navigation";

const AppContent: React.FC = (): JSX.Element => {
	const app = useSelector((state: RootState) => state.app);
	const location = useLocation(); // Use useLocation to get the current route

	useEffect(() => {
		if (app.isPlayground) {
			document.body.style.backgroundColor = "#fff";
		} else {
			document.body.style.backgroundColor = "#F0F3F9";
		}
	}, [app.isPlayground]);

	// Define the routes where you do not want to render the Footer
	const noFooterRoutes = [routeConstants.generate.route];

	const shouldRenderFooter = !noFooterRoutes.includes(location.pathname);

	return (
		<>
			<ToastContainer
				position="top-right"
				autoClose={3000}
				limit={3}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="dark"
				transition={Zoom}
			/>
			<LoadScript
				googleMapsApiKey={
					process.env.REACT_APP_GOOGLE_MAPS_API_KEY ||
					"AIzaSyB-PtbnR9_2PHVPZAHblScDRb16KOZbICw"
				}
				libraries={["places"]}
			>
				<Navbar isPlayground={app.isPlayground ? true : false} />
				<div className="!min-h-screen">
					{app.isPlayground ? <PlaygroundRoutes /> : <LandingRoutes />}
				</div>
				{shouldRenderFooter && (
					<Footer isPlayground={app.isPlayground ? true : false} />
				)}
			</LoadScript>
		</>
	);
};

const App: React.FC = (): JSX.Element => {
	return (
		<ErrorBoundary>
			<Router>
				<AppContent />
			</Router>
		</ErrorBoundary>
	);
};

export default App;
