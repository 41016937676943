import React from "react";
import { ReactComponent as StarIcon } from "common/assets/icons/sparkles-soft.svg";

import { Htag } from "common/Htag/Htag";
import { Button } from "common/Button/Button";

const TryForFreeShowcase: React.FC = () => {
	return (
		<div className="mx-[170px] TwoXl:mx-[240px] ThreeXl:mx-[300px] my-8 grid grid-cols-1 gap-6 bg-white rounded-2xl">
			<div className="px-[170px] py-8 grid grid-cols-1 grid-rows-maxTwo gap-8 place-content-center place-items-center">
				<div className="grid grid-cols-1 gap-1">
					<Htag tag="h2" type="bold" className="text-[32px] text-center">
						Try for free
					</Htag>
					<Htag
						tag="h2"
						type="bold"
						className="text-[32px] text-center leading-snug"
						style={{
							backgroundImage:
								"linear-gradient(to left, rgba(230, 80, 243, 1) 10%, rgba(112, 40, 228, 1) 100%)",
							WebkitBackgroundClip: "text",
							backgroundClip: "text",
							color: "transparent",
							WebkitTextFillColor: "transparent",
						}}
					>
						Your AI-Powered Personal Nail Artist
					</Htag>
				</div>
				<Button appearance="main" icon={<StarIcon />}>
					Start your design
				</Button>
			</div>
		</div>
	);
};

export default TryForFreeShowcase;
