import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Htag } from "common/Htag/Htag";
import { Button } from "common/Button/Button";
import { P } from "common/P/P";
import { ReactComponent as TrashIcon } from "common/assets/icons/trash-03.svg";
import CartBody from "components/Cart/CartBody";
import { RootState } from "components/App/store";
import { clearCart } from "redux/cart/cartSlice";
import { routeConstants } from "navigation";
import { useNavigate } from "react-router-dom";

const CartPage: React.FC = () => {
	const dispatch = useDispatch();
	const cart = useSelector((state: RootState) => state.cart);
	const shipping = useSelector((state: RootState) => state.shippingInformation);
	const app = useSelector((state: RootState) => state.app);
	const navigate = useNavigate();

	const subtotal = cart.totalAmount;
	const discount = cart.totalDiscount;
	const shipmentCost = subtotal > 50 ? 0 : shipping.shipmentCost;
	const grandTotal = subtotal - discount + shipmentCost;

	const handleClearCart = () => {
		dispatch(clearCart());
	};

	return (
		<div className={`${app.isPlayground ? "px-[80px] TwoXl:px-[90px] ThreeXl:px-[110px]" : "px-[170px] TwoXl:px-[240px] ThreeXl:px-[300px]"} py-8 grid grid-cols-1 gap-4 grid-flow-row auto-rows-max`}>
			<Htag tag="h2" type="semibold"> 
				Cart
			</Htag>
			<div className="grid grid-cols-[70%_30%] gap-4 pb-14">
				<div className="grid grid-flow-row grid-cols-1 gap-3 px-6 pt-3 pb-6 bg-white border border-gray-200 auto-rows-max rounded-xl">
					<div className="inline-flex items-center self-stretch justify-start">
						<div className="grow shrink basis-0 pr-2.5 py-2.5 flex-col justify-start items-start gap-2.5 inline-flex">
							<div className="inline-flex items-start justify-start gap-3">
								<div className="flex-col justify-start items-start gap-0.5 inline-flex">
									<Htag tag="h4" type="semibold">
										Products
									</Htag>
								</div>
							</div>
						</div>
						<div className="w-52 pr-2.5 py-2.5 flex-col justify-center items-start gap-2.5 inline-flex">
							<div className="flex-col justify-start items-start gap-0.5 flex">
								<P size="p2" type="medium">
									Price
								</P>
							</div>
						</div>
						<div className="w-28 pr-2.5 py-2.5 justify-start items-center gap-2.5 flex">
							<P size="p2" type="medium">
								Quantity
							</P>
						</div>
						<div
							className="justify-center items-center gap-1.5 flex cursor-pointer"
							onClick={handleClearCart}
						>
							<TrashIcon />
							<P size="p2" type="medium">
								Delete all
							</P>
						</div>
					</div>

					<div className="grid grid-flow-row grid-cols-1 gap-4 auto-rows-max ">
						<CartBody />
					</div>
				</div>

				<div className="grid grid-flow-row grid-cols-1 gap-6 p-6 bg-white border border-gray-200 auto-rows-max rounded-xl h-max">
					<div className="grid grid-flow-row grid-cols-1 gap-6 auto-rows-max">
						<Htag tag="h4" type="semibold">
							Order summary
						</Htag>

						<div className="self-stretch h-px border border-gray-200"></div>
						<div className="grid grid-flow-row grid-cols-1 gap-4 auto-rows-max h-28">
							<div className="grid gap-4 grid-cols-maxTwo place-content-between place-items-center">
								<P size="p1" type="medium" className="!text-[#475467]">
									Subtotal
								</P>
								<P size="p1" type="semibold" className="!text-[#475467]">
									${subtotal.toFixed(2)}
								</P>
							</div>

							<div className="grid gap-4 grid-cols-maxTwo place-content-between place-items-center">
								<P size="p1" type="medium" className="!text-[#475467]">
									Discount
								</P>
								<P size="p1" type="regular" className="!text-[#475467]">
									-${discount.toFixed(2)}
								</P>
							</div>

							<div className="grid gap-4 grid-cols-maxTwo place-content-between place-items-center">
								<P size="p1" type="medium" className="!text-[#475467]">
									Shipment cost
								</P>
								<P size="p1" type="regular" className="!text-[#475467]">
									${shipmentCost.toFixed(2)}
								</P>
							</div>
						</div>

						<div className="self-stretch h-px border border-gray-200"></div>
						<div className="grid gap-4 grid-cols-maxTwo place-content-between place-items-center">
							<P size="p1" type="medium" className="!text-[#475467]">
								Grand total
							</P>
							<Htag tag="h3" type="semibold">
								${grandTotal.toFixed(2)}
							</Htag>
						</div>
					</div>

					<Button
						appearance="main"
						paddingY="py-3.5"
						width="full"
						onClick={() => navigate(routeConstants.shippingAddress.route)}
					>
						Continue to payment
					</Button>
				</div>
			</div>

			<div className="grid grid-cols-1 gap-6 grid-rows-maxTwo">
				<Htag tag="h2" type="semibold">
					Best sellers
				</Htag>

				{/* <Carousel slidesToShow={5}>
          {productsData.map((product, index) => (
            <ProductCard key={index} {...product} />
          ))}
        </Carousel> */}
			</div>
		</div>
	);
};

export default CartPage;
