import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { ReactComponent as UploadIcon } from "common/assets/icons/file-upload.svg";
import { ReactComponent as DeleteIcon } from "common/assets/icons/trash-03.svg";
import { ReactComponent as UserIcon } from "common/assets/icons/user.svg";
import { P } from "common/P/P";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "components/App/store";
import { serviceConfig } from "configs";
import { getCookie } from "utils/cookie";
import Modal from "common/Modal/Modal";
import { updateProfile } from "redux/auth/profileAsyncThunks";
import { toast } from "react-toastify";

interface UploadPhotoProps {
	onChange: (files: File[]) => void;
	initialFiles?: (File | string)[];
	label?: string;
	onFileDelete?: (file: File | string) => void;
	letterId?: string;
	type?: string;
	uploadedPhoto?: File | string | any;
}

const UploadPhoto: React.FC<UploadPhotoProps> = ({
	onChange,
	initialFiles = [],
	label = "Upload Photo",
	onFileDelete,
	letterId,
	type,
	uploadedPhoto,
}) => {
	const dispatch = useDispatch<AppDispatch>();
	const auth = useSelector((state: RootState) => state.auth);
	const defaultProfileImage = auth.user?.ProfileImage
		? `${serviceConfig.apiUrl}/get-image/${auth.user.ProfileImage}`
		: null;
	const [selectedFile, setSelectedFile] = useState<File | string | null>(
		uploadedPhoto ||
			defaultProfileImage ||
			(initialFiles.length > 0 ? initialFiles[0] : null)
	);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const fileInputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (initialFiles && initialFiles.length > 0) {
			setSelectedFile(initialFiles[0]);
		}
	}, [initialFiles]);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0];
			setSelectedFile(file);
			onChange([file]);
		}
	};

	const openFileDialog = () => {
		fileInputRef.current?.click();
	};

	const removeFile = async () => {
		const token = getCookie("token");
		if (selectedFile) {
			if (typeof selectedFile === "string") {
				// Handle backend file deletion
				try {
					const response = await axios.put(
						`${serviceConfig.apiUrl}/profile/update`,
						{ ProfileImage: null },
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					);
					if (response.status === 200) {
						dispatch(updateProfile(response.data));
						toast.success("Image deleted successfully!");
					} else {
						toast.error("Failed to delete image.");
					}
					onFileDelete && onFileDelete(selectedFile);
					setSelectedFile(null); // Set to null for rendering default icon
				} catch (error) {
					console.error("Failed to delete file:", error);
					toast.error("Failed to delete image.");
				}
			} else {
				setSelectedFile(defaultProfileImage); // Set to default image if not string
			}
			onChange([]);
		}
		setIsModalOpen(false); // Close the modal after deletion
	};

	const handleRemoveClick = () => {
		if (typeof selectedFile === "string") {
			setIsModalOpen(true); // Open the modal if selected file is a string
		} else {
			removeFile(); // Directly remove the file if it's not a string
		}
	};

	return (
		<>
			<div className="grid gap-5 grid-cols-maxFr">
				<div className="relative h-max">
					{selectedFile && typeof selectedFile === "string" ? (
						<img
							src={selectedFile}
							alt="file_profile_image"
							className="w-16 h-16 border border-black rounded-full opacity-80"
						/>
					) : selectedFile ? (
						<img
							src={URL.createObjectURL(selectedFile as File)}
							alt="file_profile_image"
							className="w-16 h-16 border border-black rounded-full opacity-80"
						/>
					) : (
						<div className="flex items-center justify-center w-16 h-16 border border-black rounded-full opacity-80">
							<UserIcon className="w-7 h-7" />
						</div>
					)}
					<div
						className="absolute bottom-0 right-0 p-0.5 bg-white border rounded-full shadow-sm border-strokeColor cursor-pointer"
						onClick={handleRemoveClick}
					>
						<DeleteIcon className="stroke-redColor" />
					</div>
				</div>
				<div
					className="grid grid-flow-row grid-cols-1 gap-3 px-6 py-4 border cursor-pointer auto-rows-max border-strokeColor rounded-xl place-items-center place-content-center"
					onClick={openFileDialog}
				>
					<div className="p-2.5 rounded-lg shadow-sm border border-strokeColor">
						<UploadIcon />
					</div>
					<div className="grid grid-flow-row grid-cols-1 gap-1 auto-rows-max">
						<div className="grid gap-1 grid-cols-maxTwo">
							<P size="p3" type="semibold" className="text-mainColor">
								Click to upload
							</P>
							<P size="p3" type="regular">
								or drag and drop
							</P>
						</div>
						<P size="t1" type="regular">
							SVG, PNG, JPG or GIF (max. 800x400px)
						</P>
					</div>
					<input
						type="file"
						ref={fileInputRef}
						className="hidden"
						accept=".svg,.png,.jpg,.jpeg,.gif"
						onChange={handleFileChange}
					/>
				</div>
			</div>
			<Modal
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				title="Are you sure you want to delete this image?"
				desc="This action cannot be undone."
				onConfirm={removeFile}
				onCancel={() => setIsModalOpen(false)}
			/>
		</>
	);
};

export default UploadPhoto;
