import React from "react";
import Generate from "components/Generate/Generate";

const GeneratePage: React.FC = (): JSX.Element => {
	return (
		<>
			<div className="grid grid-cols-1">
				<Generate />
			</div>
		</>
	);
};

export default GeneratePage;
