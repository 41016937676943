import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ProductCardProps } from "common/ProductCard/ProductCard.props";
import FilterPanel from "components/FilterPanel/FilterPanel";
import ProductCard from "common/ProductCard/ProductCard";
import { serviceConfig } from "configs";
import { routeConstants } from "navigation";
import { RootState } from "components/App/store";
import { useSelector } from "react-redux";

const ShopPage: React.FC = () => {
	const [searchParams] = useSearchParams();
	const [products, setProducts] = useState<ProductCardProps[]>([]);
	const [filteredProducts, setFilteredProducts] = useState<ProductCardProps[]>(
		[]
	);
	const app = useSelector((state: RootState) => state.app);

	const navigate = useNavigate();

	useEffect(() => {
		const fetchProducts = async () => {
			try {
				const response = await fetch(`${serviceConfig.apiUrl}/products`);
				const data = await response.json();
				if (data.success) {
					const mappedProducts = data.data.map((product: any) => ({
						id: product._id,
						name: product.name,
						description: product.description,
						price: product.price,
						discountPrice: product.discountPrice,
						discountPercentage: product.discountPercentage,
						images: product.images.map((img: any) => img.savedImgPath),
						type: product.productType.name,
						length: product.length.name,
						shape: product.shape.name,
						offer: product.collectionType.name,
						colors: product.color.map((c: any) => c.name),
					}));
					setProducts(mappedProducts);
					setFilteredProducts(mappedProducts);
				} else {
					console.error(data.message);
				}
			} catch (error) {
				console.error("Error fetching products:", error);
			}
		};

		fetchProducts();
	}, []);

	useEffect(() => {
		const filters = {
			type: searchParams.getAll("type"),
			length: searchParams.getAll("length"),
			shape: searchParams.getAll("shape"),
			offers: searchParams.getAll("offers"),
			colors: searchParams.getAll("colors"),
		};

		const hasActiveFilters = Object.values(filters).some(
			(filter) => filter.length > 0
		);

		const filtered = hasActiveFilters
			? products.filter((product: any) => {
					return (
						(!filters.type.length || filters.type.includes(product.type)) &&
						(!filters.length.length ||
							filters.length.includes(product.length)) &&
						(!filters.shape.length || filters.shape.includes(product.shape)) &&
						(!filters.offers.length ||
							filters.offers.includes(product.offer)) &&
						(!filters.colors.length ||
							product.colors.some((color: any) =>
								filters.colors.includes(color)
							))
					);
			  })
			: products;

		setFilteredProducts(filtered);
	}, [searchParams, products]);

	const filterOptions = useMemo(() => {
		const types = Array.from(new Set(products.map((p) => p.type))).filter(
			Boolean
		) as string[];
		const lengths = Array.from(new Set(products.map((p) => p.length))).filter(
			Boolean
		) as string[];
		const shapes = Array.from(new Set(products.map((p) => p.shape))).filter(
			Boolean
		) as string[];
		const offers = Array.from(new Set(products.map((p) => p.offer))).filter(
			Boolean
		) as string[];
		const colors = Array.from(
			new Set(products.flatMap((p) => p.colors))
		).filter(Boolean) as string[];
		return {
			type: types,
			length: lengths,
			shape: shapes,
			offer: offers,
			colors: colors,
		};
	}, [products]);

	const handleProductClick = (productId: string) => {
		navigate(`${routeConstants.productDetails.route}/${productId}`);
	};

	return (
		<div
			className={`${
				app.isPlayground
					? "px-[80px] TwoXl:px-[90px] ThreeXl:px-[110px] grid-cols-[15%_85%]"
					: "px-[170px] TwoXl:px-[240px] ThreeXl:px-[300px] grid-cols-[20%_80%]"
			} py-8 grid gap-6`}
		>
			<div>
				<FilterPanel filterOptions={filterOptions} />
			</div>
			<div className="grid grid-flow-row auto-rows-max gap-x-4 gap-y-6 lg:grid-cols-3">
				{filteredProducts.map((product, index) => (
					<ProductCard
						key={index}
						images={product.images}
						name={product.name}
						price={product.price}
						discountPrice={product.discountPrice}
						discountPercentage={product.discountPercentage}
						onClick={() => product.id && handleProductClick(product.id)}
						id={product.id}
					/>
				))}
			</div>
		</div>
	);
};

export default ShopPage;
