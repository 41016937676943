import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { P } from "common/P/P";
import { Htag } from "common/Htag/Htag";
import { Button } from "common/Button/Button";
import { serviceConfig } from "configs";
import { ReactComponent as DownloadIcon } from "common/assets/icons/download-01.svg";

const SharedGeneratedCollectionPage: React.FC = () => {
	const { userGeneratedImageId } = useParams<{
		userGeneratedImageId: string;
	}>();
	const [collection, setCollection] = useState<any>(null);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		const fetchCollection = async () => {
			try {
				const response = await axios.get(
					`${serviceConfig.apiUrl}/get_collection/${userGeneratedImageId}`
				);
				setCollection(response.data.data);
				setLoading(false);
			} catch (error) {
				console.error("Error fetching collection:", error);
				setLoading(false);
			}
		};

		fetchCollection();
	}, [userGeneratedImageId]);

	if (loading) return <div>Loading...</div>;

	if (!collection) return <div>Collection not found</div>;

	// Extract image URLs from the collection
	const imageUrls = [
		collection.imageUrl1,
		collection.imageUrl2,
		collection.imageUrl3,
		collection.imageUrl4,
	].filter(Boolean); // Filter out any undefined or null values

	const handleDownloadAll = () => {
		const downloadUrl = `${serviceConfig.apiUrl}/download_collection/${userGeneratedImageId}`;
		window.location.href = downloadUrl;
	};

	return (
		<div className="px-[80px] TwoXl:px-[90px] ThreeXl:px-[110px] py-4">
			<Htag tag="h2" type="semibold">
				Shared Collection
			</Htag>
			<div className="grid grid-cols-1 gap-1 mt-4">
				<P size="p3" type="bold" className="text-mainColor">
					Shared by Mr./Ms. {collection.user_id.FirstName}{" "}
					{collection.user_id.LastName}
				</P>
				<P size="p2" type="regular" className="text-textColor">
					Prompt: {collection.prompt}
				</P>

				{imageUrls.length > 0 && (
					<div className="flex mt-2 space-x-2">
						{imageUrls.map((image: string, i: number) => (
							<img
								key={i}
								src={`${serviceConfig.apiUrl}${image}`}
								alt={`Generated ${i + 1}`}
								className="lg:w-[191px] TwoXl:w-[264px] lg:h-[191px] TwoXl:h-[264px] object-cover rounded-md"
							/>
						))}
					</div>
				)}

				<div className="grid mt-2 grid-cols-maxTwo place-items-center place-content-between">
					<div className="grid gap-4 grid-cols-maxTwo">
						<Button
							appearance="border"
							icon={<DownloadIcon />}
							paddingX="px-4"
							className="font-semibold"
							onClick={handleDownloadAll}
						>
							Download all
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SharedGeneratedCollectionPage;
