import React from "react";

import { ButtonProps } from "./Button.props";

export const Button = ({
	children,
	appearance,
	icon = "none",
	className = "",
	width = "max",
	paddingX = "px-16",
	paddingY = "py-[10px]",
	center = true,
	grid = "grid grid-cols-maxTwo gap-2",
	rounded = "rounded-lg",
	wishlist,
	...props
}: ButtonProps): JSX.Element => {
	return (
		<button
			className={`${
				appearance === "main" && wishlist
					? "bg-mainColor text-white border border-mainColor"
					: appearance === "main"
					? "bg-mainColor text-white hover:bg-[#9E77ED]"
					: appearance === "white"
					? "bg-white text-textPassiveColor shadow-none"
					: appearance === "border"
					? "bg-white border border-strokeColor text-textPassiveColor shadow-[0px 1px 2px 0px rgba(16_24_40_0.05)]"
					: appearance === "red"
					? "bg-redColor text-white"
					: appearance === "disabled"
					? "bg-[#F2F4F7] text-buttonColor cursor-not-allowed"
					: appearance === "gray"
					? "bg-[#F2F4F7] text-buttonColor cursor-pointer border border-[#EAECF0]"
					: ""
			} 
			w-${width} transition-all duration-300 group
			${
				center
					? "place-content-center place-items-center"
					: "place-content-start place-items-center"
			}
				${paddingY} ${paddingX} ${rounded} ${
				icon !== "none" && grid
			} ${className} text-sm font-medium`}
			{...props}
		>
			{icon !== "none" && <>{icon}</>}
			{children}
		</button>
	);
};
