import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import { Htag } from "common/Htag/Htag";
import { Input } from "common/Input/Input";
import { Select } from "common/Select/Select";
import { SelectItem } from "common/Select/SelectItem";
import { Button } from "common/Button/Button";
import { P } from "common/P/P";
import CartBody from "components/Cart/CartBody";
import { Autocomplete } from "@react-google-maps/api";
import { serviceConfig } from "configs";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "components/App/store";
import {
	setShipmentCostAndType,
	setShippingInformation,
} from "redux/shipping/shippingSlice";
import { useNavigate } from "react-router-dom";
import { routeConstants } from "navigation";

interface Country {
	_id: string;
	name: string;
	states: Region[];
}

interface Region {
	_id: string;
	name: string;
	code: string;
}

interface Inputs {
	fullName: string;
	email: string;
	phoneNumber: string;
	address: string;
	city: string;
	region: string;
	zipCode: string;
}

const ShippingAddressPage: React.FC = () => {
	const [autocomplete, setAutocomplete] =
		useState<google.maps.places.Autocomplete | null>(null);
	const [inputs, setInputs] = useState<Inputs>({
		fullName: "",
		email: "",
		phoneNumber: "",
		address: "",
		city: "",
		region: "",
		zipCode: "",
	});
	const [countries, setCountries] = useState<Country[]>([]);
	const [selectedCountry, setSelectedCountry] =
		useState<string>("United States");
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const cart = useSelector((state: RootState) => state.cart);
	const shipping = useSelector((state: RootState) => state.shippingInformation);
	const app = useSelector((state: RootState) => state.app);

	const subtotal = cart.totalAmount;
	const discount = cart.totalDiscount;
	const shipmentCost = subtotal > 50 ? 0 : shipping.shipmentCost;
	const grandTotal = subtotal - discount + shipmentCost;

	useEffect(() => {
		if (subtotal > 50) {
			dispatch(
				setShipmentCostAndType({ shipmentType: "Free", shipmentCost: 0 })
			);
		} else {
			dispatch(
				setShipmentCostAndType({ shipmentType: "Regular", shipmentCost: 7.5 })
			);
		}
	}, [subtotal, dispatch]);

	const fetchCountries = async () => {
		try {
			const response = await axios.get(`${serviceConfig.apiUrl}/countries`);
			if (response.data.success) {
				setCountries(response.data.data);
			}
		} catch (error) {
			console.error("Error fetching countries:", error);
		}
	};

	useEffect(() => {
		fetchCountries();
	}, []);

	const onLoad = useCallback((autoC: google.maps.places.Autocomplete) => {
		setAutocomplete(autoC);
	}, []);

	const onPlaceChanged = () => {
		if (autocomplete !== null) {
			const place = autocomplete.getPlace();
			let address = "";
			let city = "";
			let stateName = "";
			let zip = "";

			place.address_components?.forEach((component) => {
				const types = component.types;
				if (types.includes("street_number")) {
					address = component.long_name + " " + address;
				}
				if (types.includes("route")) {
					address += component.long_name;
				}
				if (types.includes("locality")) {
					city = component.long_name;
				}
				if (types.includes("administrative_area_level_1")) {
					stateName = component.long_name;
				}
				if (types.includes("postal_code")) {
					zip = component.long_name;
				}
			});

			setInputs({
				...inputs,
				address: address,
				city: city,
				region: stateName,
				zipCode: zip,
			});
			setSelectedCountry("United States");
		} else {
			console.log("Autocomplete is not loaded yet!");
		}
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setInputs((prevInputs) => ({
			...prevInputs,
			[name]: value,
		}));
	};

	const handleCountrySelect = (value: string) => {
		setSelectedCountry(value);
		setInputs((prevInputs) => ({
			...prevInputs,
			region: "",
		}));
	};

	const handleRegionSelect = (value: string) => {
		setInputs((prevInputs) => ({
			...prevInputs,
			region: value,
		}));
	};

	const handleShippingSelect = (type: string, cost: number) => {
		if (subtotal > 50 || type !== "Free") {
			dispatch(
				setShipmentCostAndType({ shipmentType: type, shipmentCost: cost })
			);
		}
	};

	const handleCheckout = () => {
		const { fullName, email, phoneNumber, address, city, region, zipCode } =
			inputs;
		if (
			fullName &&
			email &&
			phoneNumber &&
			address &&
			city &&
			region &&
			zipCode
		) {
			dispatch(
				setShippingInformation({
					fullName,
					email,
					phoneNumber,
					address,
					city,
					region,
					country: selectedCountry,
					zipCode,
					shipmentType: shipping.shipmentType,
					shipmentCost: shipping.shipmentCost,
				})
			);
			navigate(routeConstants.payment.route)
			// Proceed to Stripe or any other actions
		} else {
			alert("Please fill in all the fields.");
		}
	};

	return (
		<div className={`${app.isPlayground ? "px-[80px] TwoXl:px-[90px] ThreeXl:px-[110px]" : "px-[170px] TwoXl:px-[240px] ThreeXl:px-[300px]"} py-8 grid grid-cols-1 gap-4 grid-flow-row auto-rows-max`}>
			<Htag tag="h2" type="semibold">
				Checkout
			</Htag>
			<div className="grid grid-cols-[70%_30%] gap-4 pb-14">
				<div className="grid grid-flow-row grid-cols-1 gap-6 auto-rows-max">
					<div className="grid grid-flow-row grid-cols-1 gap-6 p-6 bg-white border border-gray-200 auto-rows-max rounded-xl">
						<div className="grid grid-flow-row grid-cols-1 gap-6 auto-rows-max">
							<Htag tag="h4" type="semibold">
								Select shipping country
							</Htag>
							<Select
								defaultSelectLabel="Select country"
								onSelect={handleCountrySelect}
								value={selectedCountry}
							>
								{countries.map((country) => (
									<SelectItem key={country._id} value={country.name}>
										{country.name}
									</SelectItem>
								))}
							</Select>
						</div>
						<div className="grid grid-flow-row grid-cols-1 pt-6 border-t border-gray-200 auto-rows-max">
							<div className="grid grid-flow-row grid-cols-1 gap-6 auto-rows-max">
								<Htag tag="h4" type="semibold">
									Shipping address
								</Htag>
								<div className="grid grid-flow-row grid-cols-1 gap-5 auto-rows-max">
									<Input
										appearance="default"
										type="text"
										name="fullName"
										placeholder="Enter your full name"
										label="Full name"
										withlabel
										value={inputs.fullName}
										onChange={handleInputChange}
									/>
									<div className="grid grid-cols-2 gap-3">
										<Input
											appearance="default"
											type="text"
											name="email"
											placeholder="Enter your email address"
											label="Email"
											withlabel
											value={inputs.email}
											onChange={handleInputChange}
										/>
										<Input
											appearance="default"
											type="text"
											name="phoneNumber"
											placeholder="Enter your phone number"
											label="Phone"
											withlabel
											value={inputs.phoneNumber}
											onChange={handleInputChange}
										/>
									</div>
									<Autocomplete
										onLoad={onLoad}
										onPlaceChanged={onPlaceChanged}
										restrictions={{ country: "us" }}
									>
										<Input
											appearance="default"
											type="text"
											name="address"
											placeholder="Enter your street name and house number"
											label="Street name and house number"
											withlabel
											value={inputs.address}
											onChange={handleInputChange}
										/>
									</Autocomplete>
									<div className="grid grid-cols-2 gap-3 h-max">
										<Input
											appearance="default"
											type="text"
											name="city"
											placeholder="City"
											label="City"
											withlabel
											value={inputs.city}
											onChange={handleInputChange}
										/>
										<Select
											label="Region"
											defaultSelectLabel="Select region"
											value={inputs.region}
											onSelect={handleRegionSelect}
										>
											{countries
												.find((country) => country.name === selectedCountry)
												?.states.map((state) => (
													<SelectItem key={state._id} value={state.name}>
														{state.name}
													</SelectItem>
												))}
										</Select>
									</div>
									<Input
										appearance="default"
										type="text"
										name="zipCode"
										placeholder="Enter your postal code"
										label="Postal code"
										withlabel
										value={inputs.zipCode}
										onChange={handleInputChange}
									/>
								</div>
							</div>
						</div>
					</div>

					<div className="grid grid-flow-row grid-cols-1 gap-6 p-6 bg-white border border-gray-200 auto-rows-max rounded-xl">
						<div className="grid grid-flow-row grid-cols-1 gap-6 auto-rows-max">
							<Htag tag="h4" type="semibold">
								Shipping method
							</Htag>
							<div className="grid grid-flow-row grid-cols-1 gap-3 auto-rows-max">
								<div
									className={`grid p-4 border rounded-lg grid-cols-maxTwo place-items-start place-content-between ${
										subtotal > 50 && shipping.shipmentType === "Free"
											? "border-violet-500 cursor-pointer"
											: "border-strokeColor cursor-not-allowed"
									}`}
									onClick={() => handleShippingSelect("Free", 0)}
								>
									<div className="flex items-start justify-start h-12 gap-3">
										<div className="pt-0.5 justify-center items-center flex">
											<div
												className={`w-5 h-5 p-1.5 ${
													subtotal > 50 && shipping.shipmentType === "Free"
														? "bg-violet-500"
														: "bg-gray-300"
												} rounded-full justify-center items-center flex`}
											>
												<div className="relative w-2 h-2 bg-white rounded-full" />
											</div>
										</div>
										<div className="grid grid-cols-1 grid-rows-maxTwo gap-0.5">
											<P
												size="p2"
												type="medium"
												className={`${subtotal < 50 && "!text-gray-400"}`}
											>
												Free shipping
											</P>
											{subtotal > 50 ? (
												<P size="p2" type="regular">
													7-30 business days
												</P>
											) : (
												<P size="p2" type="regular" className="!text-gray-400">
													Orders over $50
												</P>
											)}
										</div>
									</div>
									<P
										size="p2"
										type="regular"
										className={`${subtotal < 50 && "!text-gray-400"}`}
									>
										$0
									</P>
								</div>

								<div
									className={`grid p-4 border rounded-lg cursor-pointer grid-cols-maxTwo place-items-start place-content-between ${
										shipping.shipmentType === "Regular"
											? "border-violet-500"
											: "border-strokeColor"
									}`}
									onClick={() => handleShippingSelect("Regular", 7.5)}
								>
									<div className="flex items-start justify-start h-12 gap-3">
										<div className="pt-0.5 justify-center items-center flex">
											<div
												className={`w-5 h-5 p-1.5 ${
													shipping.shipmentType === "Regular"
														? "bg-violet-500"
														: "bg-strokeColor"
												} rounded-full justify-center items-center flex`}
											>
												<div className="relative w-2 h-2 bg-white rounded-full" />
											</div>
										</div>
										<div className="grid grid-cols-1 grid-rows-maxTwo gap-0.5">
											<P size="p2" type="medium">
												Regular shipping
											</P>
											<P size="p2" type="regular">
												4-14 business days
											</P>
										</div>
									</div>
									<P size="p2" type="regular">
										$7.50
									</P>
								</div>

								<div
									className={`grid p-4 border rounded-lg cursor-pointer grid-cols-maxTwo place-items-start place-content-between ${
										shipping.shipmentType === "Express"
											? "border-violet-500"
											: "border-strokeColor"
									}`}
									onClick={() => handleShippingSelect("Express", 25)}
								>
									<div className="flex items-start justify-start h-12 gap-3">
										<div className="pt-0.5 justify-center items-center flex">
											<div
												className={`w-5 h-5 p-1.5 ${
													shipping.shipmentType === "Express"
														? "bg-violet-500"
														: "bg-strokeColor"
												} rounded-full justify-center items-center flex`}
											>
												<div className="relative w-2 h-2 bg-white rounded-full" />
											</div>
										</div>
										<div className="grid grid-cols-1 grid-rows-maxTwo gap-0.5">
											<P size="p2" type="medium">
												Express shipping
											</P>
											<P size="p2" type="regular">
												1-3 business days
											</P>
										</div>
									</div>
									<P size="p2" type="regular">
										$25.00
									</P>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="grid grid-flow-row grid-cols-1 gap-6 p-6 bg-white border border-gray-200 auto-rows-max rounded-xl h-max">
					<div className="grid grid-flow-row grid-cols-1 gap-6">
						<Htag tag="h4" type="semibold">
							Your order
						</Htag>
						<CartBody className="gap-4" checkoutPage />

						<div className="self-stretch h-px border border-gray-200"></div>

						<div className="grid grid-flow-row grid-cols-1 gap-4 auto-rows-max h-28">
							<div className="grid gap-4 grid-cols-maxTwo place-content-between place-items-center">
								<P size="p1" type="medium" className="!text-[#475467]">
									Subtotal
								</P>
								<P size="p1" type="semibold" className="!text-[#475467]">
									${subtotal.toFixed(2)}
								</P>
							</div>

							<div className="grid gap-4 grid-cols-maxTwo place-content-between place-items-center">
								<P size="p1" type="medium" className="!text-[#475467]">
									Discount
								</P>
								<P size="p1" type="regular" className="!text-[#475467]">
									-${discount.toFixed(2)}
								</P>
							</div>

							<div className="grid gap-4 grid-cols-maxTwo place-content-between place-items-center">
								<P size="p1" type="medium" className="!text-[#475467]">
									Shipment cost
								</P>
								<P size="p1" type="regular" className="!text-[#475467]">
									${shipmentCost.toFixed(2)}
								</P>
							</div>
						</div>

						<div className="self-stretch h-px border border-gray-200"></div>
						<div className="grid gap-4 grid-cols-maxTwo place-content-between place-items-center">
							<P size="p1" type="medium" className="!text-[#475467]">
								Grand total
							</P>
							<Htag tag="h3" type="semibold">
								${grandTotal.toFixed(2)}
							</Htag>
						</div>
					</div>
					<Button
						appearance="main"
						paddingY="py-3.5"
						width="full"
						onClick={handleCheckout}
					>
						Continue to Stripe
					</Button>
				</div>
			</div>
		</div>
	);
};

export default ShippingAddressPage;
