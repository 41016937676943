import React, { useState, useRef, useEffect } from "react";
import { Button } from "common/Button/Button";
import { Htag } from "common/Htag/Htag";
import axios from "axios"; // Add axios import

import { ReactComponent as ArrowLeft } from "common/assets/icons/arrow-left.svg";
import { ReactComponent as LoadingIcon } from "common/assets/icons/settings.svg";
import { ReactComponent as DownloadIcon } from "common/assets/icons/download-01.svg";
import { ReactComponent as ShareIcon } from "common/assets/icons/share-07.svg";
import { ReactComponent as EditIcon } from "common/assets/icons/edit-02.svg";
import { ReactComponent as RetryIcon } from "common/assets/icons/refresh-ccw-02.svg";
import { ReactComponent as DeleteIcon } from "common/assets/icons/trash-03.svg";
import { ReactComponent as AvatarIcon } from "common/assets/icons/user.svg";
import { ReactComponent as SendIcon } from "common/assets/icons/send-03.svg";

import NailAvatar from "common/assets/images/Avatar.png";

import { useNavigate } from "react-router-dom";
import { P } from "common/P/P";
import { useSelector } from "react-redux";
import { RootState } from "components/App/store";
import { serviceConfig } from "configs";
import { Input } from "common/Input/Input";
import { toast } from "react-toastify";

interface Message {
	sender: "user" | "ai";
	text?: string;
	images?: string[];
	userGeneratedImageId?: string;
	isLoading?: boolean;
}

const Generate: React.FC = () => {
	const auth = useSelector((state: RootState) => state.auth);
	const navigate = useNavigate();
	const [messages, setMessages] = useState<Message[]>([]);
	const [input, setInput] = useState<string>("");
	console.log("Message", messages);

	const chatContainerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		// Scroll to the bottom of the chat whenever a new message is added
		if (chatContainerRef.current) {
			chatContainerRef.current.scrollTop =
				chatContainerRef.current.scrollHeight;
		}
	}, [messages]);

	const handleSendMessage = async () => {
		if (!input.trim()) return;

		const userMessage: Message = { sender: "user", text: input.trim() };
		setMessages((prevMessages) => [...prevMessages, userMessage]);
		setInput("");

		// Simulate AI response with a loading state
		const loadingMessage: Message = { sender: "ai", isLoading: true };
		setMessages((prevMessages) => [...prevMessages, loadingMessage]);

		try {
			// Send the prompt to the backend
			const response = await axios.post(
				`${serviceConfig.apiUrl}/generate_image/`,
				{
					prompt: input.trim(),
					user_id: auth.user?._id,
					samples: 4,
					version: "v1",
				}
			);

			const { userGeneratedImageId, images } = response.data.data;

			const aiResponse: Message = {
				sender: "ai",
				text: `Generated images based on your prompt: "${input.trim()}"`,
				images: images.map((img: any) => img.url),
				userGeneratedImageId, // Store the userGeneratedImageId
			};

			// Replace the loading message with the actual response
			setMessages((prevMessages) => [...prevMessages.slice(0, -1), aiResponse]);
		} catch (error) {
			console.error("Error generating image:", error);
			// Handle error appropriately, maybe by showing a message to the user
			setMessages((prevMessages) => prevMessages.slice(0, -1)); // Remove loading message
		}
	};

	const handleEdit = (text: string) => {
		// Extract the actual prompt from the message text
		const prompt = text.replace(
			/Generated images based on your prompt: "([^"]+)"/,
			"$1"
		);

		setInput(prompt); // Autofill the input field with the extracted prompt
		chatContainerRef.current?.scrollIntoView({ behavior: "smooth" }); // Scroll to input
	};

	const handleRetry = async (text: string) => {
		// Extract the actual prompt from the message text
		console.log("text:", text);
		const prompt = text.replace(
			/Generated images based on your prompt: "([^"]+)"/,
			"$1"
		);

		const loadingMessage: Message = { sender: "ai", isLoading: true };
		setMessages((prevMessages) => [...prevMessages, loadingMessage]);

		try {
			// Send the original prompt to the backend
			const response = await axios.post(
				`${serviceConfig.apiUrl}/generate_image/`,
				{
					prompt,
					user_id: auth.user?._id,
					samples: 4,
					version: "v1",
				}
			);

			const { userGeneratedImageId, images } = response.data.data;

			const aiResponse: Message = {
				sender: "ai",
				text: `Regenerated images based on the prompt: "${prompt}"`,
				images: images.map((img: any) => img.url),
				userGeneratedImageId, // Store the userGeneratedImageId
			};

			// Replace the loading message with the actual response
			setMessages((prevMessages) => [...prevMessages.slice(0, -1), aiResponse]);
		} catch (error) {
			console.error("Error generating image:", error);
			setMessages((prevMessages) => prevMessages.slice(0, -1)); // Remove loading message
		}
	};

	const handleDelete = async (userGeneratedImageId: string) => {
		try {
			await axios.delete(
				`${serviceConfig.apiUrl}/delete_image/${userGeneratedImageId}`
			);
			// Filter out the deleted message from the messages state
			setMessages((prevMessages) =>
				prevMessages.filter(
					(message) => message.userGeneratedImageId !== userGeneratedImageId
				)
			);
		} catch (error) {
			console.error("Error deleting image:", error);
			// Handle error appropriately
		}
	};

	const handleShare = (userGeneratedImageId: string) => {
		const shareableUrl = `${window.location.origin}/shared/${userGeneratedImageId}`;
		navigator.clipboard.writeText(shareableUrl);
		toast.success("Link copied successfully!");
	};

	const handleDownloadAll = (userGeneratedImageId: string) => {
		const downloadUrl = `${serviceConfig.apiUrl}/download_collection/${userGeneratedImageId}`;
		window.location.href = downloadUrl; // Trigger the download
	};

	return (
		<div className="flex flex-col h-screen overflow-hidden">
			<div className="px-[80px] TwoXl:px-[90px] ThreeXl:px-[110px] py-4 border-b border-strokeColor">
				<div className="grid gap-6 grid-cols-maxTwo place-items-center">
					<Button
						appearance="border"
						onClick={() => navigate(-1)}
						icon={<ArrowLeft />}
						paddingX="px-2.5"
						paddingY="py-2.5"
						grid="grid-cols-1"
					/>
					<Htag tag="h3" type="semibold">
						AI Nail Generation
					</Htag>
				</div>
			</div>

			{/* Chat History */}
			<div
				ref={chatContainerRef}
				className="flex-grow px-[177px] py-6 overflow-y-auto"
				style={{ marginBottom: "120px" }} // This is to prevent chat from overlapping the input area
			>
				<div className="grid grid-flow-row grid-cols-1 gap-8 auto-rows-max">
					{messages.map((message, index) => (
						<div
							key={index}
							className={`flex items-start gap-4 ${
								message.sender === "user" ? "justify-start" : "justify-start"
							}`}
						>
							{message.sender === "user" ? (
								auth.user?.ProfileImage ? (
									<img
										src={`${serviceConfig.apiUrl}/get-image/${auth.user?.ProfileImage}`}
										alt="User avatar"
										className="w-10 h-10 rounded-full"
									/>
								) : (
									<div className="flex items-center justify-center w-10 h-10 p-1.5 border rounded-full border-strokeColor">
										<AvatarIcon />
									</div>
								)
							) : (
								<img
									src={NailAvatar}
									alt="Nail AI avatar"
									className="w-10 h-10 rounded-full"
								/>
							)}

							<div className="grid grid-cols-1 gap-1">
								<P size="p3" type="bold" className="text-mainColor">
									{message.sender === "user"
										? auth.user?.FirstName || auth.user?.LastName
											? `${auth.user?.FirstName} ${auth.user?.LastName}`
											: "No Name"
										: "Nail AI"}
								</P>
								<P size="p2" type="regular" className="text-textColor">
									{message.text}
								</P>

								{message.images && (
									<div className="flex mt-2 space-x-2">
										{message.images.map((image, i) => (
											<img
												key={i}
												src={`${serviceConfig.apiUrl}${image}`}
												alt={`Generated ${i}`}
												className="lg:w-[191px] TwoXl:w-[264px] lg:h-[191px] TwoXl:h-[264px] object-cover rounded-md"
											/>
										))}
									</div>
								)}
								{message.sender === "ai" && message.images && (
									<div className="grid mt-2 grid-cols-maxTwo place-items-center place-content-between">
										<div className="grid gap-4 grid-cols-maxTwo">
											<Button
												appearance="border"
												icon={<DownloadIcon />}
												paddingX="px-4"
												className="font-semibold"
												onClick={() =>
													handleDownloadAll(message.userGeneratedImageId || "")
												}
											>
												Download all
											</Button>

											<Button
												appearance="border"
												icon={<ShareIcon />}
												paddingX="px-3"
												grid="grid grid-cols-1 place-items-center place-content-center"
												onClick={() =>
													handleShare(message.userGeneratedImageId || "")
												}
											/>
										</div>
										<div className="grid gap-4 grid-cols-maxFour">
											<Button
												appearance="border"
												icon={<EditIcon />}
												paddingX="px-3"
												grid="grid grid-cols-1 place-items-center place-content-center"
												onClick={() => handleEdit(message.text || "")}
											/>

											<Button
												appearance="border"
												icon={<RetryIcon />}
												paddingX="px-3"
												grid="grid grid-cols-1 place-items-center place-content-center"
												onClick={() => handleRetry(message.text || "")}
											/>

											<Button
												appearance="border"
												icon={<DeleteIcon />}
												paddingX="px-3"
												grid="grid grid-cols-1 place-items-center place-content-center"
												onClick={() =>
													handleDelete(message.userGeneratedImageId || "")
												}
											/>
										</div>
									</div>
								)}
								{message.isLoading && (
									<div className="flex mt-2">
										<LoadingIcon className="animate-spin" />
									</div>
								)}
							</div>
						</div>
					))}
				</div>
			</div>

			{/* Input Area */}
			<div className="fixed bottom-0 left-0 right-0 px-[177px] py-6 bg-white">
				<div className="relative">
					<Input
						type="text"
						value={input}
						onChange={(e) => setInput(e.target.value)}
						onKeyDown={(e) => {
							if (e.key === "Enter") handleSendMessage();
						}}
						placeholder="Enter prompt here"
						className="!py-4"
						appearance="default"
						name="chat"
						withlabel={false}
					/>
					<div className="absolute top-1 right-1">
						<Button
							appearance="gray"
							onClick={handleSendMessage}
							icon={<SendIcon />}
							grid="grid-cols-1"
							paddingX="px-2.5"
							paddingY="py-2.5"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Generate;
