import React from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import { ReactComponent as PencilIcon } from "common/assets/icons/pencil.svg";
import { ReactComponent as SmileIcon } from "common/assets/icons/emoji-smile.svg";
import { ReactComponent as StarIcon } from "common/assets/icons/sparkles-soft-filled.svg";

interface KeyFeaturesShowcaseProps {}

const KeyFeaturesShowcase: React.FC<KeyFeaturesShowcaseProps> = () => {
	return (
		<div className="px-[170px] TwoXl:px-[240px] ThreeXl:px-[300px] py-8 grid grid-cols-1 grid-rows-maxTwo gap-6">
			<Htag tag="h2" type="semibold" className="text-center">
				Key Features
			</Htag>
			<div className="grid grid-cols-3 gap-6">
				<div className="grid grid-cols-1 gap-4 p-6 bg-white shadow-helloMotto grid-rows-maxFr grow shrink basis-0 rounded-xl bg-gradient-to-tr from-green-200 from-10% via-green-100 via-20% to-[#ffffff_70%] to-80%">
					<div className="inline-flex items-center justify-center w-12 h-12 p-1 bg-green-200 border-8 rounded-xl border-teal-50">
						<PencilIcon />
					</div>
					<div className="grid grid-cols-1 gap-2 grid-rows-maxTwo">
						<Htag tag="h3" type="semibold">
							AI-Powered Design:
						</Htag>
						<P size="p1" type="medium">
							Our advanced AI interprets your descriptions and images to craft
							unique nail designs
						</P>
					</div>
				</div>

				<div className="grid grid-cols-1 gap-4 p-6 bg-white shadow-helloMotto grid-rows-maxFr grow shrink basis-0 rounded-xl bg-gradient-to-tr from-pink-200 from-10% via-pink-100 via-20% to-[#ffffff_70%] to-80%">
					<div className="inline-flex items-center justify-center w-12 h-12 p-1 bg-pink-200 border-8 rounded-xl border-pink-50">
						<SmileIcon />
					</div>
					<div className="grid grid-cols-1 gap-2 grid-rows-maxTwo">
						<Htag tag="h3" type="semibold">
							User-Friendly Interface:
						</Htag>
						<P size="p1" type="medium">
							Easily describe or upload your dream design and watch the AI work
							its magic.
						</P>
					</div>
				</div>

				<div className="grid grid-cols-1 gap-4 p-6 bg-white shadow-helloMotto grid-rows-maxFr grow shrink basis-0 rounded-xl bg-gradient-to-tr from-cyan-200 from-10% via-cyan-100 via-20% to-[#ffffff_70%] to-80%">
					<div className="inline-flex items-center justify-center w-12 h-12 p-1 border-8 bg-cyan-200 rounded-xl border-cyan-50">
						<StarIcon />
					</div>
					<div className="grid grid-cols-1 gap-2 grid-rows-maxTwo">
						<Htag tag="h3" type="semibold">
							Harmony with Fashion:
						</Htag>
						<P size="p1" type="medium">
							An upcoming feature that suggests nail designs based on your dress
							image for a perfect outfit match.
						</P>
					</div>
				</div>
			</div>
		</div>
	);
};

export default KeyFeaturesShowcase;
