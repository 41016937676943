import React from "react";
import { CartRowProps } from "./Cart.props";
import CounterButton from "common/CounterButton/CounterButton";
import { P } from "common/P/P";
import { ReactComponent as TrashIcon } from "common/assets/icons/trash-03.svg";
import { useDispatch } from "react-redux";
import { AppDispatch } from "components/App/store";
import {
	decreaseQuantity,
	increaseQuantity,
	removeItem,
} from "redux/cart/cartSlice";
import { serviceConfig } from "configs";

const CartRow: React.FC<CartRowProps> = ({
	imgSrc,
	name,
	size,
	color,
	price,
	discount,
	discountPercentage,
	discountPrice,
	quantity,
	productId,
	shapeId,
	sizeId,
	checkoutPage,
}) => {
	const dispatch = useDispatch<AppDispatch>();

	const handleIncreaseQuantity = () => {
		dispatch(increaseQuantity({ productId, shapeId, sizeId }));
	};

	const handleDecreaseQuantity = () => {
		if (quantity > 1) {
			dispatch(decreaseQuantity({ productId, shapeId, sizeId }));
		}
	};

	const handleRemoveItem = () => {
		dispatch(removeItem({ productId, shapeId, sizeId }));
	};

	return checkoutPage ? (
		<div className="grid gap-3 grid-cols-maxFr place-items-center">
			<div className="inline-flex flex-col items-start justify-start w-16 h-16 bg-black border border-gray-200 rounded-lg bg-opacity-10">
				<img
					src={`${serviceConfig.apiUrl}/get-image/${imgSrc}`}
					alt={name}
					className="object-cover w-full h-full"
				/>
			</div>
			<div className="grid grid-flow-row grid-cols-1 gap-1 auto-rows-max grow shrink basis-0">
				<div className="grid grid-flow-row grid-cols-1 auto-rows-max gap-0.5">
					<P size="p3" type="medium" className="truncate-1-lines">
						{name}
					</P>
					<div className="grid gap-1 grid-cols-maxThree place-items-center">
						<P size="p2" type="regular" className="!text-[#475467]">
							{color}
						</P>
						<div className="w-4 h-px rotate-90 border border-slate-600"></div>
						<P size="p2" type="regular" className="!text-[#475467]">
							{size}
						</P>
					</div>
				</div>
				<P size="p2" type="regular" className="!text-[#475467]">
					x{quantity}
				</P>
			</div>
		</div>
	) : (
		<div className="flex flex-col items-start self-stretch justify-start py-8 border-t border-gray-200">
			<div className="inline-flex items-center self-stretch justify-start gap-4">
				<div className="flex items-center justify-start h-20 grow shrink basis-0">
					<div className="flex items-center justify-start h-20 gap-4 pr-8 grow shrink basis-0">
						<div className="inline-flex flex-col items-start justify-start w-16 h-16 border border-gray-200 rounded-lg">
							<img
								src={`${serviceConfig.apiUrl}/get-image/${imgSrc}`}
								alt={name}
								className="object-cover w-full h-full"
							/>
						</div>
						<div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex">
							<P size="p2" type="semibold">
								{name}
							</P>

							<div className="grid gap-1 grid-cols-maxThree place-items-center">
								<P size="p2" type="regular" className="!text-[#475467]">
									{color}
								</P>
								<div className="w-4 h-px rotate-90 border border-slate-600"></div>
								<P size="p2" type="regular" className="!text-[#475467]">
									{size}
								</P>
							</div>
						</div>
					</div>
					<div className="inline-flex flex-col items-start justify-start gap-1 pr-8 w-52">
						<div className="justify-start items-start gap-2.5 inline-flex">
							<P size="p2" type="semibold">
								${discountPrice ? discountPrice.toFixed(2) : price.toFixed(2)}
							</P>
						</div>
						{discountPrice && (
							<div className="justify-start items-center gap-2.5 inline-flex">
								<div className="px-1.5 py-0.5 bg-red-500 rounded border border-red-300 justify-center items-center gap-2.5 flex">
									<P size="t1" type="medium" className="!text-white">
										{discountPercentage}%
									</P>
								</div>
								<P
									size="p2"
									type="regular"
									className="!text-[#98A2B3] line-through"
								>
									${price.toFixed(2)}
								</P>
							</div>
						)}
					</div>
					<div className="inline-flex flex-col items-start justify-between pr-10 w-36 h-9">
						<CounterButton
							counter={quantity}
							onDecrease={handleDecreaseQuantity}
							onIncrease={handleIncreaseQuantity}
							onBelowLimit={() => {}}
							className="place-self-end"
						/>
					</div>
					<div
						className="justify-center items-center gap-1.5 flex cursor-pointer"
						onClick={handleRemoveItem}
					>
						<TrashIcon />
						<P size="p3" type="semibold" className="cursor-pointer">
							Delete
						</P>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CartRow;
