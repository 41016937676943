import React, { useState } from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import { Input } from "common/Input/Input";
import { Button } from "common/Button/Button";
import { ReactComponent as ResetIcon } from "common/assets/icons/password.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { routeConstants } from "navigation";
import { authService } from "services/authService";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { AppDispatch } from "components/App/store";
import { setUser } from "redux/auth/authSlice";

const NewPasswordPage: React.FC = (): JSX.Element => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch<AppDispatch>();
	const email = location.state?.email;
	const otp = location.state?.otp;

	const [formData, setFormData] = useState({
		password: "",
		confirmPassword: "",
	});
	const [registerError, setRegisterError] = useState<string | null>(null);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const handlePasswordReset = async () => {
		if (formData.password !== formData.confirmPassword) {
			toast.error("Passwords do not match.");
			return;
		}

		if (formData.password.length < 8 || formData.confirmPassword.length < 8) {
			toast.error("Password need to be at least 8 characters");
			return;
		}

		try {
			const response = await authService.forgetPasswordFinal(
				email,
				otp,
				formData.password
			);
			dispatch(setUser(response.data.user));
			toast.success("Password reset successfully.");
			navigate(routeConstants.root.route);
		} catch (error: any) {
			const errorMessage =
				error.response?.data?.message || "Failed to reset password.";
			toast.error(errorMessage);
			setRegisterError(errorMessage);
		}
	};

	return (
		<div className="grid grid-cols-1 minPhone:px-5 minPhone:pt-14 minPhone:pb-6 lg:px-32 lg:pb-20 lg:pt-24">
			<div className="grid grid-flow-row minPhone:gap-6 xl:gap-8 xl:place-content-center minPhone:place-items-stretch xl:place-items-center auto-rows-max minPhone:pt-28 minPhone:pb-8 xl:pt-0 xl:pb-0 minPhone:px-4 xl:px-0 z-[99]">
				<ResetIcon />
				<div className="grid gap-2 place-content-center place-items-center">
					<Htag tag="h2" type="semibold">
						New password
					</Htag>
					<P size="p2" type="regular" className="">
						Enter your new password
					</P>
				</div>
				<div className="grid gap-6">
					<Input
						appearance="default"
						minWidth={window.innerWidth < 1280 ? false : true}
						type="password"
						withlabel={true}
						label="Password*"
						name="password"
						placeholder="Choose a password"
						onChange={handleInputChange}
						value={formData.password}
					/>
					<Input
						appearance="default"
						minWidth={window.innerWidth < 1280 ? false : true}
						type="password"
						withlabel={true}
						label="Confirm password"
						name="confirmPassword"
						placeholder="Confirm password"
						onChange={handleInputChange}
						value={formData.confirmPassword}
					/>
					<div className="grid gap-4">
						<Button
							appearance="main"
							grid="grid grid-cols-1"
							width="full"
							className={
								formData.password.length < 8 || formData.confirmPassword.length < 8
									? "cursor-not-allowed font-semibold"
									: "font-semibold"
							}
							onClick={handlePasswordReset}
						>
							Reset password
						</Button>
					</div>
					<div className="grid gap-2 grid-cols-maxTwo place-items-center place-content-center">
						<P size="p3" type="regular" className="cursor-pointer">
							Already have an account?
						</P>
						<Link to={routeConstants.login.route}>
							<P
								size="p3"
								type="semibold"
								className="cursor-pointer !text-mainColor"
							>
								Log in
							</P>
						</Link>
					</div>
				</div>
				{registerError && (
					<div className="text-red-500">
						<P size="p2" type="semibold">
							{registerError}
						</P>
					</div>
				)}
			</div>
		</div>
	);
};

export default NewPasswordPage;
