import React, { forwardRef } from "react";
import Slider from "react-slick";

import { serviceConfig } from "configs";

type ThumbnailSliderProps = {
	images: string[];
	onThumbnailClick: (index: number) => void;
};

const ThumbnailSliderComponent = forwardRef<Slider, ThumbnailSliderProps>(
	({ images, onThumbnailClick }, ref) => {
		console.log("IMAGES", images);
		const thumbnailSettings = {
			dots: false,
			infinite: false, // Set to true if you want infinite scroll
			speed: 500,
			slidesToShow: 4, // Number of thumbnails to show
			slidesToScroll: 1,
			focusOnSelect: true,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
					},
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
					},
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
		};

		return (
			<div className="relative thumbnail-slider w-[95%]">
				<Slider ref={ref} {...thumbnailSettings}>
					{images.map((image, index) => (
						<div
							key={index}
							onClick={() => onThumbnailClick(index)}
							className="px-1"
						>
							<img
								src={`${serviceConfig.apiUrl}/get-image/${image}`}
								alt={`Thumbnail ${index + 1}`}
								className="object-cover w-full cursor-pointer minPhone:w-[109px] xl:w-[182px] minPhone:h-[109px] xl:h-[182px] rounded-md"
								loading="lazy"
							/>
						</div>
					))}
				</Slider>
			</div>
		);
	}
);

ThumbnailSliderComponent.displayName = "ThumbnailSliderComponent";
export default ThumbnailSliderComponent;
