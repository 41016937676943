import React from "react";
import { OrderStatusProps } from "./OrderStatus.props";
import { P } from "common/P/P";

const OrderStatus: React.FC<OrderStatusProps> = ({ status, size = "p3" }) => {
	switch (status) {
		case "Waiting":
			return (
				<P
					size={size}
					type="regular"
					className="px-2 py-1 rounded-2xl text-[#B54708] bg-[#FFFAEB] border border-[#FEDF89] w-max"
				>
					Waiting for shipment
				</P>
			);
		case "Preparing":
			return (
				<P
					size={size}
					type="regular"
					className="px-2 py-1 font-medium rounded-2xl text-preparingColor w-max"
				>
					Preparing
				</P>
			);
		case "Shipped":
			return (
				<P
					size={size}
					type="regular"
					className="px-2 py-1 font-medium rounded-2xl text-[#067647] bg-[#ECFDF3] border border-[#ABEFC6] w-max"
				>
					Shipped
				</P>
			);
		case "Canceled":
			return (
				<P
					size={size}
					type="regular"
					className="px-2 py-1 font-medium rounded-2xl bg-[#FEF3F2] text-[#B42318] border border-[#FECDCA] w-max"
				>
					Cancelled
				</P>
			);
		case "Confirmed":
			return (
				<P
					size={size}
					type="regular"
					className="px-2 py-1 font-medium rounded-2xl text-[#344054] bg-[#F9FAFB] border border-[#EAECF0] w-max"
				>
					Confirmed
				</P>
			);
		default:
			return null;
	}
};

export default OrderStatus;
