import React, { useCallback, useEffect, useState } from "react";
import { P } from "common/P/P";
import { Button } from "common/Button/Button";

import { ReactComponent as DownloadIcon } from "common/assets/icons/download-01.svg";
import { ReactComponent as ShareIcon } from "common/assets/icons/share-07.svg";
import { ReactComponent as EditIcon } from "common/assets/icons/edit-02.svg";
import { ReactComponent as DeleteIcon } from "common/assets/icons/trash-03.svg";

import { motion } from "framer-motion";
import { GenerateHistoryItemProps } from "./GenerateHistoryItem.props";
import { serviceConfig } from "configs";

const GenerateHistoryItem: React.FC<GenerateHistoryItemProps> = ({
	prompt,
	images,
	time,
	onDownload,
	onShare,
	onEdit,
	onDelete,
}): JSX.Element => {
	const [isExpanded, setIsExpanded] = useState(false);

	const [isOverflowing, setIsOverflowing] = useState(false);

	const textContent = prompt;

	const checkOverflowAndScreenSize = useCallback(() => {
		let charLengthThreshold = 150;

		if (window.innerWidth > 1900) {
			charLengthThreshold = 200;
		} else if (window.innerWidth > 1580) {
			charLengthThreshold = 150;
		} else if (window.innerWidth > 1200) {
			charLengthThreshold = 80;
		} else {
			charLengthThreshold = 70;
		}

		setIsOverflowing(textContent.length > charLengthThreshold);
	}, [textContent.length]);

	useEffect(() => {
		checkOverflowAndScreenSize();
		window.addEventListener("resize", checkOverflowAndScreenSize);
		return () =>
			window.removeEventListener("resize", checkOverflowAndScreenSize);
	}, [checkOverflowAndScreenSize]);

	const toggleExpand = () => setIsExpanded(!isExpanded);

	const variants = {
		collapsed: {
			height: "23px",
			transition: { duration: 0.3, ease: "easeInOut" },
		},
		expanded: {
			height: "auto",
			transition: { duration: 0.3, ease: "easeInOut" },
		},
	};

	return (
		<div className="grid grid-cols-1 gap-4 p-6 bg-[#F9FAFB] rounded-xl">
			<motion.div
				initial="collapsed"
				animate={isExpanded ? "expanded" : "collapsed"}
				variants={variants}
				style={{ overflow: "hidden" }}
			>
				<div className="flex gap-2 place-content-between">
					<P
						size="p2"
						type="regular"
						className={
							isExpanded ? "overflow-hidden" : "truncate overflow-hidden"
						}
					>
						{textContent}
						{isOverflowing && isExpanded && (
							<span
								className="ml-2 cursor-pointer text-mainColor"
								onClick={toggleExpand}
							>
								Collapse
							</span>
						)}
					</P>
					{isOverflowing && !isExpanded && (
						<P
							size="p2"
							type="regular"
							className="cursor-pointer text-mainColor"
							onClick={toggleExpand}
						>
							More
						</P>
					)}
				</div>
			</motion.div>

			<div className="grid gap-4 lg:grid-cols-maxFour">
				{images.map((image: any, index: number) => (
					<img
						src={`${serviceConfig.apiUrl}${image}`}
						className="lg:w-[234px] TwoXl:w-[264px] lg:h-[234px] TwoXl:h-[264px] object-cover rounded-md"
						alt={`nail_${index}`}
					/>
				))}
			</div>
			<div className="grid grid-cols-maxTwo place-items-center place-content-between">
				<P size="p2" type="regular" className="text-buttonColor">
					{time}
				</P>
				<div className="grid gap-4 grid-cols-maxFour">
					<Button
						appearance="border"
						icon={<DownloadIcon />}
						paddingX="px-4"
						className="font-semibold"
						onClick={onDownload}
					>
						Download all
					</Button>
					<Button
						appearance="border"
						icon={<ShareIcon />}
						paddingX="px-3"
						grid="grid grid-cols-1 place-items-center place-content-center"
						onClick={onShare}
					/>
					<Button
						appearance="border"
						icon={<EditIcon />}
						paddingX="px-3"
						grid="grid grid-cols-1 place-items-center place-content-center"
						onClick={onEdit}
					/>
					<Button
						appearance="border"
						icon={<DeleteIcon />}
						paddingX="px-3"
						grid="grid grid-cols-1 place-items-center place-content-center"
						onClick={onDelete}
					/>
				</div>
			</div>
		</div>
	);
};

export default GenerateHistoryItem;
