import React from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import EllipticalImages from "components/EllipticalImages/EllipticalImages";
import { ReactComponent as TopBackground } from "common/assets/icons/vision_bg.svg";

// Define TypeScript interface for any props if needed
interface AIVisionShowcaseProps {}

const AIVisionShowcase: React.FC<AIVisionShowcaseProps> = () => {
	return (
		<div className="px-[170px] TwoXl:px-[240px] ThreeXl:px-[300px] py-8 lg:min-h-[640px]">
			<div className="relative grid grid-cols-2 gap-20 bg-white shadow-helloMotto rounded-3xl p-14">
				<TopBackground className="absolute top-0 left-0 rounded-tl-3xl" />
				<div className="grid grid-cols-1 gap-4 grid-rows-maxTwo place-content-center">
					<P size="p2" type="semibold" className="!text-mainColor">
						About
					</P>
					<div className="flex flex-col items-start self-stretch justify-start h-64 gap-4">
						<div className="grid grid-cols-1 gap-1">
							<Htag tag="h2" type="bold" className="text-[48px]">
								Revolutionizing
							</Htag>
							<Htag
								tag="h2"
								type="bold"
								className="text-[48px] leading-snug"
								style={{
									backgroundImage:
										"linear-gradient(to left, rgba(230, 80, 243, 1) 10%, rgba(112, 40, 228, 1) 100%)",
									WebkitBackgroundClip: "text",
									backgroundClip: "text",
									color: "transparent",
									WebkitTextFillColor: "transparent",
								}}
							>
								Nail Art with AI
							</Htag>
						</div>
						<P size="p1" type="regular" className="!text-neutral-500">
							Naildesign.ai uses cutting-edge AI to bring your dream nail
							designs to life. Just describe your vision or upload an image, and
							let our AI create a bespoke nail art design just for you.
						</P>
					</div>
				</div>

				<EllipticalImages />
			</div>
		</div>
	);
};

export default AIVisionShowcase;
