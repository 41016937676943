import React, { useState } from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import { Input } from "common/Input/Input";
import { Button } from "common/Button/Button";
import { ReactComponent as ResetIcon } from "common/assets/icons/password.svg";
import { Link, useNavigate } from "react-router-dom";
import { routeConstants } from "navigation";
import { authService } from "services/authService";
import { toast } from "react-toastify";

const ResetPasswordPage: React.FC = (): JSX.Element => {
	const navigate = useNavigate();
	const [formData, setFormData] = useState({ email: "" });
	const [resetError, setResetError] = useState<string | null>(null);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const validateEmail = (email: string): boolean => {
		const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
		return pattern.test(email);
	};

	const handleSendResetLink = async () => {
		if (!validateEmail(formData.email)) {
			toast.error("Invalid email format.");
			setResetError("Invalid email format.");
			return;
		}

		try {
			await authService.forgetPasswordInit(formData.email);
			toast.success("OTP sent to email for password reset.");
			navigate(routeConstants.verificationOtp.route, {
				state: { email: formData.email, origin: "forget" },
			});
		} catch (error: any) {
			const errorMessage = error.response?.data?.message || "Failed to send reset link.";
			toast.error(errorMessage);
			setResetError(errorMessage);
		}
	};

	return (
		<div className="grid grid-cols-1 minPhone:px-5 minPhone:pt-14 minPhone:pb-6 lg:px-32 lg:pb-20 lg:pt-24">
			<div className="grid grid-flow-row minPhone:gap-6 xl:gap-8 xl:place-content-center minPhone:place-items-stretch xl:place-items-center auto-rows-max minPhone:pt-28 minPhone:pb-8 xl:pt-0 xl:pb-0 minPhone:px-4 xl:px-0 z-[99]">
				<ResetIcon />
				<div className="grid gap-2 place-content-center place-items-center">
					<Htag tag="h2" type="semibold">
						Reset password
					</Htag>
					<P size="p2" type="regular" className="w-2/3 text-center">
						Enter your email address and we will send you a link to reset your password
					</P>
				</div>
				<div className="grid gap-6">
					<Input
						appearance="default"
						minWidth={window.innerWidth < 1280 ? false : true}
						type="text"
						name="email"
						withlabel={true}
						label="Email"
						placeholder="Enter your email"
						onChange={handleInputChange}
						value={formData.email}
					/>
					<div className="grid gap-4">
						<Button
							appearance="main"
							grid="grid grid-cols-1"
							width="full"
							className="font-semibold"
							onClick={handleSendResetLink}
						>
							Send link to email
						</Button>
					</div>
					<div className="grid gap-2 grid-cols-maxTwo place-items-center place-content-center">
						<P size="p3" type="regular" className="cursor-pointer">
							Already have an account?
						</P>
						<Link to={routeConstants.login.route}>
							<P
								size="p3"
								type="semibold"
								className="cursor-pointer !text-mainColor"
							>
								Log in
							</P>
						</Link>
					</div>
				</div>
				{resetError && (
					<div className="text-red-500">
						<P size="p2" type="semibold">
							{resetError}
						</P>
					</div>
				)}
			</div>
		</div>
	);
};

export default ResetPasswordPage;