import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./components/App/App";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import store from "components/App/store";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<React.Suspense fallback={<p>Loading...</p>}>
		<Provider store={store}>
			<App />
		</Provider>
	</React.Suspense>
);
