import { Htag } from "common/Htag/Htag";
import { Input } from "common/Input/Input";
import { P } from "common/P/P";
import React, { useState } from "react";
import { Button } from "common/Button/Button";
import { useSelector } from "react-redux";
import { RootState } from "components/App/store";
import { toast } from "react-toastify";
import { authService } from "services/authService";

interface Inputs {
	currentPassword: string;
	newPassword: string;
	confirmPassword: string;
}

const ManagePasswordTab: React.FC = () => {
	const auth = useSelector((state: RootState) => state.auth);
	const [inputs, setInputs] = useState<Inputs>({
		currentPassword: "",
		newPassword: "",
		confirmPassword: "",
	});

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setInputs((prevInputs) => ({
			...prevInputs,
			[name]: value,
		}));
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		if (inputs.newPassword !== inputs.confirmPassword) {
			toast.error("New password and confirm password do not match.");
			return;
		}

		if (inputs.newPassword.length < 8 || inputs.confirmPassword.length < 8) {
			toast.error("Password needs to be at least 8 characters.");
			return;
		}

		if (!auth.user?.Email) {
			toast.error("User email not found.");
			return;
		}

		const data = {
			email: auth.user.Email,
			oldPassword: inputs.currentPassword,
			newPassword: inputs.newPassword,
		};

		try {
			const response = await authService.resetPassword(
				data.email,
				data.oldPassword,
				data.newPassword
			);

			if (response.success) {
				toast.success("Your password has been updated successfully!");
			} else {
				toast.error(response.message);
			}
		} catch (error) {
			toast.error(
				"Failed to update password. Please check your inputs and try again."
			);
			console.error("Failed to submit form:", error);
		}
	};

	return (
		<div className="grid grid-flow-row grid-cols-1 gap-6 p-6 bg-white border auto-rows-max border-strokeColor rounded-xl">
			<div className="grid grid-flow-row grid-cols-1 pb-5 border-b auto-rows-max border-strokeColor">
				<Htag tag="h4" type="semibold">
					Password
				</Htag>
				<P size="p3" type="regular" className="text-textSecondaryColor">
					Please enter your current password to change your password.
				</P>
			</div>
			<div className="grid grid-flow-row grid-cols-1 gap-4 auto-rows-max">
				<Input
					appearance="default"
					type="password"
					name="currentPassword"
					placeholder="Enter your current password"
					label="Current password"
					withlabel
					value={inputs.currentPassword}
					onChange={handleInputChange}
				/>
				<Input
					appearance="default"
					type="password"
					name="newPassword"
					placeholder="Enter your new password"
					label="New password"
					withlabel
					value={inputs.newPassword}
					onChange={handleInputChange}
				/>
				<Input
					appearance="default"
					type="password"
					name="confirmPassword"
					placeholder="Enter your confirm password"
					label="Confirm Password"
					withlabel
					value={inputs.confirmPassword}
					onChange={handleInputChange}
				/>
			</div>
			<div className="grid gap-3 pt-4 border-t grid-cols-maxTwo place-content-end border-strokeColor">
				<Button appearance="border" paddingX="px-4">
					Cancel
				</Button>
				<Button
					appearance="main"
					paddingX="px-8"
					onClick={handleSubmit}
					className={
						inputs.newPassword.length < 8 || inputs.confirmPassword.length < 8
							? "cursor-not-allowed font-semibold"
							: "font-semibold"
					}
				>
					Update password
				</Button>
			</div>
		</div>
	);
};

export default ManagePasswordTab;
