import React from "react";
import { GenerateBoxItemProps } from "./GenerateBoxItem.props";
import { P } from "common/P/P";
import { Button } from "common/Button/Button";
import { ReactComponent as LockIcon } from "common/assets/icons/lock.svg";
import { useNavigate } from "react-router-dom";

const GenerateBoxItem: React.FC<GenerateBoxItemProps> = ({
	icon,
	image,
	title,
	description,
	route,
	active = true,
}) => {
	const navigate = useNavigate();

	return (
		<div className="grid grid-cols-1 grid-flow-row gap-4 px-6 py-4 bg-[#F9FAFB] relative">
			<div className="absolute top-0 right-0">
				<img src={image} alt="box_item_image" className="object-cover" />
			</div>
			<div className="p-2.5 rounded-xl bg-mainColor w-max">{icon}</div>
			<div className="z-10 grid grid-flow-row grid-cols-1 gap-2 auto-rows-max">
				<P size="p1" type="semibold">
					{title}
				</P>
				<P
					size="p3"
					type="regular"
					className="text-textSecondaryColor clamp-text-3"
				>
					{description}
				</P>
			</div>
			{active && route ? (
				<Button
					appearance="main"
					onClick={() => navigate(route)}
					width="full"
					rounded="rounded-none"
					paddingY="py-3"
				>
					Go to generate
				</Button>
			) : (
				<Button
					appearance="disabled"
					icon={<LockIcon />}
					width="full"
					rounded="rounded-none"
					paddingY="py-3"
				>
					Coming soon
				</Button>
			)}
		</div>
	);
};

export default GenerateBoxItem;
