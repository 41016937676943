import { configureStore } from "@reduxjs/toolkit";
import AppReducer from "../../redux/app/appSlice";
import authReducer from "../../redux/auth/authSlice";
import cartReducer from "../../redux/cart/cartSlice";
import shippingReducer from "../../redux/shipping/shippingSlice";

const store = configureStore({
	reducer: {
		app: AppReducer,
		auth: authReducer,
		cart: cartReducer,
		shippingInformation: shippingReducer,
	},
});

export type RootState = ReturnType<typeof store.getState>;

// Add this line to define a custom dispatch type that understands thunks
export type AppDispatch = typeof store.dispatch;

export default store;
