import React, { useState } from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import { Input } from "common/Input/Input";
import { Button } from "common/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import { routeConstants } from "navigation";
import { useDispatch } from "react-redux";
import { auth } from "redux/auth/authAsyncThunks";
import { AppDispatch } from "components/App/store";
import { ReactComponent as Logo } from "common/assets/icons/just_logo.svg";
import { ReactComponent as GoogleIcon } from "common/assets/icons/google.svg";
import { ReactComponent as AppleIcon } from "common/assets/icons/apple.svg";
import { toast } from "react-toastify";

const LoginPage: React.FC = (): JSX.Element => {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();

	const [formData, setFormData] = useState({ email: "", password: "" });

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const handleLogin = async () => {
		if (formData.password.length < 8) {
			toast.error("Password must be at least 8 characters long.");
			return;
		}
		try {
			await dispatch(auth(formData)).unwrap();
			toast.success("Login successful.");
			navigate(routeConstants.root.route);
		} catch (error: any) {
			toast.error(error.message || "Failed to login.");
		}
	};

	return (
		<div className="grid grid-cols-1 minPhone:px-5 minPhone:pt-14 minPhone:pb-6 lg:px-32 lg:pb-20 lg:pt-24">
			<div className="grid grid-flow-row minPhone:gap-6 xl:gap-8 xl:place-content-center minPhone:place-items-stretch xl:place-items-center auto-rows-max minPhone:pt-28 minPhone:pb-8 xl:pt-0 xl:pb-0 minPhone:px-4 xl:px-0 z-[99]">
				<Logo className="w-16 h-16" />
				<div className="grid gap-4 place-content-center place-items-center">
					<Htag tag="h2" type="semibold">
						Log in to your account
					</Htag>
					<P size="p2" type="regular" className="text-[#475467]">
						Welcome back! Please enter your details.
					</P>
				</div>
				<div className="grid gap-6">
					<Input
						appearance="default"
						minWidth={window.innerWidth < 1280 ? false : true}
						type="text"
						name="email"
						withlabel={true}
						label="Email"
						placeholder="Enter your email"
						onChange={handleInputChange}
						value={formData.email}
					/>
					<div className="grid gap-4">
						<Input
							appearance="default"
							minWidth={window.innerWidth < 1280 ? false : true}
							type="password"
							name="password"
							withlabel={true}
							label="Password"
							placeholder="******"
							onChange={handleInputChange}
							value={formData.password}
						/>
						<div className="grid grid-cols-maxTwo place-content-center place-items-center">
							<Link to={routeConstants.resetPassword.route}>
								<P
									size="p3"
									type="semibold"
									className="cursor-pointer text-end !text-mainColor"
								>
									Forgot password
								</P>
							</Link>
						</div>
					</div>
					<div className="grid gap-4">
						<Button
							appearance="main"
							grid="grid grid-cols-1"
							width="full"
							disabled={formData.password.length < 8}
							className={
								formData.password.length < 8
									? "cursor-not-allowed font-semibold"
									: "font-semibold"
							}
							onClick={handleLogin}
						>
							Sign in
						</Button>
						{/* <Button
							appearance="border"
							width="full"
							icon={<GoogleIcon className="w-5 h-5" />}
							grid="grid grid-cols-maxTwo gap-3"
							className="font-semibold"
						>
							Sign in with Google
						</Button>
						<Button
							appearance="border"
							width="full"
							icon={<AppleIcon className="w-5 h-5" />}
							grid="grid grid-cols-maxTwo gap-3"
							className="font-semibold"
						>
							Sign in with Apple
						</Button> */}
					</div>
					<div className="grid gap-2 grid-cols-maxTwo place-items-center place-content-center">
						<P size="p3" type="regular" className="cursor-pointer">
							Don’t have an account?
						</P>
						<P
							size="p3"
							type="semibold"
							className="cursor-pointer !text-mainColor"
							onClick={() => navigate(routeConstants.register.route)}
						>
							Sign up
						</P>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LoginPage;
