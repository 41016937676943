import React from "react";
import "./EllipticalImages.css";

import centralImage from "common/assets/images/main_nail.png";
import nail1 from "common/assets/images/nail_1.png";
import nail2 from "common/assets/images/nail_2.png";
import nail3 from "common/assets/images/nail_3.png";
import nail4 from "common/assets/images/nail_4.png";
import nail5 from "common/assets/images/nail_5.png";
import nail6 from "common/assets/images/nail_6.png";
import nail7 from "common/assets/images/nail_7.png";

const EllipticalImages: React.FC = (): JSX.Element => {
	return (
		<div className="relative">
			<div className="gradient-wrapper">
				<div className="ellipticalImages-bg"></div>
			</div>
			<div className="relative h-full mx-auto my-12 w-max lg:min-w-[422px] z-20">
				<div className="flex items-center justify-center rounded-full minPhone:p-4 lg:p-11 bg-rgba-40">
					<div className="flex items-center justify-center rounded-full minPhone:p-4 lg:p-11 bg-rgba-60">
						<img
							src={centralImage}
							alt="Central"
							className="minPhone:w-[213px] minPhone:h-[213px] lg:w-[233px] lg:h-[233px] rounded-full"
						/>
					</div>
				</div>
				<div className="absolute lg:top-0 minPhone:top-[-5%] left-[-12%]">
					<img
						src={nail1}
						alt="Nail 1"
						className="minPhone:w-[104px] minPhone:h-[104px] lg:w-[124px] lg:h-[124px] rounded-full"
					/>
				</div>
				<div className="absolute left-[50%] minPhone:top-[-2%] lg:top-[5%]">
					<img
						src={nail2}
						alt="Nail 2"
						className="minPhone:w-[62px] minPhone:h-[62px] lg:w-[82px] lg:h-[81px] rounded-full"
					/>
				</div>
				<div className="absolute minPhone:top-[7%] lg:top-[20%] left-[75%]">
					<img
						src={nail3}
						alt="Nail 3"
						className="minPhone:w-[76px] minPhone:h-[76px] lg:w-[96px] lg:h-[96px] rounded-full"
					/>
				</div>
				<div className="absolute minPhone:top-[20%] lg:top-[30%] left-[-10%]">
					<img
						src={nail4}
						alt="Nail 4"
						className="minPhone:w-[55px] minPhone:h-[55px] lg:w-[75px] lg:h-[75px] rounded-full"
					/>
				</div>
				<div className="absolute top-[48%] left-[-5%]">
					<img
						src={nail5}
						alt="Nail 5"
						className="minPhone:w-[106px] minPhone:h-[106px] lg:w-[136px] lg:h-[136px] rounded-full"
					/>
				</div>
				<div className="absolute top-[55%] left-[40%]">
					<img
						src={nail6}
						alt="Nail 6"
						className="minPhone:w-[54px] minPhone:h-[54px] lg:w-[74px] lg:h-[74px] rounded-full"
					/>
				</div>
				<div className="absolute minPhone:top-[45%] lg:top-[50%] left-[65%]">
					<img
						src={nail7}
						alt="Nail 7"
						className="minPhone:w-[126px] minPhone:h-[126px] lg:w-[156px] lg:h-[156px] rounded-full"
					/>
				</div>
				<p className="text-base font-semibold cursor-default text-mainColor minPhone:mt-[50px] lg:mt-[130px]">
					{" "}
				</p>
			</div>
		</div>
	);
};

export default EllipticalImages;
