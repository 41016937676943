import React, {
	useState,
	useEffect,
	useRef,
	ChangeEvent,
	KeyboardEvent,
} from "react";

interface OTPInputProps {
	length: number;
	onChange: (otp: string) => void;
}

const OTPInput: React.FC<OTPInputProps> = ({ length, onChange }) => {
	const [otp, setOtp] = useState<string[]>(Array(length).fill(""));
	const inputsRef = useRef<HTMLInputElement[]>([]);

	useEffect(() => {
		onChange(otp.join(""));
	}, [otp, onChange]);

	const handleInputChange = (
		e: ChangeEvent<HTMLInputElement>,
		index: number
	) => {
		const { value } = e.target;
		// Ensure only numbers are allowed
		if (!value || /^\d$/.test(value)) {
			const newOtp = [...otp];
			newOtp[index] = value.slice(0, 1);
			setOtp(newOtp);

			// Auto-focus to next input
			if (value && index < length - 1) {
				inputsRef.current[index + 1].focus();
			}
		}
	};

	const handleBackspace = (
		e: KeyboardEvent<HTMLInputElement>,
		index: number
	) => {
		if (e.key === "Backspace" && !otp[index] && index > 0) {
			const newOtp = [...otp];
			newOtp[index - 1] = "";
			setOtp(newOtp);
			inputsRef.current[index - 1].focus();
		}
	};

	const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
		e.preventDefault();
		const pastedData = e.clipboardData
			.getData("text")
			.slice(0, length)
			.replace(/[^0-9]/g, ""); // Strip out non-numeric characters
		const newOtp = Array(length).fill("");
		for (let i = 0; i < pastedData.length; i++) {
			newOtp[i] = pastedData[i];
		}
		setOtp(newOtp);
		const nextFocusIndex =
			pastedData.length < length ? pastedData.length : length - 1;
		inputsRef.current[nextFocusIndex].focus();
	};

	const handleFocus = (index: number) => {
		inputsRef.current[index].select();
	};

	return (
		<div className="inline-flex items-start justify-start h-20 gap-3 w-96">
			{Array.from({ length }).map((_, index) => (
				<div
					key={index}
					className={`w-20 p-2 bg-white rounded-lg shadow-sm border ${
						otp[index] ? "border-violet-500" : "border-gray-300"
					}`}
				>
					<input
						ref={(el) => (inputsRef.current[index] = el!)}
						type="tel"
						pattern="\d*"
						maxLength={1}
						className="w-full h-full text-center text-mainColor text-5xl font-medium font-['Inter'] leading-10 bg-transparent outline-none"
						value={otp[index] || ""}
						onChange={(e) => handleInputChange(e, index)}
						onKeyDown={(e) => handleBackspace(e, index)}
						onFocus={() => handleFocus(index)}
						onPaste={handlePaste}
						placeholder="#"
					/>
				</div>
			))}
		</div>
	);
};

export default OTPInput;
