import React from "react";
import image from "common/assets/images/section_image.png";

const CreativeProjectsShowcase: React.FC = () => {
	return (
		<div className="px-[170px] TwoXl:px-[240px] ThreeXl:px-[300px] py-8 flex-col place-content-center place-items-center gap-2.5 inline-flex">
			<div className="w-full p-4 bg-white bg-opacity-40 rounded-3xl flex-col place-content-center place-items-center gap-2.5 inline-flex">
				<img
					className="self-stretch object-cover w-full h-full grow shrink basis-0 rounded-3xl"
					src={image}
					alt="Creative Project"
				/>
			</div>
		</div>
	);
};

export default CreativeProjectsShowcase;
