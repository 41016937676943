import { Routes, Route } from "react-router-dom";

import { routeConstants } from "navigation";
import LoginPage from "pages/auth/LoginPage/LoginPage";
import RegisterPage from "pages/auth/RegisterPage/RegisterPage";
import VerificationPage from "pages/auth/VerificationPage/VerificationPage";
import ResetPasswordPage from "pages/auth/ResetPasswordPage/ResetPasswordPage";
import NewPasswordPage from "pages/auth/NewPasswordPage/NewPasswordPage";
import NotFoundPage from "pages/system/NotFoundPage/NotFoundPage";
import ShopPage from "pages/ShopPage/ShopPage";
import CartPage from "pages/CartPage/CartPage";
import ShippingAddressPage from "pages/ShippingAddressPage/ShippingAddressPage";
import PaymentPage from "pages/PaymentPage/PaymentPage";
import ProductDetailPage from "pages/ProductDetailPage/ProductDetailPage";
import SuccessOrderPage from "pages/SuccessOrderPage/SuccessOrderPage";
import AccountPage from "pages/AccountPage/AccountPage";
import OrderDetailsPage from "pages/OrderDetailsPage/OrderDetailsPage";
import PlaygrounMainPage from "pages/PlaygroundMainPage/PlaygroundMainPage";
import GeneratePage from "pages/GeneratePage/GeneratePage";
import SharedGeneratedCollectionPage from "pages/SharedGeneratedCollectionPage/SharedGeneratedCollectionPage";
import InspirationPage from "pages/InspirationPage/InspirationPage";
import FavouritePage from "pages/FavouritePage/FavouritePage";

export const PlaygroundRoutes: React.FC = () => {
	return (
		<Routes>
			<Route path={routeConstants.root.route} element={<PlaygrounMainPage />} />
			<Route path={routeConstants.shops.route} element={<ShopPage />} />
			<Route path={routeConstants.cart.route} element={<CartPage />} />
			<Route
				path={routeConstants.shippingAddress.route}
				element={<ShippingAddressPage />}
			/>
			<Route path={routeConstants.payment.route} element={<PaymentPage />} />
			<Route path={routeConstants.generate.route} element={<GeneratePage />} />
			<Route
				path={`${routeConstants.productDetails.route}/:id`}
				element={<ProductDetailPage />}
			/>
			<Route
				path={`${routeConstants.shared.route}/:userGeneratedImageId`}
				element={<SharedGeneratedCollectionPage />}
			/>
			<Route
				path={`${routeConstants.inspiration.route}`}
				element={<InspirationPage />}
			/>
			<Route
				path={`${routeConstants.favourite.route}`}
				element={<FavouritePage />}
			/>
			<Route
				path={routeConstants.successOrder.route}
				element={<SuccessOrderPage />}
			/>
			<Route path={routeConstants.account.route} element={<AccountPage />} />
			<Route
				path={`${routeConstants.orderDetails.route}/:id`}
				element={<OrderDetailsPage />}
			/>

			<Route path={routeConstants.login.route} element={<LoginPage />} />
			<Route path={routeConstants.register.route} element={<RegisterPage />} />
			<Route
				path={routeConstants.verificationOtp.route}
				element={<VerificationPage />}
			/>
			<Route
				path={routeConstants.resetPassword.route}
				element={<ResetPasswordPage />}
			/>
			<Route
				path={routeConstants.newPassword.route}
				element={<NewPasswordPage />}
			/>

			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	);
};
