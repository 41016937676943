import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";

import { ReactComponent as LeftArrowIcon } from "common/assets/icons/arrow-left.svg";
import { ReactComponent as CancelIcon } from "common/assets/icons/slash-circle-01.svg";
import { routeConstants } from "navigation";
import Breadcrumbs from "common/Breadcrumbs/Breadcrumbs";
import OrderStatus from "common/OrderStatus/OrderStatus";
import { Button } from "common/Button/Button";
import OrderHistoryItem from "common/OrderHistoryItem/OrderHistoryItem";
import { RootState } from "components/App/store";
import { useSelector } from "react-redux";
import { serviceConfig } from "configs";
import dateFormatter from "utils/dateFormatter";
import timeFormatter from "utils/timeFormatter";
import { toast } from "react-toastify";

const OrderDetailsPage: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const [orderDetails, setOrderDetails] = useState<any>(null);
	const app = useSelector((state: RootState) => state.app);
	const auth = useSelector((state: RootState) => state.auth);
	const navigate = useNavigate();

	const breadcrumbItems = [
		{ label: "Home", path: routeConstants.root.route },
		{ label: "Settings", path: routeConstants.account.route },
		{ label: "Order history", path: "noRoute" },
	];

	useEffect(() => {
		const fetchOrderDetails = async () => {
			try {
				const response = await axios.get(
					`${serviceConfig.apiUrl}/orders/${auth.user?._id}/${id}`
				);
				if (response.data.success) {
					setOrderDetails(response.data.data);
				} else {
					console.error(
						"Failed to fetch order details:",
						response.data.message
					);
				}
			} catch (error) {
				console.error("Error fetching order details:", error);
			}
		};

		if (auth.isLoggedIn && id) {
			fetchOrderDetails();
		}

		
		window.scrollTo(0, 0);
	}, [id, auth.isLoggedIn, auth.user?._id]);

	const handleCancelOrder = async () => {
		try {
			const response = await axios.put(
				`${serviceConfig.apiUrl}/orders/${auth.user?._id}/${id}`,
				{ orderStatus: "Canceled" } 
			);
			if (response.data.success) {
				setOrderDetails(response.data.data); 
				toast.success("Order canceled successfully!");
			} else {
				toast.error("Failed to cancel the order.");
			}
		} catch (error) {
			console.error("Error canceling order:", error);
			toast.error("An error occurred while canceling the order.");
		}
	};

	if (!orderDetails) {
		return <div>Loading...</div>;
	}

	return (
		<div
			className={`${
				app.isPlayground
					? "px-[80px] TwoXl:px-[90px] ThreeXl:px-[110px]"
					: "px-[170px] TwoXl:px-[240px] ThreeXl:px-[300px]"
			} pb-12 grid grid-cols-1 grid-flow-row auto-rows-max`}
		>
			<div
				className="grid gap-6 py-4 cursor-pointer grid-cols-maxTwo place-items-center"
				onClick={() => navigate(routeConstants.orders.route)}
			>
				<div className="p-2.5 border rounded-lg shadow-sm border-strokeColor">
					<LeftArrowIcon className="stroke-textColor" />
				</div>
				<Htag tag="h3" type="semibold">
					Order history
				</Htag>
			</div>
			<div className="grid grid-flow-row grid-cols-1 gap-8 auto-rows-max">
				<div className="py-4">
					<Breadcrumbs items={breadcrumbItems} whiteMode={false} />
				</div>
				<div className="grid grid-flow-row grid-cols-1 gap-6 p-6 bg-white border rounded-xl border-strokeColor auto-rows-max">
					<div
						className={`grid pb-8 border-b border-strokeColor ${
							orderDetails.orderStatus === "Waiting"
								? "grid-cols-maxTwo place-content-between place-items-center"
								: "grid-cols-1"
						}`}
					>
						<OrderStatus status={orderDetails.orderStatus} />
						{orderDetails.orderStatus === "Waiting" && (
							<Button
								appearance="red"
								icon={<CancelIcon />}
								paddingX="px-4"
								onClick={handleCancelOrder}
							>
								Cancel order
							</Button>
						)}
					</div>
					<div className="grid grid-flow-row grid-cols-1 gap-3 px-6 pt-3 pb-6 border auto-rows-max border-strokeColor rounded-xl">
						<div className="grid grid-cols-[70%_1fr_max-content] gap-6 pb-3 border-b border-strokeColor">
							<Htag tag="h4" type="semibold" className="p-2.5">
								Products
							</Htag>
							<P size="p2" type="medium" className="py-2.5 pr-2.5">
								Price
							</P>
							<P size="p2" type="medium" className="py-2.5 pr-2.5">
								Quantity
							</P>
						</div>
						<div className="grid grid-flow-row grid-cols-1 auto-rows-max">
							{orderDetails.cart.items.map((item: any, index: number) => (
								<OrderHistoryItem
									key={index}
									orderDetails
									image={item.selectedShape?.imageUrl}
									name={item.product.name}
									color={item.selectedShape?.name}
									size={item.selectedSize?.name}
									price={item.originalPrice}
									discountPrice={item.discountPrice}
									discountPercentage={item.discountPercentage}
									quantity={item.quantity}
								/>
							))}
						</div>
					</div>
					<div className="grid grid-cols-2 gap-6">
						<div className="grid grid-flow-row grid-cols-1 p-6 border auto-rows-max border-strokeColor rounded-xl">
							<Htag tag="h4" type="semibold">
								Order price
							</Htag>
							<div className="grid py-4 border-b border-dashed grid-cols-maxTwo place-content-between place-items-center border-strokeColor">
								<P size="p1" type="regular" className="text-textSecondaryColor">
									Subtotal
								</P>
								<P size="p1" type="semibold">
									${orderDetails.cart.totalAmount.toFixed(2)}
								</P>
							</div>
							<div className="grid grid-flow-row grid-cols-1 gap-6 py-4 border-b border-dashed auto-rows-max border-strokeColor">
								<div className="grid grid-cols-maxTwo place-content-between place-items-center">
									<P
										size="p1"
										type="regular"
										className="text-textSecondaryColor"
									>
										Discount
									</P>
									<P size="p1" type="semibold">
										-${orderDetails.cart.totalDiscount.toFixed(2)}
									</P>
								</div>
								<div className="grid grid-cols-maxTwo place-content-between place-items-center">
									<P
										size="p1"
										type="regular"
										className="text-textSecondaryColor"
									>
										Shipment cost
									</P>
									<P size="p1" type="semibold">
										${orderDetails.shippingInformation.shipmentCost.toFixed(2)}
									</P>
								</div>
							</div>
							<div className="grid py-4 grid-cols-maxTwo place-content-between place-items-center">
								<P size="p1" type="medium" className="text-textSecondaryColor">
									Grand total
								</P>
								<Htag tag="h4" type="semibold">
									$
									{(
										orderDetails.cart.totalAmount -
										orderDetails.cart.totalDiscount +
										orderDetails.shippingInformation.shipmentCost
									).toFixed(2)}
								</Htag>
							</div>
						</div>
						<div className="grid grid-flow-row grid-cols-1 p-6 border auto-rows-max border-strokeColor rounded-xl h-max">
							<Htag tag="h4" type="semibold">
								Order details
							</Htag>
							<div className="grid py-4 border-b border-dashed grid-cols-maxTwo place-content-between place-items-center border-strokeColor">
								<P size="p1" type="regular" className="text-textSecondaryColor">
									Order code
								</P>
								<P size="p1" type="medium" className="text-textSecondaryColor">
									#{orderDetails._id}
								</P>
							</div>
							<div className="grid py-4 border-b border-dashed grid-cols-maxTwo place-content-between place-items-center border-strokeColor">
								<P size="p1" type="regular" className="text-textSecondaryColor">
									Ordered time
								</P>
								<div className="grid grid-flow-col auto-cols-max gap-1.5 place-items-center">
									<P
										size="p1"
										type="medium"
										className="text-textSecondaryColor"
									>
										{dateFormatter(orderDetails.createdAt)}
									</P>
									<div className="w-1 h-1 bg-gray-500 rounded-full" />
									<P
										size="p1"
										type="medium"
										className="text-textSecondaryColor"
									>
										{timeFormatter(new Date(orderDetails.createdAt))}
									</P>
								</div>
							</div>
							{/* <div className="grid py-4 grid-cols-maxTwo place-content-between place-items-center">
								<P size="p1" type="regular" className="text-textSecondaryColor">
									Shipped time
								</P>
								<P size="p1" type="medium" className="text-textSecondaryColor">
									{orderDetails.shippedAt
										? dateFormatter(orderDetails.shippedAt)
										: "Not shipped yet"}
								</P>
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OrderDetailsPage;
