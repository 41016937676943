import { useSelector } from "react-redux";
import CartRow from "./CartRow";
import { Htag } from "common/Htag/Htag";
import { ReactComponent as EmptyBagIcon } from "common/assets/icons/no_bag.svg";
import { RootState } from "components/App/store";

const CartBody = ({
	checkoutPage,
	orderPage,
	orderProducts,
	className,
}: any) => {
	const cart = useSelector((state: RootState) => state.cart);

	return (
		<div
			className={`grid grid-cols-1 h-full ${
				!checkoutPage && !orderPage && "bg-white"
			} `}
		>
			<div className={`grid ${className}`}>
				{cart.items.length === 0 ? (
					<div className="flex flex-col items-center justify-center py-6">
						<EmptyBagIcon className="w-20 h-20 mb-4" />
						<Htag tag="h4" type="medium">
							Your cart is empty
						</Htag>
					</div>
				) : (
					cart.items.map((item: any) => (
						<CartRow
							key={`${item.product._id}-${item.selectedShape.id}-${item.selectedSize.id}`}
							imgSrc={item.product.images[0].savedImgPath}
							name={item.product.name}
							size={item.selectedSize.name}
							sizeId={item.selectedSize.id}
							shapeId={item.selectedShape.id}
							productId={item.product._id}
							color={item.selectedShape.name}
							price={item.unitPrice}
							quantity={item.quantity}
							checkoutPage={checkoutPage}
						/>
					))
				)}
			</div>
		</div>
	);
};

export default CartBody;
