import React, { useEffect, useState } from "react";
import { FilterItemProps } from "./FilterItem.props";
import { P } from "common/P/P";
import { Checkbox } from "common/Checkbox/Checkbox";
import { ReactComponent as ArrowIcon } from "common/assets/icons/chevron-down.svg";

const FilterItem: React.FC<FilterItemProps> = ({
	title,
	filterName,
	filters,
	onChange,
	initialState = true,
}) => {
	const [isOpen, setIsOpen] = useState(initialState);
	const [selectedValues, setSelectedValues] = useState<string[]>([]);

	useEffect(() => {
		if (initialState) {
			const queryParams = new URLSearchParams(window.location.search);
			const initialValues = queryParams.getAll(filterName);
			setSelectedValues(initialValues);
		}
	}, [filterName, initialState]);

	const toggleOpen = () => setIsOpen(!isOpen);

	const handleChange = (value: string) => {
		const newSelectedValues = selectedValues.includes(value)
			? selectedValues.filter((v) => v !== value)
			: [...selectedValues, value];
		setSelectedValues(newSelectedValues);
		onChange(filterName, value);
	};

	return (
		<div className="inline-flex flex-col justify-start gap-4 px-3 pt-3 pb-5 bg-white border border-gray-200 rounded-xl">
			<button
				className="flex items-center self-stretch justify-between py-3 pl-4 pr-3 bg-gray-100 rounded-lg"
				onClick={toggleOpen}
			>
				<P size="p1" type="semibold">
					{title}
				</P>
				<ArrowIcon
					className={`w-6 h-6 cursor-pointer ${
						isOpen ? "-rotate-180" : "rotate-0"
					}`}
				/>
			</button>
			{isOpen && (
				<div className="grid justify-start grid-flow-row grid-cols-1 gap-4 px-4 auto-rows-max">
					{filters.map((filter, index) => (
						<div
							key={index}
							className="grid gap-3 grid-cols-maxTwo place-items-center"
						>
							<Checkbox
								appearance="default"
								checked={selectedValues.includes(filter.value)}
								onChange={() => handleChange(filter.value)}
							/>
							<P size="p2" type="medium">
								{filter.label}
							</P>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default FilterItem;
