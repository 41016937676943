import React from "react";

import GeneratedHistoryCollection from "components/GeneratedHistoryCollection/GeneratedHistoryCollection";
import GenerateCollection from "components/GenerateCollection/GenerateCollection";
import InspirationCollection from "components/InspirationCollection/InspirationCollection";

const PlaygrounMainPage: React.FC = (): JSX.Element => {
	return (
		<>
			<div className="grid grid-cols-1 px-20">
				<GenerateCollection />
				<InspirationCollection />
				<GeneratedHistoryCollection />
				
			</div>
		</>
	);
};

export default PlaygrounMainPage;
