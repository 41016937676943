import React, { useState } from "react";
import { SearchInputProps } from "./Search.props";
import "./Search.css";
import { ReactComponent as SearchIcon } from "common/assets/icons/search.svg";

export const SearchInput: React.FC<SearchInputProps> = ({
	placeholder = "Search...",
	onSearch,
	className,
}) => {
	const [searchQuery, setSearchQuery] = useState<string>("");

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			onSearch(searchQuery);
		}
	};

	return (
		<div className={`relative bg-white shadow-xs ${className}`}>
			<span className="absolute inset-y-0 left-0 grid pl-4 place-items-center">
				<SearchIcon className="stroke-buttonColor" />
			</span>
			<input
				type="text"
				value={searchQuery}
				onChange={(e) => setSearchQuery(e.target.value)}
				onKeyDown={handleKeyPress}
				className="input pl-12 w-full h-full py-2.5 pr-4 lg:w-[368px] border placeholder-buttonColor rounded-lg border-strokeColor transition-all duration-300 text-passiveTextColor"
				placeholder={placeholder}
			/>
		</div>
	);
};
