import React, { useRef, useState } from "react";
import Slider from "react-slick";
import { ReactComponent as ArrowLeftIcon } from "common/assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRightIcon } from "common/assets/icons/arrow-right.svg";
import "./Carousel.css";
import { IPropsCarousel } from "./Carousel.props";

const defaultResponsiveSettings = [
	{
		breakpoint: 1850, // For screens greater than 1850px
		settings: {
			slidesToShow: 5,
			slidesToScroll: 1,
		},
	},
	{
		breakpoint: 1440, // For screens up to 1440px
		settings: {
			slidesToShow: 4,
			slidesToScroll: 1,
		},
	},
	{
		breakpoint: 1024, // For tablets and below
		settings: {
			slidesToShow: 3,
			slidesToScroll: 1,
		},
	},
	{
		breakpoint: 768, // For smaller tablets and large phones
		settings: {
			slidesToShow: 2,
			slidesToScroll: 1,
		},
	},
	{
		breakpoint: 480, // For smaller phones
		settings: {
			slidesToShow: 1,
			slidesToScroll: 1,
		},
	},
];

const Carousel: React.FC<IPropsCarousel> = ({
	children,
	slidesToShow = 2,
	responsive = defaultResponsiveSettings,
}) => {
	const sliderRef = useRef<Slider | null>(null);

	const [currentSlide, setCurrentSlide] = useState(0);
	const totalSlides = children.length;

	const totalDots = Math.ceil(totalSlides / slidesToShow);

	const activeDotIndex = Math.floor(currentSlide / slidesToShow);

	const settings = {
		infinite: true,
		speed: 500,
		slidesToShow: slidesToShow,
		slidesToScroll: 1,
		autoplay: false,
		swipeToSlide: true,
		afterChange: setCurrentSlide,
		responsive: responsive,
	};

	return (
		<div className="grid grid-cols-1 gap-6 grid-rows-maxTwo">
			<Slider ref={sliderRef} {...settings}>
				{children.map((child, index) => (
					<div key={index}>{child}</div>
				))}
			</Slider>
			<div className="grid gap-8 grid-cols-maxThree place-content-center place-items-center">
				<div
					className="p-4 transition duration-300 bg-white border border-gray-200 rounded-full cursor-pointer hover:bg-mainColor hover:border-mainColor group"
					onClick={() => sliderRef.current?.slickPrev()}
				>
					<ArrowLeftIcon className="group-hover:stroke-white" />
				</div>

				<ul className="flex gap-4">
					{Array.from({ length: totalDots }).map((_, idx) => (
						<li
							key={idx}
							className={`w-2.5 h-2.5 rounded-full ${
								idx === activeDotIndex ? "bg-mainColor" : "bg-gray-300"
							} cursor-pointer`}
							onClick={() => sliderRef.current?.slickGoTo(idx * slidesToShow)}
						></li>
					))}
				</ul>

				<div
					className="p-4 transition duration-300 bg-white border border-gray-200 rounded-full cursor-pointer hover:bg-mainColor hover:border-mainColor group"
					onClick={() => sliderRef.current?.slickNext()}
				>
					<ArrowRightIcon className="group-hover:stroke-white" />
				</div>
			</div>
		</div>
	);
};

export default Carousel;
