import React from "react";
import "./AINailArtShowcase.css";
import { Button } from "common/Button/Button";

import { ReactComponent as StarIcon } from "common/assets/icons/sparkles-soft.svg";
import { P } from "common/P/P";
import { Htag } from "common/Htag/Htag";

interface AINailArtShowcaseProps {}

const AINailArtShowcase: React.FC<AINailArtShowcaseProps> = () => {
	return (
		<div className="px-[170px] TwoXl:px-[240px] ThreeXl:px-[300px] py-8 flex-col place-content-center place-items-center gap-2.5 inline-flex z-10">
			<div className="grid items-center justify-start grid-cols-1 gap-4 py-8">
				<P size="p2" type="semibold" className="text-center text-violet-700">
					First AI powered Nail Artist
				</P>

				<div className="flex flex-col items-center justify-start gap-8">
					<div className="flex flex-col items-center justify-start gap-4">
						<div className="flex flex-col items-center self-stretch justify-start gap-3">
							<Htag tag="h1" type="bold" className="text-center">
								Naildesign AI
							</Htag>
							<Htag
								tag="h1"
								type="bold"
								className="text-center"
								style={{
									backgroundImage:
										"linear-gradient(to left, rgba(230, 80, 243, 1) 10%, rgba(112, 40, 228, 1) 100%)",
									WebkitBackgroundClip: "text",
									backgroundClip: "text",
									color: "transparent",
									WebkitTextFillColor: "transparent",
								}}
							>
								Your AI-Powered Personal Nail Artist
							</Htag>
						</div>
						<P size="p2" type="medium" className="text-center ">
							Unleash Your Nail Art Dreams with AI Precision
						</P>
					</div>

					<Button
						appearance="main"
						icon={<StarIcon />}
						paddingX="px-8"
						paddingY="py-[10px]"
						className="!text-base"
					>
						Design Your Nails Now
					</Button>
				</div>
			</div>
		</div>
	);
};

export default AINailArtShowcase;
