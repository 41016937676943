import React, { useState } from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import { Input } from "common/Input/Input";
import { Button } from "common/Button/Button";
import { ReactComponent as UserIcon } from "common/assets/icons/user-circle.svg";
import { ReactComponent as GoogleIcon } from "common/assets/icons/google.svg";
import { ReactComponent as AppleIcon } from "common/assets/icons/apple.svg";
import { Link, useNavigate } from "react-router-dom";
import { routeConstants } from "navigation";
import { authService } from "services/authService";
import { Checkbox } from "common/Checkbox/Checkbox";
import { toast } from "react-toastify";

const RegisterPage: React.FC = (): JSX.Element => {
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		password: "",
		confirmPassword: "",
	});
	const [registerError, setRegisterError] = useState<string | null>(null);
	const [isTermsAccepted, setIsTermsAccepted] = useState(false);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const validateEmail = (email: string): boolean => {
		const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
		return pattern.test(email);
	};

	const handleRegister = async () => {
		if (!validateEmail(formData.email)) {
			toast.error("Invalid email format.");
			setRegisterError("Invalid email format.");
			return;
		}

		if (formData.password !== formData.confirmPassword) {
			toast.error("Passwords do not match.");
			setRegisterError("Passwords do not match.");
			return;
		}

		if (!isTermsAccepted) {
			toast.error("You must accept the terms and conditions.");
			return;
		}

		try {
			await authService.registerInit(
				formData.email,
				formData.password,
				formData.firstName,
				formData.lastName
			);
			toast.success(
				"Registration successful. Please check your email for OTP."
			);
			navigate(routeConstants.verificationOtp.route, {
				state: {
					email: formData.email,
					firstName: formData.firstName,
					lastName: formData.lastName,
					origin: "register",
				},
			});
		} catch (error: any) {
			const errorMessage =
				error.response?.data?.message || "Failed to register.";
			toast.error(errorMessage);
			setRegisterError(errorMessage);
		}
	};

	return (
		<div className="grid grid-cols-1 minPhone:px-5 minPhone:pt-14 minPhone:pb-6 lg:px-32 lg:pb-20 lg:pt-24">
			<div className="grid grid-flow-row minPhone:gap-6 xl:gap-8 xl:place-content-center minPhone:place-items-stretch xl:place-items-center auto-rows-max minPhone:pt-28 minPhone:pb-8 xl:pt-0 xl:pb-0 minPhone:px-4 xl:px-0 z-[99]">
				<div className="p-3 bg-[#F4EBFF] rounded-full">
					<UserIcon />
				</div>
				<div className="grid gap-2 place-content-center place-items-center">
					<Htag tag="h2" type="semibold">
						Create an account
					</Htag>
					<P size="p2" type="regular">
						Start your 7-day free trial.
					</P>
				</div>
				<div className="grid gap-6">
					<Input
						appearance="default"
						minWidth={window.innerWidth < 1280 ? false : true}
						type="text"
						name="email"
						withlabel={true}
						label="Email"
						placeholder="Enter your email"
						onChange={handleInputChange}
						value={formData.email}
					/>
					<Input
						appearance="default"
						minWidth={window.innerWidth < 1280 ? false : true}
						type="text"
						withlabel={true}
						label="First name"
						name="firstName"
						placeholder="John"
						onChange={handleInputChange}
						value={formData.firstName}
					/>
					<Input
						appearance="default"
						minWidth={window.innerWidth < 1280 ? false : true}
						type="text"
						withlabel={true}
						label="Last name"
						name="lastName"
						placeholder="Doe"
						onChange={handleInputChange}
						value={formData.lastName}
					/>

					<Input
						appearance="default"
						minWidth={window.innerWidth < 1280 ? false : true}
						type="password"
						withlabel={true}
						label="Password*"
						name="password"
						placeholder="Choose a password"
						onChange={handleInputChange}
						value={formData.password}
					/>

					<Input
						appearance="default"
						minWidth={window.innerWidth < 1280 ? false : true}
						type="password"
						withlabel={true}
						label="Confirm password"
						name="confirmPassword"
						placeholder="Confirm password"
						onChange={handleInputChange}
						value={formData.confirmPassword}
					/>
					<div className="grid gap-2 grid-cols-maxTwo place-content-center place-items-center">
						<div className="grid gap-2 grid-cols-maxTwo place-items-center">
							<Checkbox
								appearance="default"
								onChange={() => setIsTermsAccepted(!isTermsAccepted)}
							/>
							<P size="p3" type="regular" className="cursor-pointer">
								I accept
							</P>
						</div>
						<Link to={routeConstants.resetPassword.route}>
							<P
								size="p3"
								type="semibold"
								className="cursor-pointer text-end !text-mainColor"
							>
								Terms & Conditions
							</P>
						</Link>
					</div>
					<div className="grid gap-4">
						<Button
							appearance="main"
							grid="grid grid-cols-1"
							width="full"
							disabled={formData.password.length < 8}
							className={
								formData.password.length < 8
									? "cursor-not-allowed font-semibold"
									: "font-semibold"
							}
							onClick={handleRegister}
						>
							Get started
						</Button>
						<div className="flex items-center justify-center gap-4 my-2">
							<div className="flex-grow border-t border-[#EAECF0]"></div>
							<P size="p3" type="medium">
								OR
							</P>
							<div className="flex-grow border-t border-[#EAECF0]"></div>
						</div>

						{/* <Button
							appearance="border"
							width="full"
							icon={<GoogleIcon className="w-5 h-5" />}
							grid="grid grid-cols-maxTwo gap-3"
							className="font-semibold"
						>
							Sign in with Google
						</Button>
						<Button
							appearance="border"
							width="full"
							icon={<AppleIcon className="w-5 h-5" />}
							grid="grid grid-cols-maxTwo gap-3"
							className="font-semibold"
						>
							Sign in with Apple
						</Button> */}
					</div>
					<div className="grid gap-2 grid-cols-maxTwo place-items-center place-content-center">
						<P size="p3" type="regular" className="cursor-pointer">
							Already have an account?
						</P>
						<Link to={routeConstants.login.route}>
							<P
								size="p3"
								type="semibold"
								className="cursor-pointer !text-mainColor"
							>
								Log in
							</P>
						</Link>
					</div>
				</div>
				{registerError && (
					<div className="text-red-500">
						<P size="p2" type="semibold">
							{registerError}
						</P>
					</div>
				)}
			</div>
		</div>
	);
};

export default RegisterPage;
