import AINailArtShowcase from "components/AINailArtShowcase/AINailArtShowcase";
import AIVisionShowcase from "components/AIVisionShowcase/AIVisionShowcase";
import CreativeProjectsShowcase from "components/CreativeProjectsShowcase/CreativeProjectsShowcase";
import HowItWorksShowscase from "components/HowItWorksShowcase/HowItWorksShowcase";
import KeyFeaturesShowcase from "components/KeyFeaturesShowcase/KeyFeaturesShowcase";
import ShopShowcase from "components/ShopShowcase/ShopShowcase";
import TryForFreeShowcase from "components/TryForFreeShowcase/TryForFreeShowcase";
import WhatUserSayShowcase from "components/WhatUserSayShowcase/WhatUserSayShowcase";
import React from "react";

const MainPage: React.FC = (): JSX.Element => {
	return (
		<>
			<div className="grid grid-cols-1">
				<AINailArtShowcase />
				<CreativeProjectsShowcase />
				<AIVisionShowcase />
				<KeyFeaturesShowcase />
				<HowItWorksShowscase />
				<ShopShowcase />
				<WhatUserSayShowcase />
				<TryForFreeShowcase />
			</div>
		</>
	);
};

export default MainPage;
