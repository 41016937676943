import React, { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import FilterItem from "common/FilterItem/FilterItem";

interface FilterOptions {
  type: string[];
  length: string[];
  shape: string[];
  offer: string[];
  colors: string[];
}

const FilterPanel: React.FC<{ filterOptions: FilterOptions }> = ({ filterOptions }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const filters = useMemo(
    () => ({
      type: searchParams.getAll("type"),
      length: searchParams.getAll("length"),
      shape: searchParams.getAll("shape"),
      offers: searchParams.getAll("offers"),
      colors: searchParams.getAll("colors"),
    }),
    [searchParams]
  );

  const handleFilterChange = useCallback(
    (filterName: keyof typeof filters, value: string) => {
      const currentFilterValues = Array.isArray(filters[filterName])
        ? filters[filterName]
        : [];
      const isAlreadyIncluded = currentFilterValues.includes(value);
      const newValues = isAlreadyIncluded
        ? currentFilterValues.filter((item) => item !== value)
        : [...currentFilterValues, value];

      const updatedFilters = { ...filters, [filterName]: newValues };

      const newSearchParams = new URLSearchParams();
      for (const [key, values] of Object.entries(updatedFilters)) {
        if (Array.isArray(values) && values.length > 0) {
          values.forEach((v) => newSearchParams.append(key, v));
        }
      }

      setSearchParams(newSearchParams, { replace: true });
    },
    [filters, setSearchParams]
  );

  return (
    <div className="grid grid-flow-row grid-cols-1 gap-5 auto-rows-max">
      {Object.entries(filterOptions).map(([key, values]: [string, string[]]) => (
        <FilterItem
          key={key}
          title={key.charAt(0).toUpperCase() + key.slice(1)}
          filterName={key as keyof typeof filters}
          filters={values.map((value) => ({ label: value, value }))}
          onChange={handleFilterChange}
          initialState={true}
        />
      ))}
    </div>
  );
};

export default FilterPanel;