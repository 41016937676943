import React from "react";
import { P } from "common/P/P";
import { InspirationItemProps } from "./InspirationItem.props";
import { ReactComponent as HeartIcon } from "common/assets/icons/heart.svg";
import { motion } from "framer-motion";

const InspirationItem: React.FC<InspirationItemProps> = ({
	image,
	name,
	likeCount,
	timeAgo,
	likedByUser,
	onLike,
}): JSX.Element => {
	return (
		<div className="relative grid grid-flow-row grid-cols-1 auto-rows-max">
			<img
				src={image}
				alt={name}
				className="w-[308px] h-[308px] TwoXl:w-full object-cover rounded-md border border-gray-800"
			/>
			<motion.div
				initial={{ opacity: 0 }}
				whileHover={{ opacity: 1 }}
				transition={{ duration: 0.3 }}
				className="absolute inset-0 grid w-full h-full grid-cols-1 gap-4 p-4 bg-black bg-opacity-50 cursor-pointer grid-rows-maxTwo place-content-between"
			>
				<motion.div
					className="grid grid-cols-maxTwo gap-2.5 place-items-center p-3 rounded-lg opacity-80 backdrop-blur-md w-max cursor-pointer"
					onClick={onLike}
				>
					<motion.div
						initial={{ scale: 1 }}
						animate={{ scale: likedByUser ? [1, 1.2, 1] : 1 }}
						transition={{ duration: 0.4, ease: "easeInOut" }}
					>
						{likedByUser ? (
							<HeartIcon className="fill-redColor stroke-redColor" />
						) : (
							<HeartIcon className="stroke-white" />
						)}
					</motion.div>
					<P size="p2" type="medium" className="text-white">
						{likeCount}
					</P>
				</motion.div>
				<div className="grid grid-flow-row grid-cols-1 gap-4">
					<P size="p2" type="medium" className="text-white">
						{timeAgo}
					</P>
					<P size="p2" type="medium" className="text-white clamp-text-3">
						{name}
					</P>
				</div>
			</motion.div>
		</div>
	);
};

export default InspirationItem;
