import React from "react";
import GenerateBoxItem from "common/GenerateBoxItem/GenerateBoxItem";
import { Htag } from "common/Htag/Htag";
import { RootState } from "components/App/store";
import { useSelector } from "react-redux";
import { routeConstants } from "navigation";

import { ReactComponent as TextToImageIcon } from "common/assets/icons/text-to-image.svg";
import { ReactComponent as ImageToImage } from "common/assets/icons/image.svg";
import { ReactComponent as PrintIcon } from "common/assets/icons/printer.svg";

import textToImage from "common/assets/images/text-to-image.png";
import imageToImage from "common/assets/images/image.png";
import printerImage from "common/assets/images/printer.png";

const GenerateCollection: React.FC = (): JSX.Element => {
	const auth = useSelector((state: RootState) => state.auth);
	return (
		<div className="grid grid-flow-row grid-cols-1 gap-8 pt-8 pb-12 auto-rows-max">
			<Htag tag="h2" type="semibold">
				Welcome back, {auth.user?.FirstName}!
			</Htag>
			<div className="grid grid-cols-3 gap-4">
				<GenerateBoxItem
					icon={<TextToImageIcon />}
					image={textToImage}
					title="Generate Nail Design by Text"
					description="Describe your perfect mani, and our AI artist brings it to life. Got a specific idea? Spill the details! Want something totally unique? Explore crazy concepts! We'll transform your words into stunning nail art you'll love."
					route={routeConstants.generate.route}
					active={true}
				/>
				<GenerateBoxItem
					icon={<ImageToImage />}
					image={imageToImage}
					title="Upload Photo for Nail Design Suggestions"
					description="Love a photo's vibes? Share it! Our AI analyzes it, then suggests nail designs to match. Feeling stuck? No problem! Let inspiration flow from any image. Your perfect mani awaits!"
					active={false}
				/>
				<GenerateBoxItem
					icon={<PrintIcon />}
					image={printerImage}
					title="Print Designs on Nail Templates"
					description="Pick from our AI creations or upload your own. We'll print them on pro templates. Easy application, instant style. Rock the perfect mani, designed by you."
					active={false}
				/>
			</div>
		</div>
	);
};

export default GenerateCollection;
