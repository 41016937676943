import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as NotFoundIllustration } from "common/assets/icons/not_found.svg";
import { ReactComponent as ArrowLeft } from "common/assets/icons/arrow-left.svg";
import { P } from "common/P/P";
import { Htag } from "common/Htag/Htag";
import { Button } from "common/Button/Button";
import { routeConstants } from "navigation";
import { RootState } from "components/App/store";
import { useSelector } from "react-redux";

const NotFoundPage: React.FC = () => {
	const navigate = useNavigate();
	const app = useSelector((state: RootState) => state.app);

	return (
		<div className={`${
			app.isPlayground
				? "px-[80px] TwoXl:px-[90px] ThreeXl:px-[110px]"
				: "px-[170px] TwoXl:px-[240px] ThreeXl:px-[300px]"
		} flex place-content-center gap-8 place-items-center minPhone:px-5 minPhone:pt-14 minPhone:pb-6 lg:pb-20 lg:pt-24 relative h-[80dvh]`}>
			<div className="flex flex-col items-start justify-start gap-12 grow shrink basis-0">
				<div className="grid grid-cols-1 gap-6 grid-rows-maxTwo">
					<div className="grid grid-cols-1 gap-3 grid-rows-maxTwo">
						<P size="p2" type="semibold" className="!text-mainColor">
							404 error
						</P>
						<Htag tag="h1" type="semibold" className="!text-violet-950">
							Page not found
						</Htag>
					</div>
					<Htag
						tag="h4"
						type="regular"
						className="!text-textPassiveColor w-2/3"
					>
						Sorry, the page you are looking for doesn't exist. Here are some
						helpful links:
					</Htag>
				</div>
				<div className="grid gap-3 grid-cols-maxTwo place-items-center">
					<Button
						appearance="border"
						onClick={() => navigate(-1)}
						icon={<ArrowLeft />}
						paddingX="px-5"
					>
						Go back
					</Button>
					<Button
						appearance="main"
						onClick={() => navigate(routeConstants.root.route)}
						paddingX="px-5"
					>
						Take me home
					</Button>
				</div>
			</div>
			<div className="flex flex-col items-center justify-center grow shrink basis-0 h-96">
				{/* Replace src with your actual image path or component */}
				{/* <img src={NotFoundImage} alt="Not Found" className="rounded-full shadow-lg w-80 h-80" /> */}
				<div className="relative">
					<NotFoundIllustration />
				</div>
			</div>
		</div>
	);
};

export default NotFoundPage;
