export function setCookie(name: string, value: string, hours: number) {
	const date = new Date();
	date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
	const expires = "expires=" + date.toUTCString();
	document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

export const getCookie = (name: string): string | undefined => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop()?.split(";").shift();
};

export const deleteCookie = (name: string): void => {
	document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export function checkTokenValidity(): boolean {
	const token = getCookie("token");
	if (!token) return false;

	// Assuming your token structure contains an expiration timestamp
	try {
		const payload = JSON.parse(atob(token.split(".")[1]));
		return new Date(payload.exp * 1000) > new Date();
	} catch (error) {
		return false;
	}
}
