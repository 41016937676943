import React from "react";
import { P } from "common/P/P";
import { ReactComponent as VisaIcon } from "common/assets/icons/visa.svg";
import { ReactComponent as MasterCardIcon } from "common/assets/icons/mastercard.svg";
import { ReactComponent as PayPalIcon } from "common/assets/icons/paypal.svg";
import { ReactComponent as AmazonIcon } from "common/assets/icons/amazon.svg";
import { AnimatePresence, motion, Variants } from "framer-motion";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./PaymentForm";

interface PaymentMethodSelectorProps {
	selectedMethod: string;
	setSelectedMethod: (method: string) => void;
	dropdownVariants: Variants;
}

const stripeKey =
	process.env.REACT_APP_MODE_PRODUCTION === "1"
		? process.env.REACT_APP_STRIPE_PUBLIC_API_KEY
		: process.env.REACT_APP_STRIPE_TEST_API_KEY;

const stripePromise = loadStripe(stripeKey!);

const PaymentMethodSelector: React.FC<PaymentMethodSelectorProps> = ({
	selectedMethod,
	setSelectedMethod,
	dropdownVariants,
}) => (
	<div className="grid grid-flow-row grid-cols-1 gap-4 auto-rows-max">
		{["card"].map((method) => (
			<div
				key={method}
				className={`grid grid-flow-row grid-cols-1 border cursor-pointer ${
					selectedMethod === method ? "border-purple-200" : "border-strokeColor"
				} rounded-lg auto-rows-max`}
			>
				<div
					className={`grid p-4 rounded-lg grid-cols-maxTwo place-content-between place-items-center ${
						selectedMethod === method
							? "border-b border-purple-200"
							: "border-none"
					}`}
					onClick={() =>
						setSelectedMethod(selectedMethod === method ? "" : method)
					}
				>
					<div className="grid gap-3 grid-cols-maxTwo place-items-center">
						<div
							className={`w-5 h-5 p-1.5 ${
								selectedMethod === method
									? "bg-violet-500"
									: "border border-strokeColor"
							} rounded-full justify-center items-center inline-flex`}
						>
							<div className="relative w-2 h-2 bg-white rounded-full" />
						</div>
						<P size="p2" type="medium">
							{method.charAt(0).toUpperCase() + method.slice(1)}
						</P>
					</div>
					{method === "card" && (
						<div className="grid gap-4 grid-cols-maxTwo place-items-center">
							<VisaIcon />
							<MasterCardIcon />
						</div>
					)}
					{method === "paypal" && <PayPalIcon />}
					{method === "amazon" && <AmazonIcon />}
				</div>
				<AnimatePresence>
					{selectedMethod === method && (
						<motion.div
							variants={dropdownVariants}
							initial="initial"
							animate="animate"
							exit="exit"
							className="grid grid-flow-row grid-cols-1 gap-4 p-4 auto-rows-max"
						>
							<Elements stripe={stripePromise}>
								<PaymentForm />
							</Elements>
						</motion.div>
					)}
				</AnimatePresence>
			</div>
		))}
	</div>
);

export default PaymentMethodSelector;
