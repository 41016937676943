import React, { useEffect, useState } from "react";
import { Htag } from "common/Htag/Htag";
import OrderHistoryItem from "common/OrderHistoryItem/OrderHistoryItem";
import { P } from "common/P/P";
import { routeConstants } from "navigation";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "components/App/store";
import { serviceConfig } from "configs";
import timeFormatter from "utils/timeFormatter";
import dateFormatter from "utils/dateFormatter";

const OrderHistoryTab: React.FC = () => {
	const [orders, setOrders] = useState([]);
	const navigate = useNavigate();
	const auth = useSelector((state: RootState) => state.auth);

	useEffect(() => {
		const fetchOrders = async () => {
			try {
				const response = await axios.get(
					`${serviceConfig.apiUrl}/orders/${auth.user?._id}`
				);
				if (response.data.success) {
					setOrders(response.data.data);
				} else {
					console.error("Failed to fetch orders:", response.data.message);
				}
			} catch (error) {
				console.error("Error fetching orders:", error);
			}
		};

		if (auth.isLoggedIn) {
			fetchOrders();
		}
	}, [auth.user?._id, auth.isLoggedIn]);

	const handleOrderClick = (id: string) => {
		navigate(`${routeConstants.orderDetails.route}/${id}`);
	};

	return (
		<div className="grid grid-flow-row grid-cols-1 gap-6 p-6 bg-white border auto-rows-max border-strokeColor rounded-xl">
			<div className="grid grid-flow-row grid-cols-1 pb-5 border-b auto-rows-max border-strokeColor">
				<Htag tag="h4" type="semibold">
					Order History
				</Htag>
				<P size="p3" type="regular" className="text-textSecondaryColor">
					Here you can manage your orders
				</P>
			</div>
			<div className="grid grid-flow-row grid-cols-2 gap-5 auto-rows-max">
				{orders.map((order: any, index) => (
					<OrderHistoryItem
						key={index}
						status={order.orderStatus}
						images={order.cart.items.map(
							(item: any) => item.selectedShape.imageUrl
						)}
						price={order.cart.totalAmount}
						date={dateFormatter(new Date(order.createdAt))}
						time={timeFormatter(new Date(order.createdAt))}
						onClick={() => handleOrderClick(order._id)}
					/>
				))}
			</div>
		</div>
	);
};

export default OrderHistoryTab;
