import React from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";

const ShopShowcase: React.FC = () => {
	return (
		<div className="px-[170px] TwoXl:px-[240px] ThreeXl:px-[300px] py-8 grid grid-cols-1 grid-rows-maxTwo gap-6">
			<div className="grid grid-cols-1 gap-2 grid-rows-maxTwo">
				<Htag tag="h2" type="semibold" className="text-[36px] text-center">
					Best seller products
				</Htag>
				<P size="p2" type="medium" className="text-center ">
					All products are generated by Nail AI
				</P>
			</div>

			{/* <Carousel slidesToShow={5}>
				{productsData.map((testimonial, index) => (
					<ProductCard key={index} {...testimonial} />
				))}
			</Carousel> */}
		</div>
	);
};

export default ShopShowcase;
