import React from "react";
import { OrderHistoryItemProps } from "./OrderHistoryItem.props";
import { P } from "common/P/P";
import OrderStatus from "common/OrderStatus/OrderStatus";

const OrderHistoryItem: React.FC<OrderHistoryItemProps> = ({
	status,
	images,
	image,
	price,
	date,
	time,
	onClick,
	orderDetails = false,
	name,
	color,
	size,
	discountPrice,
	discountPercentage,
	quantity,
}) => {
	const renderImages = () => {
		const maxImages = 4;

		const imageElements = images
			.slice(0, maxImages)
			.map((image: any, index: number) => (
				<div
					key={index}
					className="w-12 h-12 border rounded-md bg-slate-50 border-strokeColor"
				>
					<img
						key={index}
						src={image}
						alt={`Order item ${index + 1}`}
						className="object-cover w-full h-full rounded-md min-w-12 min-h-12"
					/>
				</div>
			));

		for (let i = imageElements.length; i < maxImages; i++) {
			imageElements.push(
				<div
					key={i}
					className="w-12 h-12 border rounded-md bg-slate-50 border-strokeColor"
				/>
			);
		}

		return (
			<div
				className="grid grid-cols-2 gap-1.5"
				style={{ width: "104px", height: "104px" }}
			>
				{imageElements}
			</div>
		);
	};

	return orderDetails ? (
		<div className="grid grid-cols-[70%_1fr_1fr] gap-6 py-6">
			<div className="grid items-center gap-4 grid-cols-maxFr">
				<div className="w-16 h-16 border rounded-md bg-slate-50 border-strokeColor">
					<img
						src={image}
						alt="order_image"
						className="object-cover w-full h-full rounded-md min-w-16 min-h-16"
					/>
				</div>

				<div className="grid grid-flow-row grid-cols-1 auto-rows-max gap-0.5">
					<P size="p3" type="semibold" className="truncate-1-lines">
						{name}
					</P>
					<div className="grid gap-1 grid-cols-maxThree place-items-center">
						<P size="p2" type="regular" className="!text-[#475467]">
							{color}
						</P>
						<div className="w-4 h-px rotate-90 border border-slate-600"></div>
						<P size="p2" type="regular" className="!text-[#475467]">
							{size}
						</P>
					</div>
				</div>
			</div>
			<div
				className={`inline-flex flex-col items-start ${
					discountPrice ? "justify-start" : "justify-center"
				} gap-1 pr-8 w-52`}
			>
				<div className="justify-start items-start gap-2.5 inline-flex">
					{price && (
						<P size="p2" type="semibold">
							${discountPrice ? discountPrice.toFixed(2) : price.toFixed(2)}
						</P>
					)}
				</div>
				{discountPrice && (
					<div className="justify-start items-center gap-2.5 inline-flex">
						<div className="px-1.5 py-0.5 bg-red-500 rounded border border-red-300 justify-center items-center gap-2.5 flex">
							<P size="t1" type="medium" className="!text-white">
								{discountPercentage}%
							</P>
						</div>
						{price && (
							<P
								size="p2"
								type="regular"
								className="!text-[#98A2B3] line-through"
							>
								${price.toFixed(2)}
							</P>
						)}
					</div>
				)}
			</div>
			<div className="grid grid-cols-1 place-items-center place-content-center">
				<P size="p2" type="semibold">
					{quantity}
				</P>
			</div>
		</div>
	) : (
		<div
			className="grid gap-4 p-4 border cursor-pointer rounded-xl border-strokeColor grid-cols-maxFr"
			onClick={onClick}
		>
			<div>{renderImages()}</div>
			<div className="grid grid-flow-row grid-cols-1 gap-4 auto-rows-max place-content-between">
				{status && <OrderStatus status={status} />}
				<div className="grid grid-flow-col auto-cols-max gap-1.5 place-items-center">
					<P size="p3" type="medium">
						${price}
					</P>
					<div className="w-1 h-1 bg-gray-500 rounded-full" />
					<P size="p3" type="medium">
						{date}
					</P>
					<div className="w-1 h-1 bg-gray-500 rounded-full" />
					<P size="p3" type="medium">
						{time}
					</P>
				</div>
			</div>
		</div>
	);
};

export default OrderHistoryItem;
