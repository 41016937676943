import React from "react";
import { P } from "common/P/P";
import { Htag } from "common/Htag/Htag";
import CartBody from "components/Cart/CartBody";

interface OrderSummaryProps {
  subtotal: number;
  discount: number;
  shipmentCost: number;
  grandTotal: number;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({ subtotal, discount, shipmentCost, grandTotal }) => (
  <div className="grid grid-flow-row grid-cols-1 gap-6">
    <Htag tag="h4" type="semibold">
      Your order
    </Htag>
    <CartBody className="gap-4" checkoutPage />
    <div className="self-stretch h-px border border-gray-200"></div>
    <div className="grid grid-flow-row grid-cols-1 gap-4 auto-rows-max h-28">
      <div className="grid gap-4 grid-cols-maxTwo place-content-between place-items-center">
        <P size="p1" type="medium" className="!text-[#475467]">
          Subtotal
        </P>
        <P size="p1" type="semibold" className="!text-[#475467]">
          ${subtotal.toFixed(2)}
        </P>
      </div>
      <div className="grid gap-4 grid-cols-maxTwo place-content-between place-items-center">
        <P size="p1" type="medium" className="!text-[#475467]">
          Discount
        </P>
        <P size="p1" type="regular" className="!text-[#475467]">
          -${discount.toFixed(2)}
        </P>
      </div>
      <div className="grid gap-4 grid-cols-maxTwo place-content-between place-items-center">
        <P size="p1" type="medium" className="!text-[#475467]">
          Shipment cost
        </P>
        <P size="p1" type="regular" className="!text-[#475467]">
          ${shipmentCost.toFixed(2)}
        </P>
      </div>
    </div>
    <div className="self-stretch h-px border border-gray-200"></div>
    <div className="grid gap-4 grid-cols-maxTwo place-content-between place-items-center">
      <P size="p1" type="medium" className="!text-[#475467]">
        Grand total
      </P>
      <Htag tag="h3" type="semibold">
        ${grandTotal.toFixed(2)}
      </Htag>
    </div>
  </div>
);

export default OrderSummary;
