import React, { useState, useEffect, useRef } from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import { motion, AnimatePresence } from "framer-motion";
import axios from "axios";
import { serviceConfig } from "configs";
import { useSelector } from "react-redux";
import { RootState } from "components/App/store";
import { SearchInput } from "common/Search/Search";
import InspirationItem from "common/InspirationItem/InspirationItem";
import { formatDistanceToNowStrict } from "date-fns";

interface InspirationItemProps {
	_id: string;
	imageUrl: string;
	description: string;
	likeCount: number;
	createdAt: string;
	likedByUser: boolean;
	category: string;
}

const InspirationPage: React.FC = (): JSX.Element => {
	const [inspirationItems, setInspirationItems] = useState<
		InspirationItemProps[]
	>([]);
	const [categories, setCategories] = useState<any[]>([]);
	const [selectedCategory, setSelectedCategory] = useState<string>("All");
	const [offset, setOffset] = useState<number>(0);
	const [limit] = useState<number>(20);
	const [hasMore, setHasMore] = useState<boolean>(true);
	const [searchQuery, setSearchQuery] = useState<string>("");

	const auth = useSelector((state: RootState) => state.auth);
	const categoryContainerRef = useRef<HTMLDivElement>(null);

	const fetchInspirations = async (
		offset: number,
		limit: number,
		search = ""
	) => {
		try {
			const response = await axios.get(
				`${serviceConfig.apiUrl}/inspirations?offset=${offset}&limit=${limit}&userId=${auth.user?._id}&search=${search}`
			);

			if (response.data.success) {
				const inspirations = response.data.data;

				const uniqueCategories = Array.from(
					new Set(
						inspirations.map((item: InspirationItemProps) => item.category)
					)
				);
				setCategories(["All", ...uniqueCategories]);

				setHasMore(inspirations.length === limit);
				setInspirationItems((prev) =>
					offset === 0 ? inspirations : [...prev, ...inspirations]
				);
			}
		} catch (error) {
			console.error("Error fetching inspirations:", error);
		}
	};

	const handleLike = async (itemId: string, currentLiked: boolean) => {
		try {
			const response = await axios.post(
				`${serviceConfig.apiUrl}/inspirations/like`,
				{
					inspirationId: itemId,
					like: !currentLiked,
					userId: auth.user?._id,
				}
			);

			if (response.data.success) {
				const updatedData = response.data.data;
				setInspirationItems((prevItems) =>
					prevItems.map((item) =>
						item._id === itemId
							? {
									...item,
									likeCount: updatedData.likeCount,
									likedByUser: updatedData.likedByUser,
							  }
							: item
					)
				);
			}
		} catch (error) {
			console.error("Error liking inspiration:", error);
		}
	};

	useEffect(() => {
		setOffset(0);
		setInspirationItems([]);
		fetchInspirations(0, limit);
	}, []);

	const filteredInspirations =
		selectedCategory === "All"
			? inspirationItems
			: inspirationItems.filter((item) => item.category === selectedCategory);

	const handleCategoryChange = (category: string) => {
		setSelectedCategory(category);
		setOffset(0);
	};

	let startX: number;
	let scrollLeft: number;

	const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
		startX =
			e.touches[0].pageX - (categoryContainerRef.current?.offsetLeft || 0);
		scrollLeft = categoryContainerRef.current?.scrollLeft || 0;
	};

	const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
		const x =
			e.touches[0].pageX - (categoryContainerRef.current?.offsetLeft || 0);
		const walk = x - startX;
		if (categoryContainerRef.current) {
			categoryContainerRef.current.scrollLeft = scrollLeft - walk;
		}
	};

	const itemVariants = {
		hidden: { opacity: 0, y: 20 },
		visible: (i: number) => ({
			opacity: 1,
			y: 0,
			transition: {
				delay: i * 0.1,
			},
		}),
	};

	return (
		<div className="grid grid-flow-row grid-cols-1 gap-6 py-10 auto-rows-max px-[80px] TwoXl:px-[90px] ThreeXl:px-[110px]">
			<div className="grid pb-5 grid-cols-maxTwo place-content-between place-items-center">
				<div className="grid grid-cols-1 gap-1 grid-rows-maxTwo">
					<Htag tag="h2" type="semibold">
						Inspirations
					</Htag>
					<P size="p2" type="regular" className="text-textSecondaryColor">
						Generate ideas with our generative AI
					</P>
				</div>
				<SearchInput
					value={searchQuery}
					onSearch={(query) => fetchInspirations(0, limit, query)}
				/>
			</div>

			<div
				className="flex overflow-hidden gap-1 p-1 bg-[#F9FAFB] rounded-lg border border-[#EAECF0] hide-scrollbar"
				onTouchStart={handleTouchStart}
				onTouchMove={handleTouchMove}
				ref={categoryContainerRef}
			>
				{categories.map((category) => (
					<motion.div
						key={category}
						initial={{ scale: 1 }}
						whileTap={{ scale: 1.1 }}
						whileHover={{ scale: 1.05 }}
						className={`px-4 py-2 rounded-md cursor-pointer ${
							selectedCategory === category
								? "bg-white shadow-sm"
								: "bg-transparent"
						}`}
						onClick={() => handleCategoryChange(category)}
					>
						<P
							size="p3"
							type="semibold"
							className={`cursor-pointer ${
								selectedCategory === category
									? "text-textPassiveColor"
									: "text-buttonColor"
							}`}
						>
							{category}
						</P>
					</motion.div>
				))}
			</div>

			{/* Inspirations */}
			<div className="grid grid-flow-row grid-cols-4 gap-4 TwoXl:grid-cols-5">
				<AnimatePresence>
					{filteredInspirations.map((item, index) => (
						<motion.div
							key={item._id}
							variants={itemVariants}
							initial="hidden"
							animate="visible"
							custom={index}
							exit={{ opacity: 0, y: 20 }}
						>
							<InspirationItem
								image={`${serviceConfig.apiUrl}${item.imageUrl}`}
								name={item.description}
								likeCount={item.likeCount}
								timeAgo={formatDistanceToNowStrict(new Date(item.createdAt), {
									addSuffix: true,
									roundingMethod: "floor",
								}).replace(/^about\s*/, "")}
								likedByUser={item.likedByUser}
								onLike={() => handleLike(item._id, item.likedByUser)}
							/>
						</motion.div>
					))}
				</AnimatePresence>
			</div>
		</div>
	);
};

export default InspirationPage;
