import React from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import image_1 from "common/assets/images/howitworks_1.png";
import image_2 from "common/assets/images/howitworks_2.png";
import image_3 from "common/assets/images/howitworks_3.png";
import image_4 from "common/assets/images/howitworks_4.png";

interface HowItWorksShowscaseProps {}

const HowItWorksShowscase: React.FC<HowItWorksShowscaseProps> = () => {
	return (
		<div className="px-[170px] TwoXl:px-[240px] ThreeXl:px-[300px] py-8 grid grid-cols-1 grid-rows-maxTwo gap-6">
			<div className="grid grid-cols-1 gap-2">
				<Htag tag="h2" type="semibold" className="text-center">
					How it works?
				</Htag>
				<P size="p2" type="medium" className="text-center">
					Simple steps of stunning nails
				</P>
			</div>
			<div className="grid grid-cols-1 gap-6">
				<div className="grid bg-white grid-cols-frMax shadow-helloMotto rounded-xl">
					<div className="grid grid-cols-1 gap-4 py-6 px-20 grid-rows-maxTwo grow shrink basis-0 rounded-xl bg-gradient-to-tr from-orange-200 from-15% via-orange-100 via-25% to-[#ffffff_70%] to-50% place-content-center">
						<Htag tag="h1" type="bold" className="!text-[#475467] !text-[48px]">
							01
						</Htag>
						<div className="grid grid-cols-1 gap-3 grid-rows-maxTwo">
							<Htag tag="h2" type="bold">
								Describe or Upload
							</Htag>
							<P size="p1" type="medium" className="!text-[#61646C]">
								Start by describing your dream nail design or upload an image as
								inspiration.
							</P>
						</div>
					</div>
					<img
						src={image_1}
						alt="describe_upload"
						className="object-cover"
						loading="lazy"
					/>
				</div>

				<div className="grid bg-white grid-cols-frMax shadow-helloMotto rounded-xl">
					<div className="grid grid-cols-1 gap-4 py-6 px-20 grid-rows-maxTwo grow shrink basis-0 rounded-xl bg-gradient-to-tr from-cyan-200 from-15% via-cyan-100 via-25% to-[#ffffff_70%] to-50% place-content-center">
						<Htag tag="h1" type="bold" className="!text-[#475467] !text-[48px]">
							02
						</Htag>
						<div className="grid grid-cols-1 gap-3 grid-rows-maxTwo">
							<Htag tag="h2" type="bold">
								AI Visualization
							</Htag>
							<P size="p1" type="medium" className="!text-[#61646C]">
								Our AI analyzes your input and crafts a unique nail art design.
							</P>
						</div>
					</div>
					<img
						src={image_2}
						alt="ai_visualization"
						className="object-cover"
						loading="lazy"
					/>
				</div>

				<div className="grid bg-white grid-cols-frMax shadow-helloMotto rounded-xl">
					<div className="grid grid-cols-1 gap-4 py-6 px-20 grid-rows-maxTwo grow shrink basis-0 rounded-xl bg-gradient-to-tr from-green-200 from-15% via-green-100 via-25% to-[#ffffff_70%] to-50% place-content-center">
						<Htag tag="h1" type="bold" className="!text-[#475467] !text-[48px]">
							03
						</Htag>
						<div className="grid grid-cols-1 gap-3 grid-rows-maxTwo">
							<Htag tag="h2" type="bold">
								Preview and Customize:
							</Htag>
							<P size="p1" type="medium" className="!text-[#61646C]">
								Preview the design on a virtual hand model and make any desired
								adjustments
							</P>
						</div>
					</div>
					<img
						src={image_3}
						alt="preview_customize"
						className="object-cover"
						loading="lazy"
					/>
				</div>

				<div className="grid bg-white grid-cols-frMax shadow-helloMotto rounded-xl">
					<div className="grid grid-cols-1 gap-4 py-6 px-20 grid-rows-maxTwo grow shrink basis-0 rounded-xl bg-gradient-to-tr from-fuchsia-200 from-15% via-fuchsia-100 via-25% to-[#ffffff_70%] to-50% place-content-center">
						<Htag tag="h1" type="bold" className="!text-[#475467] !text-[48px]">
							04
						</Htag>
						<div className="grid grid-cols-1 gap-3 grid-rows-maxTwo">
							<Htag tag="h2" type="bold">
								Download or Share
							</Htag>
							<P size="p1" type="medium" className="!text-[#61646C]">
								Download your nail design or share it with your nail artist for
								real-life application
							</P>
						</div>
					</div>
					<img
						src={image_4}
						alt="download_share"
						className="object-cover"
						loading="lazy"
					/>
				</div>
			</div>
		</div>
	);
};

export default HowItWorksShowscase;
