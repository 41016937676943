import React, { useState } from "react";
import { ProductCardProps } from "./ProductCard.props";
import { P } from "common/P/P";
import { serviceConfig } from "configs";

const ProductCard: React.FC<ProductCardProps> = ({
	images,
	name,
	price,
	discountPrice,
	discountPercentage,
	onClick,
}) => {
	const [hovered, setHovered] = useState(false);

	return (
		<div
			className="grid grid-cols-1 grid-rows-maxThree gap-2.5 mr-5"
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
		>
			<div
				className="relative lg:h-[222px] lg:w-[222px] cursor-pointer"
				onClick={onClick}
			>
				<img
					src={`${serviceConfig.apiUrl}/get-image/${images[0]}`}
					alt="product_image"
					className={`object-cover h-full w-full aspect-square rounded-lg border border-[#EAECF0] transition-opacity duration-500 ${
						hovered ? "opacity-0" : "opacity-100"
					}`}
					style={{ position: "absolute", top: 0, left: 0 }}
				/>
				<img
					src={`${serviceConfig.apiUrl}/get-image/${images[1]}`}
					alt="product_image"
					className={`object-cover h-full w-full aspect-square rounded-lg border border-[#EAECF0] transition-opacity duration-500 ${
						hovered ? "opacity-100" : "opacity-0"
					}`}
					style={{ position: "absolute", top: 0, left: 0 }}
				/>
				{discountPercentage && (
					<div className="absolute bottom-4 left-3 bg-[#F04438] border border-[#FDA29B] rounded-md px-2 py-0.5">
						<P size="p1" type="medium" className="!text-white">
							{discountPercentage}%
						</P>
					</div>
				)}
			</div>

			<div className="grid grid-cols-1 grid-rows-maxTwo gap-1.5">
				<P size="p2" type="medium" className="!text-[#475467] truncate-2-lines">
					{name}
				</P>
				<div className="flex justify-start items-baseline gap-1.5">
					<P size="p1" type="medium" className="">
						${discountPrice || price}
					</P>
					{discountPrice && (
						<P
							size="p2"
							type="regular"
							className="!text-[#98A2B3] line-through"
						>
							${price}
						</P>
					)}
				</div>
			</div>
		</div>
	);
};

export default ProductCard;
