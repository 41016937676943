import { createAsyncThunk } from "@reduxjs/toolkit";
import { authService } from "services/authService";
import { setCookie } from "utils/cookie";

export const updateProfile = createAsyncThunk(
  'auth/updateProfile',
  async (formData: FormData, thunkAPI) => {
    try {
      const response = await authService.updateProfile(formData);
      const { user, tokenExpirationTime } = response.data;

      const expirationHours = (new Date(tokenExpirationTime).getTime() - new Date().getTime()) / (1000 * 60 * 60);
      setCookie('user', JSON.stringify(user), expirationHours);

      return user;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);
