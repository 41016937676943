import React from "react";
import { TestimonialsCardProps } from "./TestimonialsCard.props";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";

const TestimonialsCard: React.FC<TestimonialsCardProps> = ({
	text,
	author,
	position,
}) => {
	return (
		<div className="grid grid-cols-1 grid-rows-maxTwo place-content-between gap-8 px-10 py-16 mr-8 bg-white rounded-2xl min-h-[22rem]">
			<div className="clamp-text-4">
				<Htag tag="h2" type="medium" className="text-center">
					{text}
				</Htag>
			</div>
			<div className="grid grid-cols-1 gap-1 grid-rows-maxTwo">
				<P size="p2" type="semibold" className="text-center">
					{author}
				</P>
				<P size="p3" type="regular" className="text-center">
					{position}
				</P>
			</div>
		</div>
	);
};

export default TestimonialsCard;
