import { route_prefix } from "configs";

export const routeConstants = {
	playgroundRoot: {
		route: `${route_prefix}/playground`,
		name: "Home",
	},
	root: {
		route: `${route_prefix}/`,
		name: "Home",
	},
	shops: {
		route: `${route_prefix}/shops`,
		name: "Shops",
	},
	cart: {
		route: `${route_prefix}/cart`,
		name: "Cart",
	},
	shippingAddress: {
		route: `${route_prefix}/shipping-address`,
		name: "Shipping Address",
	},
	payment: {
		route: `${route_prefix}/payment-card`,
		name: "Payment",
	},
	productDetails: {
		route: `${route_prefix}/product-details`,
		name: "Product Details",
	},
	successOrder: {
		route: `${route_prefix}/success-order`,
		name: "Success order",
	},
	generate: {
		route: `${route_prefix}/generate`,
		name: "Generate",
	},
	inspiration: {
		route: `${route_prefix}/inspiration`,
		name: "Inspiration",
	},

	favourite: {
		route: `${route_prefix}/favourites`,
		name: "Favourites",
	},
	checkout: {
		route: `${route_prefix}/checkout`,
		name: "Checkout",
	},
	filter: {
		route: `${route_prefix}/filter`,
		name: "Filter",
	},
	about: {
		route: `${route_prefix}/about-us`,
		name: "About us",
	},
	termsConditions: {
		route: `${route_prefix}/terms-conditions`,
		name: "Terms Conditions",
	},
	contact: {
		route: `${route_prefix}/contact-us`,
		name: "Contact us",
	},
	account: {
		route: `${route_prefix}/account`,
		name: "Account",
	},
	profile: {
		route: `${route_prefix}/profile`,
		name: "Profile",
	},
	orders: {
		route: `${route_prefix}/orders`,
		name: "Orders",
	},
	orderDetails: {
		route: `${route_prefix}/orders-details`,
		name: "Order Details",
	},
	shared: {
		route: `${route_prefix}/shared`,
		name: "Shared",
	},

	login: {
		route: `${route_prefix}/login`,
		name: "Login",
	},
	register: {
		route: `${route_prefix}/register`,
		name: "Register",
	},
	verificationOtp: {
		route: `${route_prefix}/verification-otp`,
		name: "Verification otp",
	},
	resetPassword: {
		route: `${route_prefix}/reset-password`,
		name: "Reset Password",
	},
	newPassword: {
		route: `${route_prefix}/new-password`,
		name: "New Password",
	},
	logout: {
		route: `${route_prefix}/logout`,
		name: "Logout",
	},
};
