import React, { useState } from "react";
import "./Navbar.css";
import { ReactComponent as Logo } from "common/assets/icons/logo.svg";
import { ReactComponent as MagicIcon } from "common/assets/icons/magic.svg";
import { ReactComponent as ArrowDown } from "common/assets/icons/chevron-down.svg";
import { ReactComponent as GenerateIcon } from "common/assets/icons/stars.svg";
import { ReactComponent as InspirationIcon } from "common/assets/icons/media-image.svg";
import { ReactComponent as FavouriteIcon } from "common/assets/icons/heart.svg";
import { ReactComponent as ShopIcon } from "common/assets/icons/shopping-bag-02.svg";
import { ReactComponent as CartIcon } from "common/assets/icons/cart.svg";
import { ReactComponent as SettingsIcon } from "common/assets/icons/settings.svg";
import { ReactComponent as AvatarIcon } from "common/assets/icons/user.svg";
import { Button } from "common/Button/Button";
import { P } from "common/P/P";
import { motion } from "framer-motion";
import { routeConstants } from "navigation";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "components/App/store";
import { serviceConfig } from "configs";

interface NavbarProps {
	isPlayground: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ isPlayground }) => {
	const auth = useSelector((state: RootState) => state.auth);
	const navigate = useNavigate();
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

	// Animation variants for the dropdown
	const dropdownVariants = {
		hidden: { opacity: 0, y: -20, display: "none" },
		visible: {
			opacity: 1,
			y: 0,
			transition: { duration: 0.3 },
			display: "block",
		},
	};

	const handleShopClick = (type: string) => {
		navigate(`${routeConstants.shops.route}?type=${type}`);
		setIsDropdownOpen(false);
	};

	return isPlayground ? (
		<nav className="relative z-20 py-3 px-[80px] TwoXl:px-[90px] ThreeXl:px-[110px] bg-white border-b border-strokeColor">
			<div className="grid grid-cols-maxTwo place-items-center place-content-between">
				<div className="grid gap-8 grid-cols-maxTwo place-items-center">
					<Logo
						className="w-32 cursor-pointer"
						onClick={() => navigate(routeConstants.root.route)}
					/>
					<div className="relative grid grid-flow-col gap-4 auto-cols-max place-items-center">
						<div
							className="grid gap-2 cursor-pointer grid-cols-maxTwo place-items-center"
							onClick={() => navigate(routeConstants.generate.route)}
						>
							<GenerateIcon className="w-6 h-6 stroke-buttonColor" />
							<P size="p2" type="semibold" className="cursor-pointer">
								Generate
							</P>
						</div>
						<div
							className="grid gap-2 cursor-pointer grid-cols-maxTwo place-items-center"
							onClick={() => navigate(routeConstants.inspiration.route)}
						>
							<InspirationIcon className="w-6 h-6 stroke-buttonColor" />
							<P size="p2" type="semibold" className="cursor-pointer">
								Inspiration
							</P>
						</div>
						<div
							className="grid gap-2 cursor-pointer grid-cols-maxTwo place-items-center"
							onClick={() => navigate(routeConstants.favourite.route)}
						>
							<FavouriteIcon className="w-6 h-6 stroke-buttonColor" />
							<P size="p2" type="semibold" className="cursor-pointer">
								Favourites
							</P>
						</div>
						<div
							className="grid gap-2 cursor-pointer grid-cols-maxTwo place-items-center"
							onClick={toggleDropdown}
						>
							<ShopIcon className="w-6 h-6 stroke-buttonColor" />
							<P size="p2" type="semibold" className="cursor-pointer">
								Shop
							</P>
						</div>
						{isDropdownOpen && (
							<motion.div
								className="absolute dropdown-content -right-28 top-[3.25rem]"
								initial="hidden"
								animate={isDropdownOpen ? "visible" : "hidden"}
								variants={dropdownVariants}
							>
								<div className="inline-flex flex-col items-start justify-start w-48 py-1 bg-white border rounded-lg shadow-lg border-strokeColor">
									<div className="self-stretch px-1.5 py-px justify-start items-center inline-flex">
										<div
											className="grow shrink basis-0 h-9 px-2.5 py-2 group hover:bg-gray-50 rounded-md justify-start items-center gap-3 flex cursor-pointer transition-all duration-300"
											onClick={() => handleShopClick("Nail")}
										>
											<div className="flex items-center justify-start h-5 gap-2 grow shrink basis-0">
												<P
													size="p3"
													type="medium"
													className="cursor-pointer group-hover:text-violet-500"
												>
													Press on nails
												</P>
												<div className="grow shrink basis-0 text-sm font-medium font-['Inter'] leading-tight"></div>
											</div>
										</div>
									</div>
									<div className="self-stretch px-1.5 py-px justify-start items-center inline-flex">
										<div
											className="grow shrink basis-0 h-9 px-2.5 py-2 group hover:bg-gray-50 transition-all duration-300 rounded-md justify-start items-center gap-3 flex cursor-pointer"
											onClick={() => handleShopClick("Sticker")}
										>
											<div className="flex items-center justify-start h-5 gap-2 grow shrink basis-0">
												<P
													size="p3"
													type="medium"
													className="cursor-pointer group-hover:text-violet-500"
												>
													Stickers
												</P>
											</div>
										</div>
									</div>
								</div>
							</motion.div>
						)}
					</div>
				</div>
				<div className="relative grid grid-flow-col gap-6 auto-cols-max place-items-center">
					<div onClick={() => navigate(routeConstants.cart.route)}>
						<CartIcon className="w-6 h-6 cursor-pointer" />
					</div>
					<div onClick={() => navigate(routeConstants.account.route)}>
						<SettingsIcon className="w-6 h-6 cursor-pointer" />
					</div>

					<div className="grid grid-cols-maxTwo gap-2.5 place-items-center">
						{auth.user?.ProfileImage ? (
							<img
								src={`${serviceConfig.apiUrl}/get-image/${auth.user?.ProfileImage}`}
								alt="profile_img"
								className="w-8 h-8 border rounded-full border-strokeColor"
							/>
						) : (
							<div className="p-1.5 border rounded-full border-strokeColor">
								<AvatarIcon />
							</div>
						)}

						<P size="p3" type="semibold">
							{auth.user?.FirstName || auth.user?.LastName
								? `${auth.user?.FirstName} ${auth.user?.LastName}`
								: "No Name"}
						</P>
					</div>
				</div>
			</div>
		</nav>
	) : (
		<div className="navbar">
			<nav className="relative z-20 px-6 py-3 mx-[170px] TwoXl:mx-[240px] ThreeXl:mx-[300px] mt-4 bg-white rounded-xl navbar-bg">
				<div className="grid grid-cols-maxTwo place-items-center place-content-between">
					<Logo
						className="w-32 cursor-pointer"
						onClick={() => navigate(routeConstants.root.route)}
					/>
					<div className="relative grid gap-4 grid-cols-maxFive place-items-center">
						<div
							className={`flex items-center justify-start gap-2 px-4 py-2 cursor-pointer rounded-xl ${
								isDropdownOpen ? "bg-purple-50 text-violet-500" : ""
							}`}
							onClick={toggleDropdown}
						>
							<P size="p3" type="medium" className="cursor-pointer">
								Shop
							</P>
							<ArrowDown className="w-[18px] h-[18px]" />
						</div>
						{isDropdownOpen && (
							<motion.div
								className="absolute dropdown-content -left-16 top-16"
								initial="hidden"
								animate={isDropdownOpen ? "visible" : "hidden"}
								variants={dropdownVariants}
							>
								<div className="inline-flex flex-col items-start justify-start w-40 py-1 bg-white rounded-lg">
									<div className="self-stretch px-1.5 py-px justify-start items-center inline-flex">
										<div
											className="grow shrink basis-0 h-9 px-2.5 py-2 group hover:bg-gray-50 rounded-md justify-start items-center gap-3 flex cursor-pointer transition-all duration-300"
											onClick={() => handleShopClick("Nail")}
										>
											<div className="flex items-center justify-start h-5 gap-2 grow shrink basis-0">
												<P
													size="p3"
													type="medium"
													className="cursor-pointer group-hover:text-violet-500"
												>
													Press on nails
												</P>
												<div className="grow shrink basis-0 text-sm font-medium font-['Inter'] leading-tight"></div>
											</div>
										</div>
									</div>
									<div className="self-stretch px-1.5 py-px justify-start items-center inline-flex">
										<div
											className="grow shrink basis-0 h-9 px-2.5 py-2 group hover:bg-gray-50 transition-all duration-300 rounded-md justify-start items-center gap-3 flex cursor-pointer"
											onClick={() => handleShopClick("Sticker")}
										>
											<div className="flex items-center justify-start h-5 gap-2 grow shrink basis-0">
												<P
													size="p3"
													type="medium"
													className="cursor-pointer group-hover:text-violet-500"
												>
													Stickers
												</P>
											</div>
										</div>
									</div>
								</div>
							</motion.div>
						)}

						<div
							className="flex items-center justify-start gap-2 px-4 py-2"
							onClick={() => navigate(routeConstants.cart.route)}
						>
							<P size="p3" type="medium">
								Cart
							</P>
						</div>
						<div className="flex items-center justify-start gap-2 px-4 py-2">
							<P size="p3" type="medium">
								Size chart
							</P>
						</div>

						<Button
							appearance="border"
							paddingX="px-4"
							grid=""
							onClick={() => navigate(routeConstants.login.route)}
						>
							Sign in
						</Button>
						<Button appearance="main" icon={<MagicIcon />} paddingX="px-4">
							Get Started
						</Button>
					</div>
				</div>
			</nav>
			{window.location.pathname === routeConstants.root.route && (
				<div className="rounded-b-xl navbar-bg-gradient"></div>
			)}
		</div>
	);
};

export default Navbar;
