import { Routes, Route } from "react-router-dom";

import { routeConstants } from "navigation";
import MainPage from "pages/MainPage/MainPage";
import LoginPage from "pages/auth/LoginPage/LoginPage";
import RegisterPage from "pages/auth/RegisterPage/RegisterPage";
import VerificationPage from "pages/auth/VerificationPage/VerificationPage";
import ResetPasswordPage from "pages/auth/ResetPasswordPage/ResetPasswordPage";
import NewPasswordPage from "pages/auth/NewPasswordPage/NewPasswordPage";
import NotFoundPage from "pages/system/NotFoundPage/NotFoundPage";
import ShopPage from "pages/ShopPage/ShopPage";
import CartPage from "pages/CartPage/CartPage";
import ShippingAddressPage from "pages/ShippingAddressPage/ShippingAddressPage";
import PaymentPage from "pages/PaymentPage/PaymentPage";
import ProductDetailPage from "pages/ProductDetailPage/ProductDetailPage";
import SuccessOrderPage from "pages/SuccessOrderPage/SuccessOrderPage";

export const LandingRoutes: React.FC = () => {
	return (
		<Routes>
			<Route path={routeConstants.root.route} element={<MainPage />} />
			<Route path={routeConstants.shops.route} element={<ShopPage />} />
			<Route path={routeConstants.cart.route} element={<CartPage />} />
			<Route
				path={routeConstants.shippingAddress.route}
				element={<ShippingAddressPage />}
			/>
			<Route path={routeConstants.payment.route} element={<PaymentPage />} />
			<Route
				path={`${routeConstants.productDetails.route}/:id`}
				element={<ProductDetailPage />}
			/>
			<Route
				path={routeConstants.successOrder.route}
				element={<SuccessOrderPage />}
			/>

			<Route path={routeConstants.login.route} element={<LoginPage />} />
			<Route path={routeConstants.register.route} element={<RegisterPage />} />
			<Route
				path={routeConstants.verificationOtp.route}
				element={<VerificationPage />}
			/>
			<Route
				path={routeConstants.resetPassword.route}
				element={<ResetPasswordPage />}
			/>
			<Route
				path={routeConstants.newPassword.route}
				element={<NewPasswordPage />}
			/>

			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	);
};
