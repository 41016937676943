import { createAsyncThunk } from "@reduxjs/toolkit";
import { authService } from "../../services/authService";
import { setCookie } from "../../utils/cookie";
import { setLoggedIn, setPlayground } from "redux/app/appSlice";

export interface LoginPayload {
	email: string;
	password: string;
}

export const auth = createAsyncThunk(
	"auth/login",
	async (payload: LoginPayload, thunkAPI) => {
		try {
			const response = await authService.login(payload.email, payload.password);

			const { token, tokenExpirationTime, user } = response.data;

			// Set token in cookie
			const expirationDate = new Date(tokenExpirationTime);
			const expirationHours =
				(expirationDate.getTime() - new Date().getTime()) / (1000 * 60 * 60);
			setCookie("token", token, expirationHours);
			setCookie("user", JSON.stringify(user), expirationHours);

			thunkAPI.dispatch(setPlayground());
			thunkAPI.dispatch(setLoggedIn(true));

			return { user, token, tokenExpirationTime };
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.response.data.message);
		}
	}
);
