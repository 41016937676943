import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import ProductDetailSlider from "components/ProductDetailSlider/ProductDetailSlider";
import ThumbnailSliderComponent from "components/ProductDetailSlider/ThumbnailSlider";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import { Button } from "common/Button/Button";
import { ReactComponent as HeartIcon } from "common/assets/icons/heart.svg";
import { ReactComponent as CartIcon } from "common/assets/icons/cart.svg";
import { serviceConfig } from "configs";
import { useDispatch, useSelector } from "react-redux";
import { addItem, removeItem } from "redux/cart/cartSlice";
import { RootState } from "components/App/store";

const ProductDetailPage: React.FC = () => {
	const auth = useSelector((state: RootState) => state.auth);
	const { id } = useParams<{ id: string }>();
	const [product, setProduct] = useState<any>(null);
	const [mainImages, setMainImages] = useState<string[]>([]);
	const [selectedImage, setSelectedImage] = useState<string | null>(null);
	const [, setCurrentImageIndex] = useState<number>(0);
	const [selectedShape, setSelectedShape] = useState<string | null>(null);
	const [selectedSize, setSelectedSize] = useState<string | null>(null);
	const mainSliderRef = useRef<Slider>(null);
	const thumbnailSliderRef = useRef<Slider>(null);
	const dispatch = useDispatch();
	const cartItems = useSelector((state: RootState) => state.cart.items);
	const app = useSelector((state: RootState) => state.app);

	useEffect(() => {
		const fetchProduct = async () => {
			try {
				const response = await fetch(`${serviceConfig.apiUrl}/products/${id}`);
				const data = await response.json();
				if (data.success) {
					setProduct(data.data);
					setMainImages(data.data.images.map((img: any) => img.savedImgPath));
				} else {
					console.error(data.message);
				}
			} catch (error) {
				console.error("Error fetching product details:", error);
			}
		};

		fetchProduct();
	}, [id]);

	useEffect(() => {
		if (selectedImage) {
			setMainImages([selectedImage]);
		} else if (product) {
			setMainImages(product.images.map((img: any) => img.savedImgPath));
		}
	}, [selectedImage, product]);

	const handleThumbnailClick = (index: number) => {
		const selectedThumbnail = product.images[index].savedImgPath;
		setSelectedImage(selectedThumbnail);
		setCurrentImageIndex(index);
		mainSliderRef.current?.slickGoTo(index);
	};

	const handleShapeClick = (shapeId: string) => {
		setSelectedShape(shapeId);
	};

	const handleSizeClick = (sizeId: string) => {
		setSelectedSize(sizeId);
	};

	const handleAddToCart = () => {
		if (!product || !selectedShape || !selectedSize) {
			return;
		}

		const unitPrice = product.discountPrice || product.price;
		const discountPrice = product.discountPrice || product.price;
		const discountPercentage = product.discountPercentage || 0;

		dispatch(
			addItem({
				product,
				selectedShape: {
					id: selectedShape,
					name: product.shape.name,
					imageUrl: `${serviceConfig.apiUrl}/get-image/${product.shape.image}`,
				},
				selectedSize: {
					id: selectedSize,
					name: product.size.name,
				},
				quantity: 1,
				unitPrice: unitPrice,
				originalPrice: product.price,
				discountPrice: discountPrice,
				discountPercentage: discountPercentage,
			})
		);
	};

	const handleRemoveFromCart = () => {
		if (!product || !selectedShape || !selectedSize) {
			return;
		}
		dispatch(
			removeItem({
				productId: product._id,
				shapeId: selectedShape,
				sizeId: selectedSize,
			})
		);
	};

	if (!product) {
		return <div>Loading...</div>;
	}

	const isInCart = cartItems.some(
		(item) =>
			item.product._id === product._id &&
			item.selectedShape.id === selectedShape &&
			item.selectedSize.id === selectedSize
	);

	return (
		<div className={`${
			app.isPlayground
				? "px-[80px] TwoXl:px-[90px] ThreeXl:px-[110px]"
				: "px-[170px] TwoXl:px-[240px] ThreeXl:px-[300px]"
		} py-8 grid grid-cols-1`}>
			<div className="grid grid-cols-2 gap-6 p-6 bg-white border border-gray-200 rounded-lg">
				<div className="">
					<ProductDetailSlider images={mainImages} ref={mainSliderRef} />
					{product.images.length > 1 && (
						<ThumbnailSliderComponent
							images={product.images.map((img: any) => img.savedImgPath)}
							onThumbnailClick={handleThumbnailClick}
							ref={thumbnailSliderRef}
						/>
					)}
				</div>

				<div className="grid grid-flow-row grid-cols-1 gap-10 auto-rows-max">
					<div className="grid grid-flow-row grid-cols-1 gap-8 auto-rows-max">
						<div className="grid grid-cols-1 gap-4 grid-rows-maxTwo">
							<Htag tag="h2" type="semibold">
								{product.name}
							</Htag>
							<div className="grid gap-3 grid-cols-maxTwo place-items-center">
								{product.discountPercentage > 0 && (
									<div className="bg-[#F04438] border border-[#FDA29B] rounded-md px-1.5 py-0.25">
										<P size="p2" type="semibold" className="!text-white">
											{product.discountPercentage}%
										</P>
									</div>
								)}
								<div className="flex justify-start items-baseline gap-1.5">
									<Htag tag="h4" type="bold">
										${product.discountPrice || product.price}
									</Htag>
									{product.discountPrice && (
										<P
											size="p2"
											type="regular"
											className="!text-[#98A2B3] line-through"
										>
											${product.price}
										</P>
									)}
								</div>
							</div>
						</div>
						<div className="grid grid-cols-1 grid-rows-maxTwo gap-1.5">
							<P size="p2" type="semibold">
								Description
							</P>
							<P size="p2" type="regular">
								{product.description}
							</P>
						</div>
						<div className="grid grid-cols-1 gap-3 grid-rows-maxTwo">
							<P size="p2" type="semibold">
								Choose Shape
							</P>
							<div className="flex flex-row flex-wrap gap-3">
								<div
									className={`object-cover w-16 h-16 border rounded-md cursor-pointer ${
										product.shape._id === selectedShape
											? "border-mainColor"
											: "border-strokeColor"
									} aspect-square`}
									onClick={() => handleShapeClick(product.shape._id)}
								>
									<img
										src={`${serviceConfig.apiUrl}/get-image/${product.shape.image}`}
										alt={`shape_1`}
										className="w-full h-full rounded-md"
									/>
								</div>
							</div>
						</div>
						<div className="grid grid-cols-1 gap-3 grid-rows-maxTwo">
							<P size="p2" type="semibold">
								Choose Size
							</P>
							<div className="flex flex-row flex-wrap gap-3">
								<div
									className={`cursor-pointer rounded-lg py-2 px-3.5 shadow-sm ${
										product.size._id === selectedSize
											? "bg-mainColor border-mainColor"
											: "border-strokeColor"
									}`}
									onClick={() => handleSizeClick(product.size._id)}
								>
									<P
										size="p3"
										type="semibold"
										className={`${
											product.size._id === selectedSize
												? "!text-white"
												: "!text-black"
										} cursor-pointer`}
									>
										{product.size.name}
									</P>
								</div>
							</div>
						</div>
					</div>

					<div
						className={`grid ${
							auth.isLoggedIn ? "grid-cols-2 gap-4" : "grid-cols-1"
						}`}
					>
						{auth.isLoggedIn && (
							<Button
								appearance="border"
								icon={<HeartIcon className="w-5 h-5" />}
								paddingX="px-12"
								paddingY="py-3"
								width="full"
							>
								Add to favorites
							</Button>
						)}
						<Button
							appearance="main"
							icon={<CartIcon className="w-4 h-4 stroke-white" />}
							paddingX="px-12"
							paddingY="py-3"
							width="full"
							onClick={isInCart ? handleRemoveFromCart : handleAddToCart}
						>
							{isInCart ? "Remove from cart" : "Add to cart"}
						</Button>
					</div>
				</div>
			</div>

			<div className="grid grid-cols-1 gap-6 py-20 grid-rows-maxTwo">
				<Htag tag="h2" type="semibold">
					Best sellers
				</Htag>

				{/* <Carousel slidesToShow={5}>
          {product.relatedProducts.map((relatedProduct: any, index: number) => (
            <ProductCard key={index} {...relatedProduct} />
          ))}
        </Carousel> */}
			</div>
		</div>
	);
};

export default ProductDetailPage;
