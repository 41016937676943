import React, { useState, useEffect } from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import InspirationItem from "common/InspirationItem/InspirationItem";
import { ReactComponent as TrendIcon } from "common/assets/icons/rocket.svg";
import { ReactComponent as StarIcon } from "common/assets/icons/star-04.svg";
import { motion, AnimatePresence } from "framer-motion";
import { Button } from "common/Button/Button";
import axios from "axios";
import { serviceConfig } from "configs";
import { formatDistanceToNowStrict } from "date-fns";
import { useSelector } from "react-redux";
import { RootState } from "components/App/store";

const InspirationCollection: React.FC = (): JSX.Element => {
	const [activeTab, setActiveTab] = useState<"Trending" | "New Feeds">(
		"Trending"
	);
	const [inspirationItems, setInspirationItems] = useState<any[]>([]);
	const [offset, setOffset] = useState<number>(0);
	const [limit] = useState<number>(10);
	const [hasMore, setHasMore] = useState<boolean>(true);

	const auth = useSelector((state: RootState) => state.auth);

	const fetchInspirations = async (
		category: "Trending" | "New Feeds",
		offset: number,
		limit: number
	) => {
		try {
			const response = await axios.get(
				`${serviceConfig.apiUrl}/inspirations?category=${category}&offset=${offset}&limit=${limit}&userId=${auth.user?._id}`
			);
			if (response.data.success) {
				setHasMore(response.data.data.length === limit);
				setInspirationItems((prev) => [...prev, ...response.data.data]);
			}
		} catch (error) {
			console.error("Error fetching inspirations:", error);
		}
	};

	const handleLike = async (itemId: string, currentLiked: boolean) => {
		try {
			const response = await axios.post(
				`${serviceConfig.apiUrl}/inspirations/like`,
				{
					inspirationId: itemId,
					like: !currentLiked,
					userId: auth.user?._id,
				}
			);

			if (response.data.success) {
				const updatedData = response.data.data;

				setInspirationItems((prevItems) =>
					prevItems.map((item) =>
						item._id === itemId
							? {
									...item,
									likeCount: updatedData.likeCount,
									likedByUser: updatedData.likedByUser,
							  }
							: item
					)
				);
			}
		} catch (error) {
			console.error("Error liking inspiration:", error);
		}
	};

	useEffect(() => {
		setOffset(0);
		setInspirationItems([]);
		fetchInspirations(activeTab, 0, limit);
	}, [activeTab]);

	const handleViewMore = () => {
		const newOffset = offset + limit;
		setOffset(newOffset);
		fetchInspirations(activeTab, newOffset, limit);
	};

	const itemVariants = {
		hidden: { opacity: 0, y: 20 },
		visible: (i: number) => ({
			opacity: 1,
			y: 0,
			transition: {
				delay: i * 0.1,
			},
		}),
	};

	return (
		<div className="grid grid-flow-row grid-cols-1 gap-6 py-10 auto-rows-max">
			<div className="grid pb-5 border-b grid-cols-maxTwo place-content-between place-items-center border-strokeColor">
				<Htag tag="h2" type="semibold">
					Inspiration
				</Htag>
				<div className="grid grid-cols-maxTwo">
					<div
						className={`grid gap-2 cursor-pointer grid-cols-maxTwo border border-strokeColor rounded-l-lg py-2 px-4 ${
							activeTab === "Trending" ? "bg-[#F9FAFB]" : "bg-white"
						}`}
						onClick={() => setActiveTab("Trending")}
					>
						<TrendIcon />
						<P size="p3" type="semibold" className="cursor-pointer">
							Trending
						</P>
					</div>
					<div
						className={`grid gap-2 px-4 py-2 border rounded-r-lg cursor-pointer grid-cols-maxTwo border-strokeColor ${
							activeTab === "New Feeds" ? "bg-[#F9FAFB]" : "bg-white"
						}`}
						onClick={() => setActiveTab("New Feeds")}
					>
						<StarIcon />
						<P size="p3" type="semibold" className="cursor-pointer">
							New feeds
						</P>
					</div>
				</div>
			</div>

			<div className="grid grid-flow-row grid-cols-4 gap-4 TwoXl:grid-cols-5">
				<AnimatePresence>
					{inspirationItems.map((item, index) => (
						<motion.div
							key={index}
							variants={itemVariants}
							initial="hidden"
							animate="visible"
							custom={index}
							exit={{ opacity: 0, y: 20 }}
						>
							<InspirationItem
								image={`${serviceConfig.apiUrl}${item.imageUrl}`}
								name={item.description}
								likeCount={item.likeCount}
								timeAgo={formatDistanceToNowStrict(new Date(item.createdAt), {
									addSuffix: true,
									roundingMethod: "floor",
								}).replace(/^about\s*/, "")}
								likedByUser={item.likedByUser}
								onLike={() => handleLike(item._id, item.likedByUser)}
							/>
						</motion.div>
					))}
				</AnimatePresence>
			</div>
			{hasMore && (
				<div className="grid gap-4 grid-cols-frMaxfr place-items-center">
					<div className="w-full h-px bg-strokeColor" />
					<Button appearance="border" paddingX="px-4" onClick={handleViewMore}>
						View more
					</Button>
					<div className="w-full h-px bg-strokeColor" />
				</div>
			)}
		</div>
	);
};

export default InspirationCollection;
