import React from "react";
import { BreadcrumbsProps } from "./Breadcrumbs.props";
import { P } from "../P/P";
import { useNavigate } from "react-router-dom";
import { ReactComponent as HomeIcon } from "common/assets/icons/home-line.svg";

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
	items,
	whiteMode = false,
}) => {
	const navigate = useNavigate();

	const breadcrumbs =
		items[0]?.label !== "Home"
			? [{ label: "Home", path: "/" }, ...items]
			: items;

	return (
		<nav
			aria-label="breadcrumbs"
			className="grid grid-flow-col gap-2 auto-cols-max h-max place-items-center"
		>
			{breadcrumbs.map((item, index) => (
				<React.Fragment key={index}>
					{index > 0 && (
						<P
							size="p3"
							type="regular"
							className={`${whiteMode ? "text-white" : "text-strokeColor"}`}
						>
							/
						</P>
					)}
					{item.path && index !== breadcrumbs.length - 1 ? (
						item.path === "noRoute" ? (
							<P
								size="p3"
								type="regular"
								className={`${whiteMode ? "text-white" : "text-grayTwo"}`}
							>
								{item.label}
							</P>
						) : (
							<P
								size="p3"
								type="regular"
								className={`${
									whiteMode ? "text-white" : "text-grayTwo"
								} cursor-pointer`}
								onClick={() => navigate(`${item.path}`)}
							>
								{item.label === "Home" ? (
									<HomeIcon className="w-5 h-5" />
								) : (
									item.label
								)}
							</P>
						)
					) : (
						<P
							size="p3"
							type="medium"
							className={`${whiteMode ? "text-white" : "text-mainColor"}`}
						>
							{item.label}
						</P>
					)}
				</React.Fragment>
			))}
		</nav>
	);
};

export default Breadcrumbs;
