import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as ArrowDownIcon } from "common/assets/icons/chevron-down.svg";
import { SelectProps } from "./Select.props";
import { SelectItemProps } from "./SelectItem";
import { P } from "common/P/P";

export const Select: React.FC<SelectProps> = ({
	label,
	children,
	defaultSelectLabel = "Select property",
	className,
	disabled = false,
	value,
	onSelect,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedLabel, setSelectedLabel] = useState(
		value || defaultSelectLabel
	);
	const dropdownRef = useRef<HTMLDivElement>(null);

	const getStatusClass = (status: string) => {
		if (typeof status === "string") {
			const loweredStatus = status.toLowerCase();
			switch (loweredStatus) {
				case "preparing":
					return "text-[#BF310A]";
				case "delivered":
					return "text-[#2DAA6F]";
				case "return":
				case "returned":
					return "text-[#101828]";
				case "cancel":
				case "cancelled":
					return "text-[#F21414]";
				case "waiting":
					return "text-[#FE9A04]";
				default:
					return "";
			}
		}
		return ""; // Return a default value if status is not a string
	};

	const isDefaultSelect = (itemValue: string): boolean => {
		if (itemValue === "Cancel" && defaultSelectLabel === "Cancelled")
			return true;
		if (itemValue === "Return" && defaultSelectLabel === "Returned")
			return true;
		if (itemValue === defaultSelectLabel) return true;
		return false;
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleClickOutside = (e: MouseEvent) => {
		if (
			dropdownRef.current &&
			!dropdownRef.current.contains(e.target as Node)
		) {
			setIsOpen(false);
		}
	};

	const handleItemSelect = (value: string) => {
		setSelectedLabel(value);
		if (onSelect) {
			onSelect(value);
		}
		setIsOpen(false);
	};

	return (
		<div
			className={`relative grid gap-2 ${disabled ? "opacity-60" : ""}`}
			ref={dropdownRef}
		>
			{label && (
				<P size="p3" type="light" className="">
					{label}
				</P>
			)}
			<div
				onClick={() => !disabled && setIsOpen(!isOpen)}
				className={`grid items-center w-full text-sm font-medium  px-4 py-3 transition-all duration-300 ease-in-out border rounded-lg cursor-pointer ${
					disabled ? "cursor-not-allowed" : ""
				} grid-cols-frMax border-strokeColor bg-white duration-400 focus:outline-none ${getStatusClass(
					selectedLabel
				)}`}
			>
				<P
					size="p3"
					type="light"
					className={`${
						selectedLabel === defaultSelectLabel
							? "text-grayTwo"
							: "text-textColor"
					}`}
				>
					{selectedLabel}
				</P>
				<ArrowDownIcon className="stroke-passiveTextColor" />
			</div>
			{isOpen && !disabled && (
				<div className="absolute z-10 w-full overflow-y-auto transition-all duration-300 bg-white border-b rounded-lg shadow-lg border-x top-full border-strokeColor max-h-56">
					{React.Children.map(children, (child) => {
						if (
							React.isValidElement<SelectItemProps>(child) &&
							child.props.value !== selectedLabel &&
							!isDefaultSelect(child.props.value)
						) {
							return React.cloneElement(child, {
								onSelect: handleItemSelect,
							});
						}
						return null;
					})}
				</div>
			)}
		</div>
	);
};
