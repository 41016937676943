import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import { Button } from "common/Button/Button";
import { ReactComponent as MailIcon } from "common/assets/icons/mail-01.svg";
import { authService } from "services/authService";
import OTPInput from "common/OTPInput/OTPInput";
import { routeConstants } from "navigation";
import { useDispatch } from "react-redux";
import { AppDispatch } from "components/App/store";
import { setUser } from "redux/auth/authSlice";
import { toast } from "react-toastify";

const VerifyOTPPage: React.FC = (): JSX.Element => {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const location = useLocation();
	const email = location.state?.email;
	const firstName = location.state?.firstName;
	const lastName = location.state?.lastName;
	const origin = location.state?.origin;

	const [otp, setOtp] = useState("");
	const [verificationError, setVerificationError] = useState<string | null>(
		null
	);
	const [countdown, setCountdown] = useState<number>(30);
	const [isResendDisabled, setIsResendDisabled] = useState<boolean>(true);

	const handleOtpChange = (otpValue: string) => {
		setOtp(otpValue);
	};

	const handleConfirmOTP = async () => {
		try {
			if (origin === "register") {
				const response = await authService.registerFinal(
					email,
					otp,
					firstName,
					lastName
				);
				dispatch(setUser(response.data.user));
				toast.success("Registration successful.");
				navigate(routeConstants.root.route);
			} else {
				// Check OTP validity
				await authService.verifyForgetPasswordOTP(email, otp);
				navigate(routeConstants.newPassword.route, {
					state: { email, otp },
				});
			}
		} catch (error: any) {
			const errorMessage =
				error.response?.data?.message || "Failed to confirm OTP.";
			toast.error(errorMessage);
			setVerificationError(errorMessage);
		}
	};

	const resendOTP = async () => {
		try {
			if (origin === "register") {
				await authService.registerInit(email, "", firstName, lastName);
			} else {
				await authService.forgetPasswordInit(email);
			}
			setCountdown(30);
			setIsResendDisabled(true);
		} catch (error) {
			console.error("Failed to resend OTP", error);
		}
	};

	useEffect(() => {
		let timer: NodeJS.Timeout | null = null;
		if (countdown > 0) {
			timer = setTimeout(() => {
				setCountdown(countdown - 1);
			}, 1000);
		} else {
			setIsResendDisabled(false);
		}

		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, [countdown]);

	return (
		<div className="grid grid-cols-1 minPhone:px-5 minPhone:pt-14 minPhone:pb-6 lg:px-32 lg:pb-20 lg:pt-24">
			<div className="grid grid-flow-row minPhone:gap-6 xl:gap-8 xl:place-content-center minPhone:place-items-stretch xl:place-items-center auto-rows-max minPhone:pt-28 minPhone:pb-8 xl:pt-0 xl:pb-0 minPhone:px-4 xl:px-0 z-[99]">
				<div className="p-3 bg-[#F4EBFF] rounded-full">
					<MailIcon />
				</div>
				<div className="grid gap-2 place-content-center place-items-center">
					<Htag tag="h2" type="semibold">
						Please check your email.
					</Htag>
					<P size="p2" type="regular">
						We've sent a code to <span className="font-semibold">{email}</span>
					</P>
				</div>
				<div className="grid gap-6">
					<OTPInput length={4} onChange={handleOtpChange} />
					<div className="grid grid-cols-1 place-content-center place-items-center">
						<P size="p3" type="regular">
							Didn’t get a code?{" "}
							<span>
								{isResendDisabled ? (
									`Please wait ${countdown} seconds`
								) : (
									<span
										className="underline cursor-pointer"
										onClick={resendOTP}
									>
										Click to resend.
									</span>
								)}
							</span>
						</P>
					</div>
					<div className="grid gap-3 grid-cols-maxTwo">
						<Button
							appearance="border"
							onClick={() => navigate(routeConstants.resetPassword.route)}
						>
							Cancel
						</Button>
						<Button
							appearance="main"
							grid="grid grid-cols-1"
							width="full"
							disabled={otp.length < 4}
							className={
								otp.length < 4
									? "cursor-not-allowed font-semibold"
									: "font-semibold"
							}
							onClick={handleConfirmOTP}
						>
							Verify
						</Button>
					</div>
				</div>
				{verificationError && (
					<div className="text-red-500">
						<P size="p2" type="semibold">
							{verificationError}
						</P>
					</div>
				)}
			</div>
		</div>
	);
};

export default VerifyOTPPage;
