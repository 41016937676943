import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ShippingInformation {
	fullName: string;
	email: string;
	phoneNumber: string;
	address: string;
	city: string;
	region: string;
	country: string;
	zipCode: string;
	shipmentType: string | null;
	shipmentCost: number;
}

const initialState: ShippingInformation = {
	fullName: "",
	email: "",
	phoneNumber: "",
	address: "",
	city: "",
	region: "",
	country: "",
	zipCode: "",
	shipmentType: null,
	shipmentCost: 0,
};

const shippingSlice = createSlice({
	name: "shipping",
	initialState,
	reducers: {
		setShippingInformation: (
			state,
			action: PayloadAction<ShippingInformation>
		) => {
			return { ...state, ...action.payload };
		},
		setShipmentCostAndType: (
			state,
			action: PayloadAction<{ shipmentType: string; shipmentCost: number }>
		) => {
			state.shipmentType = action.payload.shipmentType;
			state.shipmentCost = action.payload.shipmentCost;
		},
		clearShippingInformation: (state) => {
			return initialState;
		},
	},
});

export const {
	setShippingInformation,
	setShipmentCostAndType,
	clearShippingInformation,
} = shippingSlice.actions;
export default shippingSlice.reducer;
