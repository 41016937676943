import React, { forwardRef } from "react";
import Slider from "react-slick";
import "./Slider.css";
import { serviceConfig } from "configs";

type SliderProps = {
	images: string[];
	className?: string;
};

const ProductDetailSlider = forwardRef<Slider, SliderProps>((props, ref) => {
	const { images, className } = props;
	const settings = {
		dots: false,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
	};

	return (
		<div className={`relative ${className || ""} main-slider product-carousel`}>
			<Slider ref={ref} {...settings}>
				{images.map((image, index) => (
					<div key={index}>
						<img
							src={`${serviceConfig.apiUrl}/get-image/${image}`}
							alt={`Slide ${index + 1}`}
							className="minPhone:w-[487px] xl:w-[95%] minPhone:h-[387px] xl:h-[574px] object-cover rounded-md"
							loading="lazy"
						/>
					</div>
				))}
			</Slider>
		</div>
	);
});

export default ProductDetailSlider;
