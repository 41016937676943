import React from "react";
import TestimonialsCard from "common/TestimonialsCard/TestimonialsCard";
import Carousel from "components/Carousel/Carousel";
import { Htag } from "common/Htag/Htag";

const testimonials = [
	{
		text: "Nail AI has saved us thousands of hours of work. We’re able to spin up projects and features faster. ",
		author: "Fleur Cook",
		position: "Web Developer, Sisyphus",
	},
	{
		text: "Nail AI has saved us thousands of hours of work. We’re able to spin up projects and features faster.",
		author: "Alex Smith",
		position: "Product Manager, Hephaestus Tech",
	},
	{
		text: "2Nail AI has saved us thousands of hours of work. We’re able to spin up projects and features faster. ",
		author: "Fleur Cook",
		position: "Web Developer, Sisyphus",
	},
	{
		text: "2Nail AI has saved us thousands of hours of work. We’re able to spin up projects and features faster.",
		author: "Alex Smith",
		position: "Product Manager, Hephaestus Tech",
	},
];

const customResponsive = [
	{
		breakpoint: 1850, // For screens larger than 1850px
		settings: {
			slidesToShow: 2,
			slidesToScroll: 1,
		},
	},
	{
		breakpoint: 600, // For screens up to 600px
		settings: {
			slidesToShow: 1,
			slidesToScroll: 1,
		},
	},
];

const WhatUserSayShowcase: React.FC = () => {
	return (
		<div className="px-[170px] TwoXl:px-[240px] ThreeXl:px-[300px] py-8 grid grid-cols-1 grid-rows-maxTwo gap-8">
			<Htag tag="h2" type="semibold" className="text-[36px] text-center">
				What Our Users Say
			</Htag>

			<Carousel slidesToShow={3} responsive={customResponsive}>
				{testimonials.map((testimonial, index) => (
					<TestimonialsCard key={index} {...testimonial} />
				))}
			</Carousel>
		</div>
	);
};

export default WhatUserSayShowcase;
