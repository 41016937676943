import React, { useState, useEffect } from "react";
import { Htag } from "common/Htag/Htag";
import InspirationItem from "common/InspirationItem/InspirationItem";
import { motion, AnimatePresence } from "framer-motion";
import { Button } from "common/Button/Button";
import axios from "axios";
import { serviceConfig } from "configs";
import { formatDistanceToNowStrict } from "date-fns";
import { useSelector } from "react-redux";
import { RootState } from "components/App/store";

interface FavouriteItemProps {
	_id: string;
	imageUrl: string;
	description: string;
	likeCount: number;
	createdAt: string;
	likedByUser: boolean;
}

const FavouritePage: React.FC = (): JSX.Element => {
	const [favouriteItems, setFavouriteItems] = useState<FavouriteItemProps[]>(
		[]
	);
	const [offset, setOffset] = useState<number>(0);
	const [limit] = useState<number>(20);
	const [hasMore, setHasMore] = useState<boolean>(true);

	const auth = useSelector((state: RootState) => state.auth);

	// Fetch favourites liked by the user
	const fetchFavourites = async (
		userId: string,
		offset: number,
		limit: number
	) => {
		try {
			const response = await axios.get(
				`${serviceConfig.apiUrl}/inspirations/liked?userId=${userId}&offset=${offset}&limit=${limit}`
			);
			if (response.data.success) {
				const dataWithLikeStatus = response.data.data.map((item: any) => ({
					...item,
					likedByUser: item.likes.includes(userId), // Set likedByUser based on the likes array
				}));

				setHasMore(response.data.data.length === limit);
				setFavouriteItems((prev) => [...prev, ...dataWithLikeStatus]);
			}
		} catch (error) {
			console.error("Error fetching liked favourites:", error);
		}
	};

	const handleLike = async (itemId: string, currentLiked: boolean) => {
		try {
			// The like action will toggle the like status
			const response = await axios.post(
				`${serviceConfig.apiUrl}/inspirations/like`,
				{
					inspirationId: itemId,
					like: !currentLiked, // Toggle like/dislike (if liked, then dislike)
					userId: auth.user?._id,
				}
			);

			if (response.data.success) {
				const updatedData = response.data.data;

				// Update the like count and likedByUser status
				setFavouriteItems((prevItems) =>
					prevItems.map((item) =>
						item._id === itemId
							? {
									...item,
									likeCount: updatedData.likeCount,
									likedByUser: updatedData.likedByUser,
							  }
							: item
					)
				);
			}
		} catch (error) {
			console.error("Error liking/disliking favourite:", error);
		}
	};

	useEffect(() => {
		if (auth.user?._id) {
			// Fetch liked favourites on mount
			fetchFavourites(auth.user._id, 0, limit);
		}
	}, [auth.user?._id]);

	const handleViewMore = () => {
		const newOffset = offset + limit;
		setOffset(newOffset);
		if (auth.user?._id) {
			fetchFavourites(auth.user._id, newOffset, limit);
		}
	};

	const itemVariants = {
		hidden: { opacity: 0, y: 20 },
		visible: (i: number) => ({
			opacity: 1,
			y: 0,
			transition: {
				delay: i * 0.1,
			},
		}),
	};

	return (
		<div className="grid grid-flow-row grid-cols-1 gap-6 py-10 auto-rows-max px-[80px] TwoXl:px-[90px] ThreeXl:px-[110px]">
			<div className="grid pb-5 border-b grid-cols-maxTwo place-content-between place-items-center border-strokeColor">
				<Htag tag="h2" type="semibold">
					Your Favourites
				</Htag>
			</div>

			<div className="grid grid-flow-row grid-cols-4 gap-4 TwoXl:grid-cols-5">
				<AnimatePresence>
					{favouriteItems.map((item, index) => (
						<motion.div
							key={item._id}
							variants={itemVariants}
							initial="hidden"
							animate="visible"
							custom={index}
							exit={{ opacity: 0, y: 20 }}
						>
							<InspirationItem
								image={`${serviceConfig.apiUrl}${item.imageUrl}`}
								name={item.description}
								likeCount={item.likeCount}
								timeAgo={formatDistanceToNowStrict(new Date(item.createdAt), {
									addSuffix: true,
									roundingMethod: "floor",
								}).replace(/^about\s*/, "")}
								likedByUser={item.likedByUser} // Render based on likedByUser field
								onLike={() => handleLike(item._id, item.likedByUser)} // Handle like/dislike
							/>
						</motion.div>
					))}
				</AnimatePresence>
			</div>
			{hasMore && (
				<div className="grid gap-4 grid-cols-frMaxfr place-items-center">
					<div className="w-full h-px bg-strokeColor" />
					<Button appearance="border" paddingX="px-4" onClick={handleViewMore}>
						View more
					</Button>
					<div className="w-full h-px bg-strokeColor" />
				</div>
			)}
		</div>
	);
};

export default FavouritePage;
